import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  roleHeading,
  roleTypography,
  textfield,
} from "./muiRoleComponentStyles";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import { updateRole } from "../../redux/slices/RoleSlice";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { getUsersList } from "../../redux/slices/UserSlice";
import { textfieldStyle, styledChip } from "./muiRoleComponentStyles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "background.bgLayoutA" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EditDialog = ({ roleDetail, editDialog, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const fireToasterContext = useContext(toasterContext);
  const [loader, setLoader] = useState(false);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [roleObj, setRoleObj] = useState({
    roleId: null,
    name: "",
    description: "",
  });
  const { usersList } = useSelector((state) => state.userReducer);

  const [userIds, setUserIds] = useState(
    roleDetail?.users?.map((role) => role.id)
  );

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  useEffect(() => {
    //Cleaning up states each time dialog opens
    setRoleObj({
      roleId: roleDetail?.id ? roleDetail?.id : null,
      name: roleDetail?.name ? roleDetail?.name : "",
      description: roleDetail?.description ? roleDetail?.description : "",
    });
  }, [roleDetail]);

  useEffect(() => {
    if (userIds.length === 0) {
      setSelectAllUsers(false);
    } else if (userIds.length === usersList.length) {
      setSelectAllUsers(true);
    }
  }, [userIds, usersList]);

  const handleUpdateRole = () => {
    setLoader(true);
    const finalObject = {
      ...roleObj,
      userIds: userIds,
    };
    dispatch(updateRole(finalObject, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Role Updated"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };
  const handleToggleSelectAllUser = () => {
    setSelectAllUsers((prev) => {
      if (!prev) {
        setUserIds(usersList.map((option) => option.id));
      } else {
        setUserIds([]);
      }
      return !prev;
    });
  };

  const handleChangeRoleData = (event) => {
    let roleObjTemp = { ...roleObj };
    roleObjTemp[event.target.name] = event.target.value;
    setRoleObj(roleObjTemp);
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={editDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={roleHeading} variant="roleTableHeadingBold">
            {translate("editRole")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm style={{ width: "100%" }} onSubmit={handleUpdateRole}>
          <DialogContent dividers>
            <TextValidator
              sx={textfield}
              fullWidth
              id="outlined-basic"
              label={translate("name")}
              name="name"
              variant="outlined"
              type="text"
              value={roleObj?.name}
              onChange={handleChangeRoleData}
            />

            {/* users select field */}
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="groups"
              limitTags={15}
              sx={textfieldStyle}
              options={usersList}
              getOptionLabel={(option) =>
                `${option?.firstName} ${option?.lastName}`
              }
              value={usersList?.filter((option) => userIds.includes(option.id))}
              onChange={(event, newValue) => {
                setUserIds(newValue.map((option) => option.id));
                if (newValue.length !== usersList.length) {
                  setSelectAllUsers(false);
                }
                if (newValue.length === usersList.length) {
                  setSelectAllUsers(true);
                }
                if (newValue.length === 0) {
                  setSelectAllUsers(false);
                }
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography sx={roleTypography} variant="inputOutputList">
                    {`${option?.firstName} ${option?.lastName}`}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={translate("users")}
                  InputLabelProps={{
                    style: { color: "#6D7B88" },
                  }}
                  sx={textfield}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={`${option?.firstName} ${option?.lastName}`}
                    key={option.id}
                    {...getTagProps({ index })}
                    sx={styledChip}
                  />
                ))
              }
              PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                  <>
                    {usersList.length < 1 ? (
                      <Typography sx={roleTypography} variant="inputOutputList">
                        No Role Found
                      </Typography>
                    ) : (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()} // prevent blur
                          pl={1.5}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault(); // prevent blur
                              handleToggleSelectAllUser();
                            }}
                            sx={{
                              color: "#6D7B88",
                            }}
                            label={translate("selectAll")}
                            control={
                              <Checkbox
                                id="select-all-checkbox"
                                checked={selectAllUsers}
                                sx={{
                                  color: "#6D7B88", // Change the color of the Checkbox when unchecked
                                  "&.Mui-checked": {
                                    color: "#6D7B88", // Change the color of the Checkbox when checked
                                  },
                                }}
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    )}
                  </>
                );
              }}
            />

            <TextValidator
              sx={textfield}
              fullWidth
              id="outlined-basic"
              label={translate("description")}
              name="description"
              variant="outlined"
              type="text"
              multiline
              rows={4}
              value={roleObj?.description}
              onChange={handleChangeRoleData}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={{
                "&.MuiButtonBase-root": {
                  backgroundColor: "black",
                  fontFamily: "Poppins-semibold",
                  fontSize: "15px",
                  color: "white",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                },
                "&:hover": { backgroundColor: "black" },
              }}
              autoFocus
              onClick={handleCloseDialog}
            >
              <Typography sx={roleHeading} variant="roleHeadingBoldButton">
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              disabled={!roleObj.name}
              disableRipple
              type="submit"
              sx={
                loader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutB",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "background.bgLayoutB" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={roleHeading} variant="roleHeadingBoldButton">
                {translate("saveRole")}
              </Typography>
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default EditDialog;
