import React from "react";
import {
  Container,
  Typography,
  // DialogActions,
  Button,
  DialogContent,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  // dialogCancelButton,
  styledTableCell,
  userHeading,
} from "../../utils/muiComponentStyles";
// import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "../../redux/store";
// import { deleteContractor } from "../../redux/slices/contractorSlice";

const Index = ({
  openModal,
  storeResposne,
  handleCloseDialog,
  handleCloseDeleteModal,
}) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [loader, setLoader] = useState(false);

  const handleApplicantRowClick = (data) => {
    localStorage.setItem("selectedApplication", JSON.stringify(data));
    navigate("/applications/application-detail");
  };

  // const handleDeleteContractor = () => {
  //   setLoader(true);
  //   dispatch(deleteContractor(selectedContractor.id, handleApiRes));
  // };

  // const handleApiRes = (data) => {
  //   setLoader(false);
  //   if (data.status) {
  //     handleCloseDeleteModal();
  //     fireToasterContext.fireToasterHandler(true, data.message);
  //   } else {
  //     handleCloseDeleteModal();
  //     fireToasterContext.fireToasterHandler(false, data.message);
  //   }
  // };

  return (
    <Container>
      <BootstrapDialog
        onClose={() => {
          handleCloseDeleteModal();
          handleCloseDialog();
        }}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleCloseDeleteModal();
            handleCloseDialog();
          }}
        >
          <Typography sx={userHeading} variant="userTableHeadingBold">
            Delete Contractor with Application
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box paddingTop={4}>
            <Typography
              variant="caption"
              color="error"
              sx={{ fontFamily: "Poppins", fontSize: "16px" }}
            >
              {storeResposne.message}
              {/* <span
                style={{
                  fontFamily: "Poppins-Bold",
                }}
              >
                {selectedContractor.name}
              </span> */}
            </Typography>
          </Box>
          <TableContainer sx={{ pt: 1 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      ...styledTableCell,
                      backgroundColor: "background.bgLayoutA",
                    }}
                  >
                    <Typography sx={userHeading} variant="userTableHeadingBold">
                      Job Description
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {storeResposne?.data?.map((application, index) => (
                  <TableRow key={application.id}>
                    <TableCell
                      sx={{
                        borderBottom:
                          index === storeResposne.data.length - 1
                            ? "none"
                            : "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {application.jobDescription}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom:
                          index === storeResposne.data.length - 1
                            ? "none"
                            : "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleApplicantRowClick(application)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        {/* <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={() => {
              handleCloseDeleteModal();
              handleCloseDialog();
            }}
          >
            Ok
          </Button>
           <Button
            type="submit"
            onClick={handleDeleteContractor}
            sx={
              loader
                ? {
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
                : {
                    backgroundColor: "background.bgLayoutB",
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "background.bgLayoutB",
                      boxShadow: 2,
                    },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
            }
          >
            Delete Contractor with Application
            {loader ? <CircularProgressLoader /> : ""}
          </Button> 
        </DialogActions> */}
      </BootstrapDialog>
    </Container>
  );
};

export default Index;
