import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicRoute = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();

  // Always allow /application-form regardless of token
  if (location.pathname === "/application-form") {
    return <Outlet />;
  }

  // For other public routes (like /login), if token exists, redirect to dashboard
  if (token) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PublicRoute;
