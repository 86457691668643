import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Layout from "../layouts/index";
import routes from "../layouts/routesList";
const ProtectedRoute = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1].split("-").join("");
  const isSuperAdmin = localStorage.getItem("isSuperAdmin");
  const token = localStorage.getItem("token"); // determine if authorized, from context or however you're doing it

  let routePath = [];
  for (let i = 0; i < routes?.length; i++) {
    if (routes[i].children) {
      let temp = routes[i]?.children;
      for (let j = 0; j < temp.length; j++) {
        if (temp[j]?.path) routePath.push(temp[j].path);
      }
    }
    //Normal Routes
    if (routes[i]?.path) routePath.push(routes[i].path);
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isSuperAdmin && token ? (
    path === "404" ? (
      <Outlet />
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    )
  ) : (
    <Navigate to="/application-form" />
  );
};

export default ProtectedRoute;
