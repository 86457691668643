import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  InputBase,
  Grid,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import Loader from "../Loader/Loader";
import { Search } from "@mui/icons-material";
import CustomPagination from "../../hooks/useTablePagination";
import { useDispatch, useSelector } from "../../redux/store";
import { getResportsData } from "../../redux/slices/resportsSlice";
import { getAllMuncipalities } from "../../redux/slices/muncipalitySlice";
import {
  StyledTableRow,
  miniScrollBarStyles,
  styledTableCell,
  tableBodyTypos,
  tableLoaderBox,
  userHeading,
  autoCompleteStyle,
  textfield,
} from "../../utils/muiComponentStyles";

import { CSVLink } from "react-csv";

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const buildingTypeList = [
  { id: 1, name: "Single Family" },
  { id: 2, name: "Duplex" },
  { id: 3, name: "Multi-Family" },
  { id: 4, name: "Commercial-Industrial" },
  { id: 5, name: "Garage/Shed" },
  // { id: 6, name: "Does not apply" },
];

const headers = [
  { width: "200px", name: "Permit #" },
  { width: "200px", name: "Permit Date" },
  { width: "200px", name: "Municipality" },
  { width: "200px", name: "Job Address" },
  { width: "200px", name: "Building Type" },
  { width: "200px", name: "Project Type" },
  { width: "200px", name: "Owner" },
  { width: "250px", name: "Construction Cost" },
  { width: "200px", name: "Electrical Cost" },
  { width: "200px", name: "Plumbing Cost" },
  { width: "200px", name: "HVAC Cost" },
  { width: "200px", name: "Permit Fees" },
  { width: "200px", name: "Payment Status" },
  { width: "200px", name: "Permit Status" },
];

const Index = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredList, setFilteredList] = useState([]);
  const [toBeSearched, setToBeSearched] = useState("");
  const [dateTimeObj, setDateTimeObj] = useState({});
  const [permitReportData, setPermitReportData] = useState([]);
  const [download, setDownload] = useState(false);

  useEffect(() => {
    dispatch(getResportsData(handleApiResponse));
    dispatch(getAllMuncipalities());
  }, []);

  let { resports } = useSelector((state) => state.resportsReducer);
  let { muncipalityList } = useSelector((state) => state.muncipalityReducer);

  const handleApiResponse = (response) => {
    if (response.status) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFilteredList(resports);
  }, [resports]);

  useEffect(() => {
    setFilteredList(resports);
    if (!dateTimeObj.startDate && !dateTimeObj.endDate) {
      setFilteredList(resports);
      return;
    }
  }, [resports, dateTimeObj]);

  useEffect(() => {
    handleDateSearch();
  }, [dateTimeObj]);

  const getBuidingType = (data) => {
    const matchingBuildingType = buildingTypeList.find(
      (buildingType) => buildingType.id === data
    );
    if (matchingBuildingType) {
      return matchingBuildingType.name;
    } else {
      return "N/A";
    }
  };

  const getProjectTypeList = (data) => {
    const matchingProjectType = projectTypeList.find(
      (projectType) => projectType.id === data
    );
    if (matchingProjectType) {
      return matchingProjectType.name;
    } else {
      return "N/A";
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleSearch = (value) => {
    setToBeSearched(value);
  };

  const handleDateChange = (event) => {
    let tempdateTimeObj = { ...dateTimeObj };
    tempdateTimeObj[event.target.name] = event.target.value;
    setDateTimeObj(tempdateTimeObj);
  };

  const handleDateSearch = () => {
    const filteredApplicants = resports.filter((u) => {
      // Filter by date (without considering the time)
      const startDateMatch =
        !dateTimeObj.startDate ||
        u.createdAt.substring(0, 10) >= dateTimeObj.startDate;
      const endDateMatch =
        !dateTimeObj.endDate ||
        u.createdAt.substring(0, 10) <= dateTimeObj.endDate;
      return startDateMatch && endDateMatch;
    });

    // Update the filtered data state when the Search button is clicked
    setFilteredList(filteredApplicants);
  };

  const dateTimeApiformat = (currentDate) => {
    const date = new Date(currentDate);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const newDT = `${month}/${day}/${year}`;

    return newDT;
  };

  const filterUsers = (reports, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return reports.filter((report) => {
      // Destructure report properties for easier access
      const {
        ownerAddress,
        ownerName,
        projectType,
        projectAddress,
        permitNumber,
      } = report;

      return (
        ownerAddress?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
        ownerName?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
        permitNumber?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
        projectTypeList
          .find((el) => {
            if (el.id === projectType) return el;
          })
          ?.name?.toLowerCase()
          ?.includes(lowerCaseSearchTerm) ||
        projectAddress?.toLowerCase()?.includes(lowerCaseSearchTerm)
      );
    });
  };
  const filteredData = filterUsers(filteredList, toBeSearched);

  const handleAutoCompleteOnChange = (event, newValue) => {
    if (newValue === null || newValue === undefined) {
      setFilteredList(resports);
    } else {
      const filteredData = resports.filter(
        (el) => el.muncipality.name === newValue.name
      );
      setFilteredList(filteredData);
    }
  };

  // const calculateConstructionCost = () => {
  //   let sumConstructionCost = 0;
  //   for (let i = 0; i < filteredData.length; i++) {
  //     if (filteredData[i].constructionCost) {
  //       sumConstructionCost =
  //         sumConstructionCost + filteredData[i].constructionCost;
  //     }
  //   }
  //   return sumConstructionCost.toFixed(2);
  // };

  // const calculateElectricalCost = () => {
  //   let sumElectricalCost = 0;
  //   for (let i = 0; i < filteredData.length; i++) {
  //     if (filteredData[i].electricalCost) {
  //       sumElectricalCost = sumElectricalCost + filteredData[i].electricalCost;
  //     }
  //   }
  //   return sumElectricalCost.toFixed(2);
  // };

  // const calculatePlumberCost = () => {
  //   let sumPlumberCost = 0;
  //   for (let i = 0; i < filteredData.length; i++) {
  //     if (filteredData[i].plumbingCost) {
  //       sumPlumberCost = sumPlumberCost + filteredData[i].plumbingCost;
  //     }
  //   }
  //   return sumPlumberCost.toFixed(2);
  // };

  // const calculateHvacCost = () => {
  //   let sumHvacCost = 0;
  //   for (let i = 0; i < filteredData.length; i++) {
  //     if (filteredData[i].hvacCost) {
  //       sumHvacCost = sumHvacCost + filteredData[i].hvacCost;
  //     }
  //   }
  //   return sumHvacCost.toFixed(2);
  // };

  // const calculatePermitFeeCost = () => {
  //   let sumPermitFeeCost = 0;
  //   for (let i = 0; i < filteredData.length; i++) {
  //     if (filteredData[i].fees) {
  //       sumPermitFeeCost = sumPermitFeeCost + filteredData[i].fees;
  //     }
  //   }
  //   return sumPermitFeeCost.toFixed(2);
  // };

  const generateCSVData = () => {
    setDownload(true);
    const csvData = filteredData.map((row, index) => ({
      "Sr. No": index + 1,
      "Permit #": row.permitNumber,
      "Permit Date": row.createdAt?.split("T")[0],
      Municipality: row?.muncipality?.name,
      "Job Address": row.projectAddress,
      "Building Type": getBuidingType(row.buildingType),
      "Project Type": getProjectTypeList(row.projectType),
      Owner: row.ownerName,
      "Construction Cost":
        row.constructionCost === null ? "$ 0" : `$ ${row.constructionCost}`,
      "Electrical Cost":
        row.electricalCost === null ? "$ 0" : `$ ${row.electricalCost}`,
      "Plumbing Cost":
        row.plumbingCost === null ? "$ 0" : `$ ${row.plumbingCost}`,
      "HVAC Cost": row.hvacCost === null ? "$ 0" : `$ ${row.hvacCost}`,
      "Permit Fees": row.fees ? `$ ${row.fees}` : "$ 0",
      "Payment Status":
        row.paymentDone === true
          ? "Paid"
          : row.paymentDone === false || row.paymentDone === null
          ? "Unpaid"
          : row.paymentDone,
      "Permit Status": row.status,
    }));
    // let csvTotalAmountData = [...csvData];
    // let tempCsvTotalAmountDataObject = {
    //   "Sr. No": csvData?.length + 1,
    //   "Permit #": "",
    //   "Permit Date": "",
    //   Municipality: "",
    //   "Job Address": "",
    //   "Building Type": "",
    //   "Project Type": "",
    //   Owner: "Total Amount",
    //   "Construction Cost": calculateConstructionCost(),
    //   "Electrical Cost": calculateElectricalCost(),
    //   "Plumbing Cost": calculatePlumberCost(),
    //   "HVAC Cost": calculateHvacCost(),
    //   "Permit Fees": calculatePermitFeeCost(),
    //   "Payment Status": "",
    //   "Permit Status": "",
    // };
    // csvTotalAmountData.push(tempCsvTotalAmountDataObject);
    setPermitReportData(csvData);

    setTimeout(() => {
      setDownload(false);
      document.getElementById("csv_link").click();
    }, 2000);
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12} md={4}>
          {/* Search input */}
          <InputBase
            disabled={loading}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
              width: "100%",
              height: "38px",
              border: "1px solid rgba(169, 169, 169, 0.6)",
              borderRadius: 2,
              backgroundColor: "background.bgSearchBar",
              fontFamily: "Poppins",
            }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            startAdornment={
              <Search
                sx={{
                  color: "rgba(151, 151, 151, 0.68);",
                  paddingLeft: "3px",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Autocomplete
            disabled={loading}
            disablePortal
            id="combo-box-demo"
            options={muncipalityList}
            sx={{ fontFamily: "Poppins" }}
            getOptionLabel={(option) => option.name || ""}
            onChange={(event, newValue) =>
              handleAutoCompleteOnChange(event, newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Municipality"
                size="small"
                sx={autoCompleteStyle}
                InputLabelProps={{
                  style: { fontFamily: "Poppins" },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          {/* Start Date input */}
          <TextField
            disabled={loading}
            size="small"
            id="startDate"
            label="Start Date"
            name="startDate"
            type="date"
            onChange={handleDateChange}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          {/* Start Date input */}
          <TextField
            disabled={loading}
            size="small"
            id="startDate"
            label="End Date"
            name="endDate"
            placeholder="End Date"
            type="date"
            onChange={handleDateChange}
            sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          {permitReportData && (
            <CSVLink
              id="csv_link"
              data={permitReportData}
              filename={
                !dateTimeObj.startDate || !dateTimeObj.endDate
                  ? `${Date.now()}.csv`
                  : `${dateTimeObj.startDate} - ${dateTimeObj.endDate}.csv`
              }
            />
          )}
          <Button
            disabled={loading}
            disableRipple
            fullWidth
            onClick={() => generateCSVData()}
            sx={{
              backgroundColor: "background.bgLayoutB",
              width: "100%",
              borderRadius: "10px",
              "&:hover": { backgroundColor: "background.bgLayoutB" },
              "&:disabled": {
                backgroundColor: "rgba(224, 224, 224, 1)",
              },
            }}
          >
            <Typography sx={userHeading} variant="userHeadingBoldButton">
              {download ? "Downloading..." : "Download Report"}
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          maxHeight: 640,
          borderRadius: "10px",
          [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
          ...miniScrollBarStyles,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    backgroundColor: "background.bgLayoutA",
                    minWidth: header.width,
                  }}
                >
                  <Typography sx={userHeading} variant="userTableHeadingBold">
                    {header.name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableLoaderBox, borderBottom: "none" }}
                >
                  <Loader />
                </TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>

                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
              </TableRow>
            ) : filteredData?.length < 1 ? (
              <TableRow>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{ ...tableLoaderBox, borderBottom: "none" }}
                >
                  <Typography sx={userHeading} variant="userTableCellName">
                    No Reports found
                  </Typography>
                </TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>

                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
              </TableRow>
            ) : (
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "150px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.permitNumber}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "190px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {dateTimeApiformat(row.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "220px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row?.muncipality?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "190px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.projectAddress}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "150px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {getBuidingType(row?.buildingType)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "190px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {getProjectTypeList(row?.projectType)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "130px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.ownerName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "220px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.constructionCost === null
                          ? "$ 0"
                          : `$ ${row.constructionCost.toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "150px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.electricalCost === null
                          ? "$ 0"
                          : `$ ${row.electricalCost.toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "150px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.plumbingCost === null
                          ? "$ 0"
                          : `$ ${row.plumbingCost.toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "150px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.hvacCost === null
                          ? "$ 0"
                          : `$ ${row.hvacCost.toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "150px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row?.fees ? `$ ${(row.fees * 1).toFixed(2)}` : "$ 0"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "150px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.paymentDone === true
                          ? "Paid"
                          : row.paymentDone === false ||
                            row.paymentDone === null
                          ? "Unpaid"
                          : row.paymentDone}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "150px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.status}
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredData}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};

export default Index;

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Paper,
//   Table,
//   TableRow,
//   TableHead,
//   TableBody,
//   TableCell,
//   Typography,
//   TableContainer,
//   InputBase,
//   Grid,
//   Autocomplete,
//   TextField,
//   Button,
// } from "@mui/material";
// import Loader from "../Loader/Loader";
// import { Search } from "@mui/icons-material";
// import CustomPagination from "../../hooks/useTablePagination";
// import { useDispatch, useSelector } from "../../redux/store";
// import { getResportsData } from "../../redux/slices/resportsSlice";
// import { getAllMuncipalities } from "../../redux/slices/muncipalitySlice";
// import {
//   StyledTableRow,
//   miniScrollBarStyles,
//   styledTableCell,
//   tableBodyTypos,
//   tableLoaderBox,
//   userHeading,
//   autoCompleteStyle,
//   textfield,
// } from "../../utils/muiComponentStyles";

// import { CSVLink } from "react-csv";

// const projectTypeList = [
//   { id: 1, name: "New Building" },
//   { id: 2, name: "Addition" },
//   { id: 3, name: "Alteration/Repair" },
//   { id: 4, name: "Deck" },
//   { id: 5, name: "Demolition" },
//   { id: 6, name: "Driveway" },
//   { id: 7, name: "Fence" },
//   { id: 8, name: "Pool" },
// ];

// const buildingTypeList = [
//   { id: 1, name: "Single Family" },
//   { id: 2, name: "Duplex" },
//   { id: 3, name: "Multi-Family" },
//   { id: 4, name: "Commercial-Industrial" },
//   { id: 5, name: "Garage/Shed" },
//   // { id: 6, name: "Does not apply" },
// ];

// const headers = [
//   { width: "200px", name: "Permit #" },
//   { width: "200px", name: "Permit Date" },
//   { width: "200px", name: "Municipality" },
//   { width: "200px", name: "Job Address" },
//   { width: "200px", name: "Building Type" },
//   { width: "200px", name: "Project Type" },
//   { width: "200px", name: "Owner" },
//   { width: "250px", name: "Construction Cost" },
//   { width: "200px", name: "Electrical Cost" },
//   { width: "200px", name: "Plumbing Cost" },
//   { width: "200px", name: "HVAC Cost" },
//   { width: "200px", name: "Permit Fees" },
//   { width: "200px", name: "Payment Status" },
//   { width: "200px", name: "Permit Status" },
// ];

// const Index = () => {
//   const dispatch = useDispatch();
//   const [page, setPage] = useState(() => {
//     // Retrieve the page from localStorage or default to 0
//     const savedPage = localStorage.getItem("pageReport");
//     return savedPage ? Number(savedPage) : 0;
//   });
//   const [loading, setLoading] = useState(true);
//   const [rowsPerPage, setRowsPerPage] = useState(() => {
//     // Retrieve the rowsPerPage from localStorage or default to 10
//     const savedRowsPerPage = localStorage.getItem("rowsPerPageReport");
//     return savedRowsPerPage ? Number(savedRowsPerPage) : 10;
//   });
//   const [filteredList, setFilteredList] = useState([]);
//   const [toBeSearched, setToBeSearched] = useState(() => {
//     // Retrieve the search input from localStorage or default to an empty string
//     return localStorage.getItem("searchInput") || "";
//   });
//   const [selectedMunicipality, setSelectedMunicipality] = useState(() => {
//     // Retrieve the selected municipality from localStorage or default to null
//     const savedMunicipality = localStorage.getItem("selectedMunicipality");
//     return savedMunicipality ? JSON.parse(savedMunicipality) : null;
//   });
//   const [dateTimeObj, setDateTimeObj] = useState({});
//   const [permitReportData, setPermitReportData] = useState([]);
//   const [download, setDownload] = useState(false);

//   useEffect(() => {
//     dispatch(getResportsData(handleApiResponse));
//     dispatch(getAllMuncipalities());
//   }, []);

//   let { resports } = useSelector((state) => state.resportsReducer);
//   let { muncipalityList } = useSelector((state) => state.muncipalityReducer);

//   const handleApiResponse = (response) => {
//     if (response.status) {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     setFilteredList(resports);
//   }, [resports]);

//   useEffect(() => {
//     setFilteredList(resports);
//     if (!dateTimeObj.startDate && !dateTimeObj.endDate) {
//       setFilteredList(resports);
//       return;
//     }
//   }, [resports, dateTimeObj]);

//   useEffect(() => {
//     handleDateSearch();
//   }, [dateTimeObj]);

//   const getBuidingType = (data) => {
//     const matchingBuildingType = buildingTypeList.find(
//       (buildingType) => buildingType.id === data
//     );
//     if (matchingBuildingType) {
//       return matchingBuildingType.name;
//     } else {
//       return "N/A";
//     }
//   };

//   const getProjectTypeList = (data) => {
//     const matchingProjectType = projectTypeList.find(
//       (projectType) => projectType.id === data
//     );
//     if (matchingProjectType) {
//       return matchingProjectType.name;
//     } else {
//       return "N/A";
//     }
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage - 1);
//     localStorage.setItem("pageReport", newPage - 1); // Persist the page in localStorage
//   };

//   const handleRowsPerPageChange = (newRowsPerPage) => {
//     setRowsPerPage(newRowsPerPage);
//     localStorage.setItem("rowsPerPageReport", newRowsPerPage); // Persist the rowsPerPage in localStorage
//     setPage(0); // Reset to the first page when changing rows per page
//     localStorage.setItem("pageReport", 0); // Persist the reset page in localStorage
//   };

//   const handleDateChange = (event) => {
//     let tempdateTimeObj = { ...dateTimeObj };
//     tempdateTimeObj[event.target.name] = event.target.value;
//     setDateTimeObj(tempdateTimeObj);
//   };

//   const handleDateSearch = () => {
//     const filteredApplicants = resports.filter((u) => {
//       // Filter by date (without considering the time)
//       const startDateMatch =
//         !dateTimeObj.startDate ||
//         u.createdAt.substring(0, 10) >= dateTimeObj.startDate;
//       const endDateMatch =
//         !dateTimeObj.endDate ||
//         u.createdAt.substring(0, 10) <= dateTimeObj.endDate;
//       return startDateMatch && endDateMatch;
//     });

//     // Update the filtered data state when the Search button is clicked
//     setFilteredList(filteredApplicants);
//   };

//   const dateTimeApiformat = (currentDate) => {
//     const date = new Date(currentDate);
//     const year = date.getFullYear();
//     const month = ("0" + (date.getMonth() + 1)).slice(-2);
//     const day = ("0" + date.getDate()).slice(-2);
//     const newDT = `${month}/${day}/${year}`;

//     return newDT;
//   };

//   const filterUsers = (reports, searchTerm) => {
//     const lowerCaseSearchTerm = searchTerm.toLowerCase();
//     return reports.filter((report) => {
//       // Destructure report properties for easier access
//       const {
//         ownerAddress,
//         ownerName,
//         projectType,
//         projectAddress,
//         permitNumber,
//       } = report;

//       return (
//         ownerAddress?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
//         ownerName?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
//         permitNumber?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
//         projectTypeList
//           .find((el) => {
//             if (el.id === projectType) return el;
//           })
//           ?.name?.toLowerCase()
//           ?.includes(lowerCaseSearchTerm) ||
//         projectAddress?.toLowerCase()?.includes(lowerCaseSearchTerm)
//       );
//     });
//   };
//   const filteredData = filterUsers(filteredList, toBeSearched);

//   const handleSearch = (value) => {
//     setToBeSearched(value);
//     localStorage.setItem("searchInput", value); // Persist the search input
//   };

//   const handleAutoCompleteOnChange = (event, newValue) => {
//     if (newValue === null || newValue === undefined) {
//       setFilteredList(resports);
//       setSelectedMunicipality(null);
//       localStorage.removeItem("selectedMunicipality"); // Clear the persisted municipality
//     } else {
//       setFilteredList(
//         resports.filter((el) => el.muncipality.name === newValue.name)
//       );
//       setSelectedMunicipality(newValue);
//       localStorage.setItem("selectedMunicipality", JSON.stringify(newValue)); // Persist the selected municipality
//     }
//   };

//   const generateCSVData = () => {
//     setDownload(true);
//     const csvData = filteredData.map((row, index) => ({
//       "Sr. No": index + 1,
//       "Permit #": row.permitNumber,
//       "Permit Date": row.createdAt?.split("T")[0],
//       Municipality: row?.muncipality?.name,
//       "Job Address": row.projectAddress,
//       "Building Type": getBuidingType(row.buildingType),
//       "Project Type": getProjectTypeList(row.projectType),
//       Owner: row.ownerName,
//       "Construction Cost":
//         row.constructionCost === null ? "$ 0" : `$ ${row.constructionCost}`,
//       "Electrical Cost":
//         row.electricalCost === null ? "$ 0" : `$ ${row.electricalCost}`,
//       "Plumbing Cost":
//         row.plumbingCost === null ? "$ 0" : `$ ${row.plumbingCost}`,
//       "HVAC Cost": row.hvacCost === null ? "$ 0" : `$ ${row.hvacCost}`,
//       "Permit Fees": row.fees ? `$ ${row.fees}` : "$ 0",
//       "Payment Status":
//         row.paymentDone === true
//           ? "Paid"
//           : row.paymentDone === false || row.paymentDone === null
//           ? "Unpaid"
//           : row.paymentDone,
//       "Permit Status": row.status,
//     }));

//     // csvTotalAmountData.push(tempCsvTotalAmountDataObject);
//     setPermitReportData(csvData);

//     setTimeout(() => {
//       setDownload(false);
//       document.getElementById("csv_link").click();
//     }, 2000);
//   };

//   return (
//     <Box>
//       <Grid container spacing={2} sx={{ my: 2 }}>
//         <Grid item xs={12} md={4}>
//           {/* Search input */}
//           <InputBase
//             disabled={loading}
//             value={toBeSearched} // Bind search input value
//             onChange={(e) => handleSearch(e.target.value)}
//             sx={{
//               width: "100%",
//               height: "38px",
//               border: "1px solid rgba(169, 169, 169, 0.6)",
//               borderRadius: 2,
//               backgroundColor: "background.bgSearchBar",
//               fontFamily: "Poppins",
//             }}
//             placeholder="Search"
//             inputProps={{ "aria-label": "search" }}
//             startAdornment={
//               <Search
//                 sx={{
//                   color: "rgba(151, 151, 151, 0.68);",
//                   paddingLeft: "3px",
//                 }}
//               />
//             }
//           />
//         </Grid>
//         <Grid item xs={6} md={2}>
//           <Autocomplete
//             disabled={loading}
//             disablePortal
//             id="combo-box-demo"
//             options={muncipalityList}
//             sx={{ fontFamily: "Poppins" }}
//             getOptionLabel={(option) => option.name || ""}
//             value={selectedMunicipality} // Bind selected municipality value
//             onChange={(event, newValue) =>
//               handleAutoCompleteOnChange(event, newValue)
//             }
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Select Municipality"
//                 size="small"
//                 sx={autoCompleteStyle}
//                 InputLabelProps={{
//                   style: { fontFamily: "Poppins" },
//                 }}
//               />
//             )}
//           />
//         </Grid>
//         <Grid item xs={6} md={2}>
//           {/* Start Date input */}
//           <TextField
//             disabled={loading}
//             size="small"
//             id="startDate"
//             label="Start Date"
//             name="startDate"
//             type="date"
//             onChange={handleDateChange}
//             inputProps={{
//               max: new Date().toISOString().split("T")[0],
//             }}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
//           />
//         </Grid>
//         <Grid item xs={6} md={2}>
//           {/* Start Date input */}
//           <TextField
//             disabled={loading}
//             size="small"
//             id="startDate"
//             label="End Date"
//             name="endDate"
//             placeholder="End Date"
//             type="date"
//             onChange={handleDateChange}
//             sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//         </Grid>
//         <Grid item xs={6} md={2}>
//           {permitReportData && (
//             <CSVLink
//               id="csv_link"
//               data={permitReportData}
//               filename={
//                 !dateTimeObj.startDate || !dateTimeObj.endDate
//                   ? `${Date.now()}.csv`
//                   : `${dateTimeObj.startDate} - ${dateTimeObj.endDate}.csv`
//               }
//             />
//           )}
//           <Button
//             disabled={loading}
//             disableRipple
//             fullWidth
//             onClick={() => generateCSVData()}
//             sx={{
//               backgroundColor: "background.bgLayoutB",
//               width: "100%",
//               borderRadius: "10px",
//               "&:hover": { backgroundColor: "background.bgLayoutB" },
//               "&:disabled": {
//                 backgroundColor: "rgba(224, 224, 224, 1)",
//               },
//             }}
//           >
//             <Typography sx={userHeading} variant="userHeadingBoldButton">
//               {download ? "Downloading..." : "Download Report"}
//             </Typography>
//           </Button>
//         </Grid>
//       </Grid>

//       <TableContainer
//         component={Paper}
//         elevation={0}
//         sx={{
//           maxHeight: 640,
//           borderRadius: "10px",
//           [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
//           ...miniScrollBarStyles,
//         }}
//       >
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
//             <TableRow>
//               {headers.map((header, index) => (
//                 <TableCell
//                   key={index}
//                   sx={{
//                     backgroundColor: "background.bgLayoutA",
//                     minWidth: header.width,
//                   }}
//                 >
//                   <Typography sx={userHeading} variant="userTableHeadingBold">
//                     {header.name}
//                   </Typography>
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {loading ? (
//               <TableRow>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   align="center"
//                   sx={{ ...tableLoaderBox, borderBottom: "none" }}
//                 >
//                   <Loader />
//                 </TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>

//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//               </TableRow>
//             ) : filteredData?.length < 1 ? (
//               <TableRow>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   align="center"
//                   sx={{ ...tableLoaderBox, borderBottom: "none" }}
//                 >
//                   <Typography sx={userHeading} variant="userTableCellName">
//                     No Reports found
//                   </Typography>
//                 </TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>

//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//                 <TableCell
//                   sx={styledTableCell}
//                   component="th"
//                   scope="row"
//                 ></TableCell>
//               </TableRow>
//             ) : (
//               filteredData
//                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row) => (
//                   <StyledTableRow
//                     hover
//                     key={row.id}
//                     sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                   >
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "150px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.permitNumber}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "190px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {dateTimeApiformat(row.createdAt)}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "220px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row?.muncipality?.name}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "190px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.projectAddress}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "150px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {getBuidingType(row?.buildingType)}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "190px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {getProjectTypeList(row?.projectType)}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "130px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.ownerName}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "220px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.constructionCost === null
//                           ? "$ 0"
//                           : `$ ${row.constructionCost.toFixed(2)}`}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "150px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.electricalCost === null
//                           ? "$ 0"
//                           : `$ ${row.electricalCost.toFixed(2)}`}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "150px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.plumbingCost === null
//                           ? "$ 0"
//                           : `$ ${row.plumbingCost.toFixed(2)}`}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "150px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.hvacCost === null
//                           ? "$ 0"
//                           : `$ ${row.hvacCost.toFixed(2)}`}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "150px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row?.fees ? `$ ${(row.fees * 1).toFixed(2)}` : "$ 0"}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "150px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.paymentDone === true
//                           ? "Paid"
//                           : row.paymentDone === false ||
//                             row.paymentDone === null
//                           ? "Unpaid"
//                           : row.paymentDone}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: "150px",
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={tableBodyTypos}
//                         variant="tableBodyCellName"
//                       >
//                         {row.status}
//                       </Typography>
//                     </TableCell>
//                   </StyledTableRow>
//                 ))
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <CustomPagination
//         page={page}
//         setPage={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         handleChangePage={handleChangePage}
//         filteredList={filteredData}
//         setRowsPerPage={handleRowsPerPageChange}
//       />
//     </Box>
//   );
// };

// export default Index;
