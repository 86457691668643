// import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showToastMessage = () => {
  toast.success("User Created Successfully !", {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const showToastErrMessage = () => {
  toast.error("Invalid Role id !", {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  timezoneutcoffset: new Date().getTimezoneOffset(),
};
const initialState = {
  usersList: [],
  userRolesWithpermissionsList: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUsers(state, action) {
      state.usersList = action.payload.data;
    },
    postUsers(state, action) {
      state.usersList = action.payload("");
    },
    getUserRolesWithPermissions(state, action) {
      state.userRolesWithpermissionsList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getUsersList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/users`,
        { headers }
      );

      dispatch(slice.actions.getUsers(response.data));
      if (handleApiRes) {
        handleApiRes(response);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error.response.data.message);
      }
    }
  };
}

export function createUser(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/users`,
        data,
        {
          headers,
        }
      );
      dispatch(getUsersList());
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error.response.data.message);
      }
    }
  };
}

export function updateUser(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/users?id=${id}`,
        data,
        {
          headers,
        }
      );
      dispatch(getUsersList());
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error.response.data.message);
      }
    }
  };
}

export function updateUserStatus(id, status, handleUpdateStatusApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/users/change-status?id=${id}&active=${status}`,
        {},
        { headers }
      );
      if (handleUpdateStatusApiRes) {
        handleUpdateStatusApiRes(response.data);
      }
      dispatch(getUsersList());
    } catch (error) {
      if (handleUpdateStatusApiRes) {
        handleUpdateStatusApiRes(error?.response?.data);
      }
    }
  };
}
export function postUsersList(data) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/users`,
        data,
        {
          headers,
        }
      );
      response.status == 200 ? showToastMessage() : null;

      dispatch(getUsersList());
    } catch (error) {
      showToastErrMessage();
    }
  };
}

export function getUserRolesWithPermissionsList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roles/get-users-with-roles`,
        { headers }
      );

      dispatch(slice.actions.getUserRolesWithPermissions(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function updateUserRolesPermissions(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roles/add-or-remove-user-roles`,
        { data: data },
        { headers }
      );
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error.response.data.message);
      }
    }
  };
}

export function deleteUser(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/users?id=${id}`,
        { headers }
      );
      handleApiRes(response.data);
      dispatch(getUsersList());
    } catch (error) {
      handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}
