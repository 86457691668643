import React, { useContext, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  CardHeader,
  TextField,
  SvgIcon,
  Box,
  Stack,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  applicationCardStyle,
  miniScrollBarStyles,
  textfield,
  userHeading,
} from "../../../utils/muiComponentStyles";
import ToasterContext from "../../../utils/context/toasterContext";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateContractor } from "../../../redux/slices/contractorSlice";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedContractor = JSON.parse(
    localStorage.getItem("selectedContractor")
  );
  const [generalContractorObject, setGeneralContractorObject] =
    useState(selectedContractor);
  const [generalEmailError, setGeneralEmailError] = useState(false);
  const [generalContractorLoader, setGeneralContractorLoader] = useState(false);
  const [saveGeneralContractorPressed, setSaveGeneralContractorPressed] =
    useState(false);
  const fireToasterContext = useContext(ToasterContext);

  const handleGeneralContractorChange = (event) => {
    event.preventDefault();
    setGeneralContractorObject({
      ...generalContractorObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleGeneralContractorApiResponse = (data) => {
    setGeneralContractorLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "contractor created"
      );
      navigate("/contractors");
      // localStorage.removeItem("selectedContractor");
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleGeneralContractorSubmit = () => {
    setSaveGeneralContractorPressed(true);
    if (generalContractorObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(generalContractorObject.email)) {
        setGeneralEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setGeneralEmailError(false);
      }
    }

    if (
      !generalContractorObject?.name ||
      !generalContractorObject?.companyName ||
      !generalContractorObject?.email ||
      !generalContractorObject?.phoneNumber
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For General Contractor"
      );
      return;
    }

    const finalGeneralContractorObject = {
      type: selectedContractor?.type,
      id: selectedContractor?.id,
      temporary: selectedContractor.temporary,
      name: generalContractorObject?.name,
      email: generalContractorObject?.email,
      companyName: generalContractorObject?.companyName,
      phoneNumber: generalContractorObject?.phoneNumber,
    };
    setGeneralContractorLoader(true);
    dispatch(
      updateContractor(
        finalGeneralContractorObject,
        handleGeneralContractorApiResponse
      )
    );
  };
  return (
    <Container>
      <Card>
        <CardHeader
          disableTypography
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>{`Update ${selectedContractor?.type} Contractor details`}</Box>
              <Box
                sx={{
                  display:
                    selectedContractor.temporary === false ? "none" : "flex",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <SvgIcon>
                  <ErrorOutlineRoundedIcon sx={{ color: "#d52127" }} />
                </SvgIcon>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    fontSize: "15px",
                    ml: 1,
                  }}
                >
                  {selectedContractor.temporary === true
                    ? "Add contractor to Database"
                    : null}
                </Typography>
              </Box>
            </Stack>
          }
          sx={applicationCardStyle}
        />
        <ValidatorForm
          style={{ width: "100%" }}
          onSubmit={handleGeneralContractorSubmit}
        >
          <CardContent
            sx={{
              mt: 2,
              mx: 2,
              height: "230px",
              overflowY: "auto",
              [`::-webkit-scrollbar`]: {
                width: "0px",
                background: "transparent",
              },
              ...miniScrollBarStyles,
            }}
          >
            <Grid container spacing={2} sx={{ my: "5px" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !generalContractorObject.name &&
                    saveGeneralContractorPressed
                  }
                  helperText={
                    !generalContractorObject.name &&
                    saveGeneralContractorPressed
                      ? "Name is required"
                      : ""
                  }
                  id="general-contractor-name"
                  value={generalContractorObject.name}
                  name="name"
                  label="General Contractor Name"
                  sx={textfield}
                  onChange={handleGeneralContractorChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !generalContractorObject.companyName &&
                    saveGeneralContractorPressed
                  }
                  helperText={
                    !generalContractorObject.companyName &&
                    saveGeneralContractorPressed
                      ? "Company name is required"
                      : ""
                  }
                  id="general-contractor-company-name"
                  label="General Contractor Company"
                  name="companyName"
                  value={generalContractorObject.companyName}
                  sx={textfield}
                  onChange={handleGeneralContractorChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="dwelling-contractor-email"
                  label="General Contractor Email"
                  name="email"
                  value={generalContractorObject.email}
                  type="email"
                  sx={textfield}
                  onChange={handleGeneralContractorChange}
                  error={
                    generalEmailError ||
                    (!generalContractorObject?.email &&
                      saveGeneralContractorPressed)
                  }
                  helperText={
                    generalEmailError ||
                    (!generalContractorObject?.email &&
                      saveGeneralContractorPressed)
                      ? "Please enter a valid email address"
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !generalContractorObject.phoneNumber &&
                    saveGeneralContractorPressed
                  }
                  helperText={
                    !generalContractorObject.phoneNumber &&
                    saveGeneralContractorPressed
                      ? "Phone no is required"
                      : ""
                  }
                  id="dwelling-contractor-phone-number"
                  label="General Contractor Phone No."
                  name="phoneNumber"
                  value={generalContractorObject.phoneNumber}
                  sx={textfield}
                  onChange={handleGeneralContractorChange}
                  inputProps={{ min: 1 }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              disableRipple
              type="submit"
              disabled={generalContractorLoader}
              sx={
                generalContractorLoader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "187px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutB",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "187px",
                      "&:hover": { backgroundColor: "background.bgLayoutB" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={userHeading} variant="userHeadingBoldButton">
                Update
              </Typography>
              {generalContractorLoader ? <CircularProgressLoader /> : ""}
            </Button>
          </CardActions>
        </ValidatorForm>
      </Card>
    </Container>
  );
};

export default Index;
