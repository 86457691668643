import React, { useContext, useEffect, useState } from "react";
// import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { compressionOption } from "../../utils/imageCompression";
import CloseIcon from "@mui/icons-material/Close";
import PdfPreview from "./../../assets/application-media-previews/wipermit-pdf-thumbnail.png";
import DocsPreview from "./../../assets/application-media-previews/wipermit-docs-thumbnail.jpg";
import PowerPointPreview from "./../../assets/application-media-previews/wipermit-powerpoint-thumbnail.jpg";
import ExcelPreview from "./../../assets/application-media-previews/wipermit-xls-thumbnail.jpg";

import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import Swal from "sweetalert2";
import "./applicationform.css";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  applicationFormCreateButton,
  applicationFormJobDescriptionField,
  applicationFormSearchButton,
  applicationFormSubmitButton,
  applicationFormTitleField,
  applicationTitleTypography,
  applicationWisconsinTypography,
  applicationWisconsinTypographySubtitle,
  cancelButton,
  saveButton,
  tableDataCell,
  tableDataRow,
  tableHeadCell,
  textfieldDropdownStyle,
  // autocompleteMuncipality,
  textfieldStyle,
} from "./ApplicationFormStyle";
import { AddBoxOutlined } from "@mui/icons-material";
import { getApplicationFormMunicipalities } from "../../redux/slices/muncipalitySlice";
import { dispatch, useSelector } from "../../redux/store";
import {
  getAllContractors,
  createContractor,
} from "../../redux/slices/contractorSlice";
import {
  createApplication,
  uploadApplicationMedia,
} from "../../redux/slices/dashboard/applicantsSlice";
import toasterContext from "../../utils/context/toasterContext";
import CustomPagination from "../../hooks/useTablePagination";
import { textfieldDate } from "../../utils/muiComponentStyles";
import ContractorDetail from "./ContractorDetail";
import getShortFileName from "../../utils/fileName";

// import image from "./../../assets/pdficon.png";
// import axios from "axios";

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const buildingTypeList = [
  { id: 1, name: "Single Family" },
  { id: 2, name: "Duplex" },
  { id: 3, name: "Multi-Family" },
  { id: 4, name: "Commercial-Industrial" },
  { id: 5, name: "Garage/Shed" },
  // { id: 6, name: "Does not apply" },
];

const permitRequested = [
  { id: 1, name: "Construction" },
  { id: 2, name: "HVAC" },
  { id: 3, name: "Electric" },
  { id: 4, name: "Plumbing" },
  { id: 5, name: "Erosion Control" },
  { id: 6, name: "Deck" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Driveway" },
  { id: 9, name: "Pool" },
  { id: 10, name: "Other" },
];

const personSubmittingApplication = [
  {
    id: 1,
    name: "Contractor",
  },
  {
    id: 2,
    name: "Owner",
  },
];

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});

const Index = ({ setIsCautionary }) => {
  const fireToasterContext = useContext(toasterContext);
  const [applicationFormObject, setApplicationFormObject] = useState({});
  const [contractorObject, setContractorObject] = useState({});
  const [generalContractorObject, setGeneralContractorObject] = useState({});
  const [electricalObject, setElectricalObject] = useState({});
  const [plumberObject, setPlumberObject] = useState({});
  const [heatObject, setHeatObject] = useState({});
  const [muncipalityId, setMuncipalityId] = useState();
  const [projectType, setProjectType] = useState();
  const [buildingType, setBuildingType] = useState();
  const [showDwellContractor, setShowDwellContractor] = useState(false);
  const [showDwellContractorForm, setShowDwellContractorForm] = useState(false);
  const [showGeneralContractor, setShowGeneralContractor] = useState(false);
  const [showGeneralContractorForm, setShowGeneralContractorForm] =
    useState(false);
  const [showElectricalTable, setShowElecrtricalTable] = useState(false);
  const [showElecrticalForm, setShowElecrtricalForm] = useState(false);
  const [showPlumbingTable, setShowPlumbingTable] = useState(false);
  const [showPlumbingForm, setShowPlumbingForm] = useState(false);
  const [showHVACTable, setShowHVACTable] = useState(false);
  const [showHVACForm, setShowHVACForm] = useState(false);
  const [showPermitOtherField, setShowPermitOtherField] = useState(false);
  const [dwellContractors, setDwellContractors] = useState([]);
  const [generalContractors, setGeneralContractors] = useState([]);
  const [electricalContractors, setElectricalContractors] = useState([]);
  const [plumberContractors, setPlumberContractors] = useState([]);
  const [hvacContractors, setHVACContractors] = useState([]);
  const [permitIds, setPermitIds] = useState([]);
  const [personSubmittingApplicationName, setPersonSubmittingApplicationName] =
    useState();
  const [contractorId, setContractorId] = useState();
  const [generalContractorId, setGeneralContractorId] = useState();
  const [electricalId, setElectricalId] = useState();
  const [plumberId, setPlumberId] = useState();
  const [hvacId, setHvacId] = useState();
  // const [previews, setPreviews] = useState([]);
  // const [selectedMedia, setselectedMedia] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);

  const [disableFileInput, setDisableFileInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const [contractorLoader, setContractorLoader] = useState(false);
  const [generalContractorLoader, setGeneralContractorLoader] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [saveContractorPressed, setSaveContractorPressed] = useState(false);
  const [saveGeneralContractorPressed, setSaveGeneralContractorPressed] =
    useState(false);
  const [saveElectricalPressed, setSaveElectricalPressed] = useState(false);
  const [savePlumberPressed, setSavePlumberPressed] = useState(false);
  const [saveHeatingPressed, setSaveHeatingPressed] = useState(false);
  const [dwellingContractorSearchString, setDwellingContractorSearchString] =
    useState("");
  const [generalContractorSearchString, setGeneralContractorSearchString] =
    useState("");
  const [electicalContractorSearchString, setElecticalContractorSearchString] =
    useState("");
  const [plumbingContractorSearchString, setPlumbingContractorSearchString] =
    useState("");
  const [hvacContractorSearchString, setHvacContractorSearchString] =
    useState("");
  const [dwellContractorPage, setDwellContractorPage] = useState(0);
  const [rowsPerPageForDwellContractorPage, setRowsPerPageForDwellContractors] =
    useState(10);
  const [generalContractorPage, setGeneralContractorPage] = useState(0);
  const [
    rowsPerPageForGeneralContractorPage,
    setRowsPerPageForGeneralContractors,
  ] = useState(10);
  const [electricalContractorPage, setElectricalContractorPage] = useState(0);
  const [
    rowsPerPageForElectricalContractor,
    setRowsPerPageForElectricalContractor,
  ] = useState(10);
  const [plumberContractorPage, setPlumberContractorPage] = useState(0);
  const [rowsPerPageForPlumberContractor, setRowsPerPageForPlumberContractor] =
    useState(10);
  const [hvacContractorPage, setHvacContractorPage] = useState(0);
  const [rowsPerPageForHvacContractor, setRowsPerPageForHvacContractor] =
    useState(10);
  const [ownerEmailError, setOwnerEmailError] = useState(false);
  const [dwellEmailError, setDwellEmailError] = useState(false);
  const [dwellQualifierEmailError, setDwellQualifierEmailError] =
    useState(false);
  const [generalEmailError, setGeneralEmailError] = useState(false);
  const [electricalEmailError, setElectricalEmailError] = useState(false);
  const [electricalMasterEmailError, setElectrcialMasterEmailError] =
    useState(false);
  const [plumberEmailError, setPlumberEmailError] = useState(false);
  const [hvacEmailError, setHvacEmailError] = useState(false);
  const [applicantEmailError, setApplicantEmailError] = useState(false);
  const [selectedContractorDataShow, setSelectedContractorDataShow] =
    useState(false);
  const [selectedContractorData, setSelectedContractorData] = useState();
  const [selectedElectricalDataShow, setSelectedElectricalDataShow] =
    useState(false);
  const [selectedGeneralData, setSelectedGeneralData] = useState();
  const [selectedGeneralDataShow, setSelectedGeneralDataShow] = useState(false);
  const [selectedElectrcialData, setSelectedElectricalData] = useState();
  const [selectedPlumberDataShow, setSelectedPlumberDataShow] = useState(false);
  const [selectedPlumberData, setSelectedPlumberData] = useState();
  const [selectedHvacDataShow, setSelectedHvacDataShow] = useState(false);
  const [selectedHvacData, setSelectedHvacData] = useState();
  const [contractorListArray, setContractorListArray] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const { applicationFormMunicipalityList } = useSelector(
    (state) => state.muncipalityReducer
  );

  const { contractorList } = useSelector((state) => state.contractorReducer);

  useEffect(() => {
    dispatch(getApplicationFormMunicipalities());
    dispatch(getAllContractors());
    return () => {
      setApplicationFormObject({});
      setContractorObject({});
      setGeneralContractorObject({});
      setElectricalObject({});
      setPlumberObject({});
      setHeatObject({});
      setMuncipalityId();
      setProjectType();
      setBuildingType();
      setShowDwellContractor(false);
      setShowDwellContractorForm(false);
      setShowGeneralContractor(false);
      setShowGeneralContractorForm(false);
      setShowElecrtricalTable(false);
      setShowElecrtricalForm(false);
      setShowPlumbingTable(false);
      setShowPlumbingForm(false);
      setShowHVACTable(false);
      setShowHVACForm(false);
      setShowPermitOtherField(false);
      setDwellContractors([]);
      setGeneralContractors([]);
      setElectricalContractors([]);
      setPlumberContractors([]);
      setHVACContractors([]);
      setPermitIds([]);
      setPersonSubmittingApplicationName();
      setContractorId();
      setGeneralContractorId();
      setElectricalId();
      setPlumberId();
      setHvacId();
      setPreviewImages([]);
      setSelectedMedia([]);
      setDisableFileInput(false);
      setLoader(false);
      setContractorLoader(false);
      setGeneralContractorLoader(false);
      setSubmitPressed(false);
      setSaveContractorPressed(false);
      setSaveGeneralContractorPressed(false);
      setSaveElectricalPressed(false);
      setSavePlumberPressed(false);
      setSaveHeatingPressed(false);
      setDwellingContractorSearchString("");
      setGeneralContractorSearchString("");
      setElecticalContractorSearchString("");
      setPlumbingContractorSearchString("");
      setHvacContractorSearchString("");
      setOwnerEmailError(false);
      setDwellEmailError(false);
      setDwellQualifierEmailError(false);
      setGeneralEmailError(false);
      setElectricalEmailError(false);
      setElectrcialMasterEmailError(false);
      setPlumberEmailError(false);
      setHvacEmailError(false);
      setApplicantEmailError(false);
      setSelectedContractorDataShow(false);
      setSelectedContractorData();
      setSelectedElectricalDataShow(false);
      setSelectedGeneralData();
      setSelectedGeneralDataShow(false);
      setSelectedElectricalData();
      setSelectedPlumberDataShow(false);
      setSelectedPlumberData();
      setSelectedHvacDataShow(false);
      setSelectedHvacData();
      setContractorListArray([]);
    };
  }, []);

  useEffect(() => {
    setContractorListArray(contractorList);
  }, [contractorList]);

  const handleDwellContractorChangePage = (event, newPage) => {
    setDwellContractorPage(newPage - 1);
  };

  const handleGeneralContractorChangePage = (event, newPage) => {
    setRowsPerPageForGeneralContractors(newPage - 1);
  };

  const handleElectricalContractorChangePage = (event, newPage) => {
    setElectricalContractorPage(newPage - 1);
  };

  const handlePlumberContractorChangePage = (event, newPage) => {
    setPlumberContractorPage(newPage - 1);
  };

  const handleHvacContractorChangePage = (event, newPage) => {
    setHvacContractorPage(newPage - 1);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setApplicationFormObject({
      ...applicationFormObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleContractorChange = (event) => {
    event.preventDefault();
    setContractorObject({
      ...contractorObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleGeneralContractorChange = (event) => {
    event.preventDefault();
    setGeneralContractorObject({
      ...generalContractorObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleElectricalChange = (event) => {
    event.preventDefault();
    setElectricalObject({
      ...electricalObject,
      [event.target.name]: event.target.value,
    });
  };

  const handlePlumberChange = (event) => {
    event.preventDefault();
    setPlumberObject({
      ...plumberObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleHeatObjectChange = (event) => {
    event.preventDefault();
    setHeatObject({
      ...heatObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheckBox = (event) => {
    setApplicationFormObject({
      ...applicationFormObject,
      [event.target.name]: event.target.checked,
    });
  };

  const handleContractorSearchString = (searchString, contractorType) => {
    if (contractorType == "dwellingContractor") {
      setDwellingContractorSearchString(searchString);
    } else if (contractorType == "generalContractor") {
      setGeneralContractorSearchString(searchString);
    } else if (contractorType == "electricContractor") {
      setElecticalContractorSearchString(searchString);
    } else if (contractorType == "plumberContractor") {
      setPlumbingContractorSearchString(searchString);
    } else if (contractorType == "HVACContractor") {
      setHvacContractorSearchString(searchString);
    }
  };

  const handleFilterContractorType = (contractorType) => {
    let filterContractors = [];
    if (contractorType == "dwellingContractor") {
      filterContractors = contractorListArray.filter(
        (contractor) =>
          contractor.type === "Construction" &&
          (contractor?.name
            ?.toLowerCase()
            .includes(dwellingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(dwellingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.licenseNumber
              ?.toLowerCase()
              .includes(dwellingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.phoneNumber
              ?.toLowerCase()
              .includes(dwellingContractorSearchString?.toLocaleLowerCase()))
      );
      setDwellContractors(filterContractors);
      setShowDwellContractor(true);
      setShowDwellContractorForm(false);
    }
    if (contractorType == "generalContractor") {
      filterContractors = contractorListArray.filter(
        (contractor) =>
          contractor.type === "General" &&
          (contractor?.name
            ?.toLowerCase()
            .includes(generalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(generalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.email
              ?.toLowerCase()
              .includes(generalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.phoneNumber
              ?.toLowerCase()
              .includes(generalContractorSearchString?.toLocaleLowerCase()))
      );
      setGeneralContractors(filterContractors);
      setShowGeneralContractor(true);
      setShowGeneralContractorForm(false);
    } else if (contractorType == "electricContractor") {
      filterContractors = contractorListArray.filter(
        (contractor) =>
          contractor.type === "Electrical" &&
          (contractor?.masterElectricianLicenseNumber
            ?.toLowerCase()
            .includes(electicalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(electicalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.licenseNumber
              ?.toLowerCase()
              .includes(electicalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.phoneNumber
              ?.toLowerCase()
              .includes(electicalContractorSearchString?.toLocaleLowerCase()))
      );
      setElectricalContractors(filterContractors);
      setShowElecrtricalTable(true);
      setShowElecrtricalForm(false);
    } else if (contractorType == "plumberContractor") {
      filterContractors = contractorListArray.filter(
        (contractor) =>
          contractor.type === "Plumbing" &&
          (contractor?.phoneNumber
            ?.toLowerCase()
            .includes(plumbingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(plumbingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.licenseNumber
              ?.toLowerCase()
              .includes(plumbingContractorSearchString?.toLocaleLowerCase()))
      );
      setPlumberContractors(filterContractors);
      setShowPlumbingTable(true);
      setShowPlumbingForm(false);
    } else if (contractorType == "HVACContractor") {
      filterContractors = contractorListArray.filter(
        (contractor) =>
          contractor.type === "HVAC" &&
          (contractor?.phoneNumber
            ?.toLowerCase()
            .includes(hvacContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(hvacContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.licenseNumber
              ?.toLowerCase()
              .includes(hvacContractorSearchString?.toLocaleLowerCase()))
      );
      setHVACContractors(filterContractors);
      setShowHVACTable(true);
      setShowHVACForm(false);
    }
  };

  const handleContractorTableRowClick = (data) => {
    setShowDwellContractor(false);
    setSelectedContractorDataShow(true);
    setContractorId(data.id);
    setSelectedContractorData(data);
  };

  const handleGeneralTableRowClick = (data) => {
    setShowGeneralContractor(false);
    setSelectedGeneralDataShow(true);
    setGeneralContractorId(data.id);
    setSelectedGeneralData(data);
  };

  const handleElectricalTableRowClick = (data) => {
    setShowElecrtricalTable(false);
    setSelectedElectricalDataShow(true);
    setElectricalId(data.id);
    setSelectedElectricalData(data);
  };

  const handlePlumberTableRowClick = (data) => {
    setShowPlumbingTable(false);
    setSelectedPlumberDataShow(true);
    setPlumberId(data.id);
    setSelectedPlumberData(data);
  };

  const handleHvacTableRowClick = (data) => {
    setShowHVACTable(false);
    setSelectedHvacDataShow(true);
    setHvacId(data.id);
    setSelectedHvacData(data);
  };

  const handleShowGeneralSelectedFormClose = () => {
    setShowGeneralContractor(true);
    setGeneralContractorId();
    setSelectedGeneralData();
    setSelectedGeneralDataShow(false);
  };

  const handleShowSelectedFormClose = () => {
    setShowDwellContractor(true);
    setContractorId();
    setSelectedContractorData();
    setSelectedContractorDataShow(false);
  };

  const handleShowElectricalSelectedFormClose = () => {
    setShowElecrtricalTable(true);
    setElectricalId();
    setSelectedElectricalData();
    setSelectedElectricalDataShow(false);
  };

  const handleShowPlumberSelectedFormClose = () => {
    setShowPlumbingTable(true);
    setPlumberId();
    setSelectedPlumberData();
    setSelectedPlumberDataShow(false);
  };

  const handleShowHvacSelectedFormClose = () => {
    setShowHVACTable(true);
    setHvacId();
    setSelectedHvacData();
    setSelectedHvacDataShow(false);
  };

  const handleSelectedPermitRequest = (value) => {
    setPermitIds(value.map((item) => item.id));
    let trigger = false;
    value.map((item) => {
      if (item.name === "Other") {
        trigger = true;
      }
    });
    if (trigger) {
      setShowPermitOtherField(true);
    } else setShowPermitOtherField(false);
  };

  // const onlyDate = (dateStr) => {
  //   const [yyyy, mm, dd] = dateStr.split(/[/:\-T]/);
  //   const newDT = `${dd}/${mm}/${yyyy}`;
  //   return newDT;
  // };

  // const getFilePreviewName = (name) => {
  //   let first15Characters = name.slice(0, 15);
  //   if (name.length > 15) {
  //     first15Characters = `${first15Characters}...`;
  //   }
  //   return first15Characters;
  // };

  // Get file extension
  function checkFileExt(filename) {
    const ext = filename.split(".");
    return ext[ext.length - 1];
  }

  const onDropHandler = (ev) => {
    ev.preventDefault();
    let file = "";
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items]
        ?.find((item) => item.kind === "file")
        ?.getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    handleSelectFile(file);
    ev.target.value = "";
    // setSelectedMedia([...selectedMedia, file]);
  };

  const handleSelectFile = async (files) => {
    if (files) {
      let tempPreviewImages = [...previewImages];
      let tempSelectedMedia = [...selectedMedia];
      const selectedFile = files;

      let fileExtension = checkFileExt(files.name);
      fileExtension = fileExtension?.toLowerCase();

      if (
        fileExtension == "png" ||
        fileExtension == "jpg" ||
        fileExtension == "jpeg" ||
        fileExtension == "webp"
      ) {
        const compressedFile = await imageCompression(
          selectedFile,
          compressionOption
        );
        const imageUrl = URL.createObjectURL(compressedFile);

        tempPreviewImages.push({
          type: "Image",
          url: imageUrl,
          name: selectedFile?.name,
        });
        setPreviewImages(tempPreviewImages);

        tempSelectedMedia.push({
          file: compressedFile,
          fileId: previewImages?.length ? previewImages?.length + 1 : 0,
          type: "Image",
        });
        setSelectedMedia(tempSelectedMedia);
      } else if (
        fileExtension == "docx" ||
        fileExtension == "doc" ||
        fileExtension == "txt"
      ) {
        tempPreviewImages.push({
          type: "Docs",
          url: DocsPreview,
          name: selectedFile?.name,
        });
        setPreviewImages(tempPreviewImages);
        tempSelectedMedia.push({
          file: selectedFile,
          fileId: previewImages?.length ? previewImages?.length + 1 : 0,
          type: "Docs",
        });
        setSelectedMedia(tempSelectedMedia);
      } else if (
        fileExtension == "csv" ||
        fileExtension == "xls" ||
        fileExtension == "xlsx"
      ) {
        tempPreviewImages.push({
          type: "Excel",
          url: ExcelPreview,
          name: selectedFile?.name,
        });
        setPreviewImages(tempPreviewImages);
        tempSelectedMedia.push({
          file: selectedFile,
          fileId: previewImages?.length ? previewImages?.length + 1 : 0,
          type: "Excel",
        });
        setSelectedMedia(tempSelectedMedia);
      } else if (fileExtension == "pdf") {
        tempPreviewImages.push({
          type: "Pdf",
          url: PdfPreview,
          name: selectedFile?.name,
        });
        setPreviewImages(tempPreviewImages);
        tempSelectedMedia.push({
          file: selectedFile,
          fileId: previewImages?.length ? previewImages?.length + 1 : 0,
          type: "Pdf",
        });
        setSelectedMedia(tempSelectedMedia);
      } else if (fileExtension == "pptx" || fileExtension == "ppt") {
        tempPreviewImages.push({
          type: "Powerpoint",
          url: PowerPointPreview,
          name: selectedFile?.name,
        });
        setPreviewImages(tempPreviewImages);
        tempSelectedMedia.push({
          file: selectedFile,
          fileId: previewImages?.length ? previewImages?.length + 1 : 0,
          type: "Powerpoint",
        });
        setSelectedMedia(tempSelectedMedia);
      } else {
        fireToasterContext.fireToasterHandler(false, "Invalid File");
      }
    }
  };

  const {
    projectAddress,
    jobDescription,
    totalSquareFeet,
    constructionCost,
    electricalCost,
    plumbingCost,
    hvacCost,
    ownerName,
    ownerEmail,
    ownerPhoneNumber,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerZip,
    dwellingContractorByOwner,
    electricalContractorByOwner,
    plumbingContractorByOwner,
    hvacContractorByOwner,
    applicantName,
    applicantEmail,
    permitRequestedOtherDescription,
  } = applicationFormObject;

  const {
    name,
    email,
    companyName,
    phoneNumber,
    licenseNumber,
    licenseExpirationDate,
    address,
    city,
    zip,
    dwellingContractorQualifierName,
    dwellingContractorQualifierAddress,
    dwellingContractorQualifierLicenseNumber,
    dwellingContractorQualifierLicenseExpirationDate,
    dwellingContractorQualifierCity,
    dwellingContractorQualifierZip,
    dwellingContractorQualifierEmail,
    dwellingContractorQualifierPhoneNumber,
  } = contractorObject;

  const { generalName, generalEmail, generalCompanyName, generalPhoneNumber } =
    generalContractorObject;

  const {
    // electricalName,
    electricalEmail,
    electricalCompanyName,
    electricalPhoneNumber,
    electricalLicenseNumber,
    electricalLicenseExpirationDate,
    electricalAddress,
    electricalCity,
    electricalZip,
    masterElectricianName,
    masterElectricianEmail,
    masterElectricianPhoneNumber,
    masterElectricianLicenseNumber,
    masterElectricianLicenseExpirationDate,
    masterElectricianAddress,
    masterElectricianCity,
    masterElectricianZip,
  } = electricalObject;

  const {
    // plumberName,
    plumberEmail,
    plumberCompanyName,
    plumberPhoneNumber,
    plumberLicenseNumber,
    plumberLicenseExpirationDate,
    plumberAddress,
    plumberCity,
    plumberZip,
  } = plumberObject;

  const {
    // heatName,
    heatEmail,
    heatCompanyName,
    heatPhoneNumber,
    heatLicenseNumber,
    heatLicenseExpirationDate,
    heatAddress,
    heatCity,
    heatZip,
  } = heatObject;

  const handleDwellContractorApiResponse = (data) => {
    setContractorLoader(false);
    if (data.status) {
      setContractorId(data.data.id);
      const newCreatedDwellingContractor = data?.data;
      let tempDwellingContractors = [...dwellContractors];
      tempDwellingContractors.push(newCreatedDwellingContractor);
      setDwellContractors(tempDwellingContractors);
      let tempContractorArray = [...contractorListArray];
      tempContractorArray.push(data?.data);
      setContractorListArray(tempContractorArray);
      setShowDwellContractorForm(false);
      setSelectedContractorData(data?.data);
      setSelectedContractorDataShow(true);
      setContractorObject({});
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      let errorMessage = data.message;

      fireToasterContext.fireToasterHandler(
        false,
        errorMessage ? errorMessage : "Something went wrong"
      );
    }
  };

  const handleGeneralContractorApiResponse = (data) => {
    setGeneralContractorLoader(false);
    if (data.status) {
      setGeneralContractorId(data.data.id);
      const newCreatedGeneralContractor = data?.data;
      let tempGeneralContractors = [...generalContractors];
      tempGeneralContractors.push(newCreatedGeneralContractor);
      setGeneralContractors(tempGeneralContractors);
      let tempContractorArray = [...contractorListArray];
      tempContractorArray.push(data?.data);
      setContractorListArray(tempContractorArray);
      setShowGeneralContractorForm(false);
      setSelectedGeneralDataShow(true);
      setSelectedGeneralData(data?.data);
      setGeneralContractorObject({});
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      let errorMessage = data.message;
      fireToasterContext.fireToasterHandler(
        false,
        errorMessage ? errorMessage : "Something went wrong"
      );
    }
  };

  const handleElectricalContractorApiResponse = (data) => {
    setContractorLoader(false);
    if (data.status) {
      setElectricalId(data.data.id);
      const newCreatedElectricalContractor = data.data;
      let tempElectricalContractors = [...electricalContractors];
      tempElectricalContractors.push(newCreatedElectricalContractor);
      setElectricalContractors(tempElectricalContractors);
      let tempContractorArray = [...contractorListArray];
      tempContractorArray.push(data?.data);
      setContractorListArray(tempContractorArray);
      setSelectedElectricalDataShow(true);
      setSelectedElectricalData(data?.data);
      setShowElecrtricalForm(false);
      setElectricalObject({});
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      let errorMessage = data.message;

      fireToasterContext.fireToasterHandler(
        false,
        errorMessage ? errorMessage : "Something went wrong"
      );
    }
  };

  const handlePlumberContractorApiResponse = (data) => {
    setContractorLoader(false);
    if (data.status) {
      setPlumberId(data?.data?.id);
      const newCreatedPlumbingContractor = data?.data;
      let tempPlumbingContractors = [...plumberContractors];
      tempPlumbingContractors.push(newCreatedPlumbingContractor);
      let tempContractorArray = [...contractorListArray];
      tempContractorArray.push(data?.data);
      setContractorListArray(tempContractorArray);
      setPlumberContractors(tempPlumbingContractors);
      setSelectedPlumberDataShow(true);
      setSelectedPlumberData(data?.data);
      setShowPlumbingForm(false);
      setPlumberObject({});
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      let errorMessage = data.message;

      fireToasterContext.fireToasterHandler(
        false,
        errorMessage ? errorMessage : "Something went wrong"
      );
    }
  };

  const handleHVACContractorApiResponse = (data) => {
    setContractorLoader(false);
    if (data.status) {
      setHvacId(data?.data?.id);
      const newCreatedHvacContractor = {
        id: data?.data?.id,
        companyName: data?.data?.companyName,
        licenseNumber: data?.data?.licenseNumber,
        phoneNumber: data?.data?.phoneNumber,
      };
      let tempHvacContractors = [...hvacContractors];
      tempHvacContractors.push(newCreatedHvacContractor);
      setHVACContractors(tempHvacContractors);
      let tempContractorArray = [...contractorListArray];
      tempContractorArray.push(data?.data);
      setContractorListArray(tempContractorArray);
      setSelectedHvacDataShow(true);
      setSelectedHvacData(data?.data);
      setShowHVACForm(false);
      setHeatObject({});
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      let errorMessage = data.message;

      fireToasterContext.fireToasterHandler(
        false,
        errorMessage ? errorMessage : "Something went wrong"
      );
    }
  };

  const handleDwellContractorSubmit = () => {
    setSaveContractorPressed(true);
    if (contractorObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(contractorObject.email)) {
        setDwellEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setDwellEmailError(false);
      }
    }
    if (contractorObject.dwellingContractorQualifierEmail) {
      var patternQualifierEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !patternQualifierEmail.test(
          contractorObject.dwellingContractorQualifierEmail
        )
      ) {
        setDwellQualifierEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setDwellQualifierEmailError(false);
      }
    }
    if (buildingType < 3) {
      if (
        !contractorObject.name ||
        !contractorObject.companyName ||
        !contractorObject.email ||
        !contractorObject.phoneNumber ||
        !contractorObject.licenseNumber ||
        !contractorObject.licenseExpirationDate ||
        !contractorObject.address ||
        !contractorObject.city ||
        !contractorObject.zip ||
        !contractorObject.dwellingContractorQualifierName ||
        !contractorObject.dwellingContractorQualifierAddress ||
        !contractorObject.dwellingContractorQualifierLicenseNumber ||
        !contractorObject.dwellingContractorQualifierLicenseExpirationDate ||
        !contractorObject.dwellingContractorQualifierCity ||
        !contractorObject.dwellingContractorQualifierZip ||
        !contractorObject.dwellingContractorQualifierPhoneNumber ||
        !contractorObject.dwellingContractorQualifierEmail
      ) {
        fireToasterContext.fireToasterHandler(
          false,
          "Please Fill All Required Fields For Dwelling Contractor"
        );
        return;
      }
    }

    const finalDwellContractorObject = {
      type: "Construction",
      temporary: true,
      name: contractorObject.name,
      email: contractorObject.email,
      companyName: contractorObject.companyName,
      phoneNumber: contractorObject.phoneNumber,
      licenseNumber: contractorObject.licenseNumber,
      licenseExpirationDate: contractorObject.licenseExpirationDate,
      address: contractorObject.address,
      city: contractorObject.city,
      zip: contractorObject.zip,
      dwellingContractorQualifierName:
        contractorObject.dwellingContractorQualifierName,
      dwellingContractorQualifierAddress:
        contractorObject.dwellingContractorQualifierAddress,
      dwellingContractorQualifierLicenseNumber:
        contractorObject.dwellingContractorQualifierLicenseNumber,
      dwellingContractorQualifierLicenseExpirationDate:
        contractorObject.dwellingContractorQualifierLicenseExpirationDate,
      dwellingContractorQualifierCity:
        contractorObject.dwellingContractorQualifierCity,
      dwellingContractorQualifierZip:
        contractorObject.dwellingContractorQualifierZip,
      dwellingContractorQualifierEmail:
        contractorObject.dwellingContractorQualifierEmail,
      dwellingContractorQualifierPhoneNumber:
        contractorObject.dwellingContractorQualifierPhoneNumber,
    };
    setContractorLoader(true);
    dispatch(
      createContractor(
        finalDwellContractorObject,
        handleDwellContractorApiResponse
      )
    );
  };

  const handleGeneralContractorSubmit = () => {
    setSaveGeneralContractorPressed(true);
    if (generalContractorObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(generalContractorObject.email)) {
        setGeneralEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setGeneralEmailError(false);
      }
    }

    if (buildingType > 2) {
      if (
        !generalContractorObject.name ||
        !generalContractorObject.companyName ||
        !generalContractorObject.email ||
        !generalContractorObject.phoneNumber
      ) {
        fireToasterContext.fireToasterHandler(
          false,
          "Please Fill All Required Fields For General Contractor"
        );
        return;
      }
    }

    const finalGeneralContractorObject = {
      type: "General",
      temporary: true,
      name: generalContractorObject.name,
      email: generalContractorObject.email,
      companyName: generalContractorObject.companyName,
      phoneNumber: generalContractorObject.phoneNumber,
    };
    setGeneralContractorLoader(true);
    dispatch(
      createContractor(
        finalGeneralContractorObject,
        handleGeneralContractorApiResponse
      )
    );
  };

  const handleElectricContractorSubmit = () => {
    setSaveElectricalPressed(true);
    if (electricalObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(electricalObject.email)) {
        setElectricalEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setElectricalEmailError(false);
      }
    }
    if (electricalObject.masterElectricianEmail) {
      var patternMasterEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!patternMasterEmail.test(electricalObject.masterElectricianEmail)) {
        setElectrcialMasterEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setElectrcialMasterEmailError(false);
      }
    }
    if (
      electricalObject.phoneNumber &&
      electricalObject.email &&
      electricalObject.licenseNumber &&
      electricalObject.licenseExpirationDate &&
      electricalObject.address &&
      electricalObject.city &&
      electricalObject.zip &&
      electricalObject.masterElectricianEmail &&
      electricalObject.masterElectricianLicenseNumber &&
      electricalObject.masterElectricianLicenseExpirationDate &&
      electricalObject.masterElectricianName &&
      electricalObject.masterElectricianPhoneNumber &&
      electricalObject.masterElectricianAddress &&
      electricalObject.masterElectricianCity &&
      electricalObject.masterElectricianZip
    ) {
      const finalElectricalContractorObject = {
        type: "Electrical",
        temporary: true,
        name: null,
        email: electricalObject.email,
        companyName: electricalObject.companyName,
        phoneNumber: electricalObject.phoneNumber,
        licenseNumber: electricalObject.licenseNumber,
        licenseExpirationDate: electricalObject.licenseExpirationDate,
        address: electricalObject.address,
        city: electricalObject.city,
        zip: electricalObject.zip,
        masterElectricianName: electricalObject.masterElectricianName,
        masterElectricianEmail: electricalObject.masterElectricianEmail,
        masterElectricianPhoneNumber:
          electricalObject.masterElectricianPhoneNumber,
        masterElectricianLicenseNumber:
          electricalObject.masterElectricianLicenseNumber,
        masterElectricianLicenseExpirationDate:
          electricalObject.masterElectricianLicenseExpirationDate,
        masterElectricianAddress: electricalObject.masterElectricianAddress,
        masterElectricianCity: electricalObject.masterElectricianCity,
        masterElectricianZip: electricalObject.masterElectricianZip,
      };
      setContractorLoader(true);
      dispatch(
        createContractor(
          finalElectricalContractorObject,
          handleElectricalContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Electrical Contractor"
      );
    }
  };

  const handlePlumberContractorSubmit = () => {
    setSavePlumberPressed(true);
    if (plumberObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(plumberObject.email)) {
        setPlumberEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setPlumberEmailError(false);
      }
    }
    if (
      plumberObject.companyName &&
      plumberObject.email &&
      plumberObject.phoneNumber &&
      plumberObject.licenseNumber &&
      plumberObject.licenseExpirationDate &&
      plumberObject.address &&
      plumberObject.city &&
      plumberObject.zip
    ) {
      const finalPlumberContractorObject = {
        type: "Plumbing",
        temporary: true,
        name: null,
        email: plumberObject.email,
        companyName: plumberObject.companyName,
        phoneNumber: plumberObject.phoneNumber,
        licenseNumber: plumberObject.licenseNumber,
        licenseExpirationDate: plumberObject.licenseExpirationDate,
        address: plumberObject.address,
        city: plumberObject.city,
        zip: plumberObject.zip,
      };
      setContractorLoader(true);
      dispatch(
        createContractor(
          finalPlumberContractorObject,
          handlePlumberContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Plumbing Contractor"
      );
    }
  };

  const handleHVACContractorSubmit = () => {
    setSaveHeatingPressed(true);
    if (heatObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(heatObject.email)) {
        setHvacEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setHvacEmailError(false);
      }
    }
    if (
      heatObject.companyName &&
      heatObject.phoneNumber &&
      heatObject.licenseNumber &&
      heatObject.licenseExpirationDate &&
      heatObject.email
    ) {
      const finalHvacContractorObject = {
        type: "HVAC",
        temporary: true,
        name: null,
        email: heatObject.email,
        companyName: heatObject.companyName,
        phoneNumber: heatObject.phoneNumber,
        licenseNumber: heatObject.licenseNumber,
        licenseExpirationDate: heatObject.licenseExpirationDate,
        address: heatObject.address,
        city: heatObject.city,
        zip: heatObject.zip,
      };
      setContractorLoader(true);
      dispatch(
        createContractor(
          finalHvacContractorObject,
          handleHVACContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Hvac Contractor"
      );
    }
  };

  const handleUploadMediaResponse = (data) => {
    setLoader(false);
    if (data.status) {
      Swal.fire({
        icon: "success",
        title: "",
        text: "Your permit application has been submitted successfully!",
        confirmButtonText: "Ok",
        confirmButtonColor: "#d52027",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.reload();
          window.location.href = "https://wipermit.com/";
          setLoader(false);
          setSubmitButtonDisabled(false);
          setIsCautionary(true);
        }
      });
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleUploadFilesToSpaces = async (
    applicationId,
    applicationMediaArr
  ) => {
    try {
      let allMediaArr = previewImages.filter((el) => {
        return el.url
          .toString()
          .startsWith(process.env.REACT_APP_SPACES_ENDPOINT);
      });

      // Loop through each selected media file
      for (let i = 0; i < applicationMediaArr.length; i++) {
        const arrayBuffer = await applicationMediaArr[i].file.arrayBuffer();
        const params = {
          Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
          Key: `${process.env.REACT_APP_ENVIRONMENT}/${applicationId}/${
            applicationMediaArr[i].file.name.split(" ").join("-").split(".")[0]
          }-${Date.now()}.${checkFileExt(applicationMediaArr[i].file.name)}`,
          Body: new Uint8Array(arrayBuffer),
          ACL: "public-read",
        };
        // Upload the media file to the S3 bucket
        await s3Client.send(new PutObjectCommand(params));
        // Add the uploaded media URL to the array
        allMediaArr.push({
          url: `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`,
          type: applicationMediaArr[i].type,
        });
      }

      // Dispatch the uploadMedia action with the necessary parameters
      dispatch(
        uploadApplicationMedia(
          applicationId,
          allMediaArr,
          handleUploadMediaResponse
        )
      );
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleCreateApplicationResponse = (data) => {
    if (data.status) {
      handleUploadFilesToSpaces(data.data.id, selectedMedia);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleSubmit = () => {
    setSubmitPressed(true);
    setSubmitButtonDisabled(true);
    if (applicationFormObject.ownerEmail) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(applicationFormObject.ownerEmail)) {
        setOwnerEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        setSubmitButtonDisabled(false);
        return;
      } else {
        setOwnerEmailError(false);
      }
    }
    if (applicationFormObject.applicantEmail) {
      var patternApplicantEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!patternApplicantEmail.test(applicationFormObject.applicantEmail)) {
        setApplicantEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        setSubmitButtonDisabled(false);
        return;
      } else {
        setApplicantEmailError(false);
      }
    }
    if (
      showPermitOtherField &&
      !applicationFormObject.permitRequestedOtherDescription
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    if (projectType < 3 && !applicationFormObject.totalSquareFeet) {
      fireToasterContext.fireToasterHandler(
        false,
        "Total square feet is required"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    if (
      (permitIds?.includes(1) ||
        permitIds?.includes(6) ||
        permitIds?.includes(7) ||
        permitIds?.includes(8) ||
        permitIds?.includes(9) ||
        permitIds?.includes(10)) &&
      !applicationFormObject.constructionCost
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Construction Cost is required"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    if (permitIds?.includes(2) && !applicationFormObject.hvacCost) {
      fireToasterContext.fireToasterHandler(false, "Hvac Cost is required");
      setSubmitButtonDisabled(false);
      return;
    }

    if (permitIds?.includes(3) && !applicationFormObject.electricalCost) {
      fireToasterContext.fireToasterHandler(
        false,
        "Electrical Cost is required"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    if (permitIds?.includes(4) && !applicationFormObject.plumbingCost) {
      fireToasterContext.fireToasterHandler(false, "Plumbing Cost is required");
      setSubmitButtonDisabled(false);
      return;
    }

    // if building type and project type is correct and general contractor is not selected or created
    if (
      permitIds?.includes(1) &&
      ((buildingType > 2 && projectType) ||
        (buildingType < 3 && projectType > 4)) &&
      !generalContractorId &&
      !applicationFormObject.dwellingContractorByOwner
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "General contractor is required"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    // this condition for building type ad project type is true but contractor not selected or added to application
    if (
      permitIds?.includes(1) &&
      buildingType < 3 &&
      projectType < 5 &&
      !contractorId &&
      !applicationFormObject.dwellingContractorByOwner
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Dwelling contractor is required"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    if (
      permitIds?.includes(3) &&
      !electricalId &&
      !applicationFormObject.electricalContractorByOwner
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Electrical contractor is required"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    if (
      permitIds?.includes(4) &&
      !plumberId &&
      !applicationFormObject.plumbingContractorByOwner
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Plumber contractor is required"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    if (
      permitIds?.includes(2) &&
      !hvacId &&
      !applicationFormObject.hvacContractorByOwner
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Hvac contractor is required"
      );
      setSubmitButtonDisabled(false);
      return;
    }

    const createdAtDate = new Date();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    if (
      applicationFormObject.projectAddress &&
      muncipalityId &&
      projectType &&
      buildingType &&
      applicationFormObject.jobDescription &&
      permitIds.length > 0 &&
      applicationFormObject.ownerName &&
      applicationFormObject.ownerEmail &&
      applicationFormObject.ownerPhoneNumber &&
      applicationFormObject.ownerAddress &&
      applicationFormObject.ownerZip &&
      personSubmittingApplicationName &&
      applicationFormObject.applicantName &&
      applicationFormObject.applicantEmail
    ) {
      const finalObject = {
        muncipalityId: muncipalityId,
        projectAddress: applicationFormObject.projectAddress,
        projectType: projectType,
        buildingType: buildingType,
        jobDescription: applicationFormObject.jobDescription,
        totalSquareFeet: applicationFormObject.totalSquareFeet,
        constructionCost:
          permitIds?.includes(1) ||
          permitIds?.includes(6) ||
          permitIds?.includes(7) ||
          permitIds?.includes(8) ||
          permitIds?.includes(9) ||
          permitIds?.includes(10)
            ? applicationFormObject.constructionCost
            : 0,
        electricalCost: permitIds?.includes(3)
          ? applicationFormObject.electricalCost
          : 0,
        plumbingCost: permitIds?.includes(4)
          ? applicationFormObject.plumbingCost
          : 0,
        hvacCost: permitIds?.includes(2) ? applicationFormObject.hvacCost : 0,
        ownerName: applicationFormObject.ownerName,
        ownerEmail: applicationFormObject.ownerEmail,
        ownerPhoneNumber: applicationFormObject.ownerPhoneNumber,
        ownerAddress: applicationFormObject.ownerAddress,
        ownerCity: applicationFormObject.ownerCity,
        ownerState: applicationFormObject.ownerState,
        ownerZip: applicationFormObject.ownerZip,
        permitRequested: permitIds,
        permitRequestedOtherDescription: showPermitOtherField
          ? applicationFormObject.permitRequestedOtherDescription
          : "",
        dwellingContractorId:
          buildingType < 3 ? contractorId : generalContractorId,
        dwellingContractorByOwner:
          applicationFormObject.dwellingContractorByOwner,
        electricalContractorId: electricalId,
        electricalContractorByOwner:
          applicationFormObject.electricalContractorByOwner,
        plumbingContractorId: plumberId,
        plumbingContractorByOwner:
          applicationFormObject.plumbingContractorByOwner,
        hvacContractorId: hvacId,
        hvacContractorByOwner: applicationFormObject.hvacContractorByOwner,
        personSubmittingApplication: personSubmittingApplicationName,
        status: "New Application",
        applicantName: applicationFormObject.applicantName,
        applicantEmail: applicationFormObject.applicantEmail,
        createdAt:
          createdAtDate.toLocaleDateString("en-US", options) +
          " " +
          createdAtDate.toLocaleTimeString("en-US"),
      };

      setLoader(true);

      dispatch(createApplication(finalObject, handleCreateApplicationResponse));
    } else {
      setSubmitButtonDisabled(false);
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields"
      );
    }
  };

  return (
    <div className="main_div_application_form">
      <Container>
        <Box component="div" className="application_form_main_box">
          {/* title */}
          <Typography variant="h4" sx={applicationTitleTypography}>
            Application Form
          </Typography>

          {/* muncipality dropdown */}
          <Autocomplete
            sx={textfieldStyle}
            name="muncipalityId"
            id="muncipalityId"
            options={applicationFormMunicipalityList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Municipality"
                error={!muncipalityId && submitPressed}
                helperText={
                  !muncipalityId && submitPressed
                    ? "Municipality is required"
                    : ""
                }
              />
            )}
            onChange={(event, value) => {
              setMuncipalityId(value?.id);
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
          <Typography variant="h4" sx={applicationWisconsinTypography}>
            Wisconsin Statutes 101.63,73
          </Typography>

          <Typography variant="h4" sx={applicationWisconsinTypographySubtitle}>
            The information you provide may be used by government agency
            programs. [(Privacy Law,S. 15.04(I)(m))]
            <br />
            <br />
            Homeowners may do the work of Construction/Electrical/Plumbing and
            HVAC if they own and occupy the home. <br />
            <br />
            Emails will only be used for communication regarding the permit
            application
          </Typography>

          {/* project information box */}
          <Box component="div" sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
            >
              Project Information
            </Typography>
            <Grid container spacing={2}>
              {/* project address */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  error={!applicationFormObject.projectAddress && submitPressed}
                  helperText={
                    !applicationFormObject.projectAddress && submitPressed
                      ? "Project address is required"
                      : ""
                  }
                  id="project-address"
                  placeholder="Project Address"
                  name="projectAddress"
                  value={projectAddress}
                  sx={[applicationFormTitleField, { mt: 1 }]}
                  onChange={handleChange}
                />
              </Grid>

              {/* project type dropdown */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  sx={textfieldDropdownStyle}
                  id="project-type"
                  options={projectTypeList}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Project Type"
                      error={!projectType && submitPressed}
                      helperText={
                        !projectType && submitPressed
                          ? "Project type is required"
                          : ""
                      }
                    />
                  )}
                  onChange={(event, value) => {
                    setProjectType(value?.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                />
              </Grid>

              {/* building type dropdown */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  sx={textfieldDropdownStyle}
                  id="building-type"
                  options={buildingTypeList}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Building Type"
                      error={!buildingType && submitPressed}
                      helperText={
                        !buildingType && submitPressed
                          ? "Building type is required"
                          : ""
                      }
                    />
                  )}
                  onChange={(event, value) => {
                    setBuildingType(value?.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                />
              </Grid>
            </Grid>
          </Box>

          {/* job description box */}
          <Box component="div" sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
            >
              Job Description
            </Typography>
            <Grid container spacing={2}>
              {/* job description */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  error={!applicationFormObject.jobDescription && submitPressed}
                  helperText={
                    !applicationFormObject.jobDescription && submitPressed
                      ? "Job description is required"
                      : ""
                  }
                  rows={3}
                  multiline
                  id="job-description"
                  name="jobDescription"
                  value={jobDescription}
                  placeholder="Job Description"
                  sx={[applicationFormJobDescriptionField, { my: 1 }]}
                  onChange={handleChange}
                />
              </Grid>

              {/* Total square feet for new buildings and additions */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  error={
                    projectType < 3 &&
                    !applicationFormObject.totalSquareFeet &&
                    submitPressed
                  }
                  helperText={
                    projectType < 3 &&
                    !applicationFormObject.totalSquareFeet &&
                    submitPressed
                      ? "Total square feet is required"
                      : ""
                  }
                  id="square-feet-for-new-building"
                  placeholder="Total square feet for new buildings and additions"
                  name="totalSquareFeet"
                  type="number"
                  value={totalSquareFeet ? totalSquareFeet : ""}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                  inputProps={{ min: 1 }}
                />
              </Grid>

              {/* Total square feet for new buildings and additions */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {/* permit request dropdown */}
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  sx={textfieldDropdownStyle}
                  name="permitRequested"
                  id="permit-requested"
                  options={permitRequested}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Permit Requested"
                      error={permitIds.length < 1 && submitPressed}
                      helperText={
                        permitIds.length < 1 && submitPressed
                          ? "Permit requested are required"
                          : ""
                      }
                    />
                  )}
                  onChange={(event, value) => {
                    handleSelectedPermitRequest(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Grid>

              {showPermitOtherField && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      showPermitOtherField &&
                      !applicationFormObject.permitRequestedOtherDescription &&
                      submitPressed
                    }
                    helperText={
                      showPermitOtherField &&
                      !applicationFormObject.permitRequestedOtherDescription &&
                      submitPressed
                        ? "Permit other description is required"
                        : ""
                    }
                    id="permit-requested-other-description-field"
                    placeholder="Permit Requested Other Description"
                    value={
                      permitRequestedOtherDescription
                        ? permitRequestedOtherDescription
                        : ""
                    }
                    name="permitRequestedOtherDescription"
                    sx={applicationFormTitleField}
                    onChange={handleChange}
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          {/* cost box */}
          <Box component="div" sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
            >
              Cost
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#000000",
                fontWeight: 500,
                fontSize: "15px",
                marginTop: "5px",
              }}
            >
              Cost = Material + Labor
            </Typography>
            <Grid container spacing={2}>
              {/* Cunstruction cost */}
              {(permitIds?.includes(1) ||
                permitIds?.includes(6) ||
                permitIds?.includes(7) ||
                permitIds?.includes(8) ||
                permitIds?.includes(9) ||
                permitIds?.includes(10)) && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      submitPressed &&
                      permitIds.includes(1) &&
                      !applicationFormObject.constructionCost
                    }
                    helperText={
                      submitPressed &&
                      permitIds.includes(1) &&
                      !applicationFormObject.constructionCost
                        ? "Construction Cost is required"
                        : ""
                    }
                    id="construction-cost"
                    placeholder="Construction Cost"
                    name="constructionCost"
                    value={constructionCost ? constructionCost : ""}
                    type="number"
                    sx={applicationFormTitleField}
                    onChange={handleChange}
                    inputProps={{
                      min: 1,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {/* Electrical cost */}
              {permitIds.includes(3) && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      submitPressed &&
                      permitIds.includes(3) &&
                      !applicationFormObject.electricalCost
                    }
                    helperText={
                      submitPressed &&
                      permitIds.includes(3) &&
                      !applicationFormObject.electricalCost
                        ? "Electrical Cost is required"
                        : ""
                    }
                    id="electrical-cost"
                    placeholder="Electrical Cost"
                    name="electricalCost"
                    value={electricalCost ? electricalCost : ""}
                    type="number"
                    sx={applicationFormTitleField}
                    onChange={handleChange}
                    inputProps={{ min: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {/* Plumbing cost */}
              {permitIds.includes(4) && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      submitPressed &&
                      permitIds.includes(4) &&
                      !applicationFormObject.plumbingCost
                    }
                    helperText={
                      submitPressed &&
                      permitIds.includes(4) &&
                      !applicationFormObject.plumbingCost
                        ? "Plumbing Cost is required"
                        : ""
                    }
                    id="plumbing-cost"
                    placeholder="Plumbing Cost"
                    name="plumbingCost"
                    value={plumbingCost ? plumbingCost : ""}
                    type="number"
                    sx={applicationFormTitleField}
                    onChange={handleChange}
                    inputProps={{ min: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {/* HVAC cost */}
              {permitIds.includes(2) && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      submitPressed &&
                      permitIds.includes(2) &&
                      !applicationFormObject.hvacCost
                    }
                    helperText={
                      submitPressed &&
                      permitIds.includes(2) &&
                      !applicationFormObject.hvacCost
                        ? "Hvac Cost is required"
                        : ""
                    }
                    id="hvac-cost"
                    placeholder="HVAC Cost"
                    name="hvacCost"
                    value={hvacCost ? hvacCost : ""}
                    type="number"
                    sx={applicationFormTitleField}
                    onChange={handleChange}
                    inputProps={{ min: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          {/* owner information box */}
          <Box component="div" sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
            >
              Owner Information
            </Typography>
            <Grid container spacing={2}>
              {/* owner name */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  error={!applicationFormObject.ownerName && submitPressed}
                  helperText={
                    !applicationFormObject.ownerName && submitPressed
                      ? "Owner name is required"
                      : ""
                  }
                  id="owner-name"
                  name="ownerName"
                  placeholder="Name"
                  value={ownerName ? ownerName : ""}
                  sx={[applicationFormTitleField, { my: 1 }]}
                  onChange={handleChange}
                />
              </Grid>

              {/* Owner email */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="owner-email"
                  placeholder="Email"
                  name="ownerEmail"
                  value={ownerEmail ? ownerEmail : ""}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                  type="email"
                  error={
                    !!ownerEmailError ||
                    (!applicationFormObject?.ownerEmail && submitPressed)
                  }
                  helperText={
                    ownerEmailError ||
                    (!applicationFormObject?.ownerEmail && submitPressed)
                      ? "Please enter a valid email address"
                      : ""
                  }
                />
              </Grid>

              {/* owner phonenumber */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !applicationFormObject.ownerPhoneNumber && submitPressed
                  }
                  helperText={
                    !applicationFormObject.ownerPhoneNumber && submitPressed
                      ? "Owner phone number is required"
                      : ""
                  }
                  id="owner-phonenumber"
                  placeholder="Phone Number"
                  name="ownerPhoneNumber"
                  value={ownerPhoneNumber ? ownerPhoneNumber : ""}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                />
              </Grid>

              {/* owner address */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={!applicationFormObject.ownerAddress && submitPressed}
                  helperText={
                    !applicationFormObject.ownerAddress && submitPressed
                      ? "Owner address is required"
                      : ""
                  }
                  id="owner-address"
                  name="ownerAddress"
                  placeholder="Address"
                  value={ownerAddress}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                />
              </Grid>

              {/* owner city */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="owner-city"
                  placeholder="City"
                  name="ownerCity"
                  value={ownerCity ? ownerCity : ""}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                  error={!applicationFormObject.ownerCity && submitPressed}
                  helperText={
                    !applicationFormObject.ownerCity && submitPressed
                      ? "Owner city is required"
                      : ""
                  }
                />
              </Grid>

              {/* owner state */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="owner-state"
                  placeholder="State"
                  name="ownerState"
                  value={ownerState ? ownerState : ""}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                />
              </Grid>

              {/* owner zipcode */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="owner-zipcode"
                  type="number"
                  placeholder="Zip code"
                  name="ownerZip"
                  value={ownerZip ? ownerZip : ""}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                  error={!applicationFormObject.ownerZip && submitPressed}
                  helperText={
                    !applicationFormObject.ownerZip && submitPressed
                      ? "Owner zip is required"
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Box>

          {(permitIds?.includes(1) ||
            permitIds?.includes(6) ||
            permitIds?.includes(7) ||
            permitIds?.includes(8) ||
            permitIds?.includes(9) ||
            permitIds?.includes(10)) &&
            ((buildingType > 2 && projectType) ||
              (buildingType < 3 && projectType > 4)) &&
            (selectedGeneralDataShow ? (
              <ContractorDetail
                type="General"
                data={selectedGeneralData}
                handleClose={handleShowGeneralSelectedFormClose}
              />
            ) : (
              <>
                <Box component="div" sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#414042",
                      fontWeight: 600,
                      fontSize: "15px",
                    }}
                  >
                    Construction
                  </Typography>

                  <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="dwellingContractorByOwner"
                          checked={
                            dwellingContractorByOwner
                              ? dwellingContractorByOwner
                              : false
                          }
                          sx={{ fontSize: 60 }}
                          onChange={handleChangeCheckBox}
                        />
                      }
                      label="By Owner"
                    />
                  </FormGroup>

                  {applicationFormObject.dwellingContractorByOwner !== true && (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#414042",
                          fontWeight: 300,
                          fontSize: "15px",
                          marginTop: "5px",
                        }}
                      >
                        Search General Contractor if the contractor is not
                        listed click the add button to enter contractors
                        information
                      </Typography>
                      <Grid container spacing={2} sx={{ mt: "2px" }}>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                          <TextField
                            id="search-dweeling-contractor"
                            placeholder="Enter name, company name, email or license number..."
                            name="searchDwellContractor"
                            value={
                              generalContractorSearchString
                                ? generalContractorSearchString
                                : ""
                            }
                            sx={applicationFormTitleField}
                            onChange={(event) => {
                              handleContractorSearchString(
                                event.target.value,
                                "generalContractor"
                              );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Button
                            disabled={!generalContractorSearchString}
                            sx={applicationFormSearchButton}
                            onClick={() =>
                              handleFilterContractorType("generalContractor")
                            }
                          >
                            <AddBoxOutlined /> Search
                          </Button>
                        </Grid>
                      </Grid>

                      {showGeneralContractor && (
                        <>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#414042",
                              fontWeight: 300,
                              fontSize: "15px",
                              marginTop: "5px",
                            }}
                          >
                            Click desired contractor from search results
                          </Typography>

                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={tableHeadCell}>Name</TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Company Name
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Email
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Phone Number
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {generalContractors
                                  ?.slice(
                                    generalContractorPage *
                                      rowsPerPageForGeneralContractorPage,
                                    generalContractorPage *
                                      rowsPerPageForGeneralContractorPage +
                                      rowsPerPageForGeneralContractorPage
                                  )
                                  ?.map((contractor, index) => (
                                    <TableRow
                                      selected={
                                        generalContractorId === contractor.id
                                      }
                                      key={index}
                                      sx={[
                                        tableDataRow,
                                        {
                                          backgroundColor:
                                            contractor.id ===
                                            generalContractorId
                                              ? "crimson"
                                              : "",
                                          color:
                                            contractor.id ===
                                            generalContractorId
                                              ? "white"
                                              : "",
                                        },
                                      ]}
                                      onClick={() =>
                                        handleGeneralTableRowClick(contractor)
                                      }
                                    >
                                      <TableCell sx={tableDataCell}>
                                        {contractor?.name}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {contractor?.companyName}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {contractor?.email}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {contractor?.phoneNumber}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <CustomPagination
                            page={generalContractorPage}
                            setPage={setGeneralContractorPage}
                            rowsPerPage={rowsPerPageForGeneralContractorPage}
                            handleChangePage={handleGeneralContractorChangePage}
                            filteredList={generalContractors}
                            setRowsPerPage={setRowsPerPageForGeneralContractors}
                          />
                          <Button
                            sx={applicationFormCreateButton}
                            onClick={() => {
                              setShowGeneralContractor(false);
                              setShowGeneralContractorForm(true);
                            }}
                          >
                            <AddBoxOutlined /> Add General Contractor
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Box>
                {showGeneralContractorForm && (
                  <Box component="div" sx={{ marginTop: "20px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      General Contractor Information
                    </Typography>
                    <Grid container spacing={2} sx={{ my: "5px" }}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !generalContractorObject.name &&
                            saveGeneralContractorPressed
                          }
                          helperText={
                            !generalContractorObject.name &&
                            saveGeneralContractorPressed
                              ? "Name is required"
                              : ""
                          }
                          id="general-contractor-name"
                          placeholder="General Contractor Contact Name"
                          value={generalName}
                          name="name"
                          sx={applicationFormTitleField}
                          onChange={handleGeneralContractorChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !generalContractorObject.companyName &&
                            saveGeneralContractorPressed
                          }
                          helperText={
                            !generalContractorObject.companyName &&
                            saveGeneralContractorPressed
                              ? "Company name is required"
                              : ""
                          }
                          id="general-contractor-company-name"
                          placeholder="General Contractor Company"
                          name="companyName"
                          value={generalCompanyName}
                          sx={applicationFormTitleField}
                          onChange={handleGeneralContractorChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="dwelling-contractor-email"
                          placeholder="General Contractor Company Email"
                          name="email"
                          value={generalEmail}
                          type="email"
                          sx={applicationFormTitleField}
                          onChange={handleGeneralContractorChange}
                          error={
                            generalEmailError ||
                            (!generalContractorObject?.email &&
                              saveGeneralContractorPressed)
                          }
                          helperText={
                            generalEmailError ||
                            (!generalContractorObject?.email &&
                              saveGeneralContractorPressed)
                              ? "Please enter a valid email address"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !generalContractorObject.phoneNumber &&
                            saveGeneralContractorPressed
                          }
                          helperText={
                            !generalContractorObject.phoneNumber &&
                            saveGeneralContractorPressed
                              ? "Phone no is required"
                              : ""
                          }
                          id="dwelling-contractor-phone-number"
                          placeholder="General Contractor Company Phone No."
                          // type="number"
                          name="phoneNumber"
                          value={generalPhoneNumber}
                          sx={applicationFormTitleField}
                          onChange={handleGeneralContractorChange}
                          inputProps={{ min: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Button
                          sx={{
                            ...saveButton,
                            backgroundColor: contractorLoader
                              ? "grey"
                              : "#d52027",
                          }}
                          onClick={() => {
                            handleGeneralContractorSubmit();
                          }}
                        >
                          Save Contractor
                          {generalContractorLoader ? (
                            <CircularProgressLoader />
                          ) : (
                            ""
                          )}
                        </Button>
                        <Button
                          sx={cancelButton}
                          onClick={() => {
                            setGeneralContractorObject({});
                            setShowGeneralContractorForm(false);
                            setShowGeneralContractor(true);
                            setSaveGeneralContractorPressed(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </>
            ))}

          {(permitIds?.includes(1) ||
            permitIds?.includes(6) ||
            permitIds?.includes(7) ||
            permitIds?.includes(8) ||
            permitIds?.includes(9) ||
            permitIds?.includes(10)) &&
            buildingType < 3 &&
            projectType < 5 &&
            (selectedContractorDataShow ? (
              <ContractorDetail
                type="Dwelling"
                data={selectedContractorData}
                handleClose={handleShowSelectedFormClose}
              />
            ) : (
              <>
                <Box component="div" sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                  >
                    Construction
                  </Typography>

                  <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="dwellingContractorByOwner"
                          checked={
                            dwellingContractorByOwner
                              ? dwellingContractorByOwner
                              : false
                          }
                          sx={{ fontSize: 60 }}
                          onChange={handleChangeCheckBox}
                        />
                      }
                      label="By Owner"
                    />
                  </FormGroup>
                  {applicationFormObject.dwellingContractorByOwner !== true && (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#414042",
                          fontWeight: 300,
                          fontSize: "15px",
                          marginTop: "5px",
                        }}
                      >
                        Search Dwelling Contractor if the contractor is not
                        listed click the add button to enter contractors
                        information
                      </Typography>
                      <Grid container spacing={2} sx={{ mt: "2px" }}>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                          <TextField
                            id="search-dweeling-contractor"
                            placeholder="Enter name, company name, license number or phone number..."
                            name="searchDwellContractor"
                            value={
                              dwellingContractorSearchString
                                ? dwellingContractorSearchString
                                : ""
                            }
                            sx={applicationFormTitleField}
                            onChange={(event) => {
                              handleContractorSearchString(
                                event.target.value,
                                "dwellingContractor"
                              );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Button
                            disabled={!dwellingContractorSearchString}
                            sx={applicationFormSearchButton}
                            onClick={() =>
                              handleFilterContractorType("dwellingContractor")
                            }
                          >
                            <AddBoxOutlined /> Search
                          </Button>
                        </Grid>
                      </Grid>

                      {showDwellContractor && (
                        <>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#414042",
                              fontWeight: 300,
                              fontSize: "15px",
                              marginTop: "5px",
                            }}
                          >
                            Click desired contractor from search results
                          </Typography>

                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={tableHeadCell}>Name</TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Company Name
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    License Number
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Phone Number
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dwellContractors
                                  ?.slice(
                                    dwellContractorPage *
                                      rowsPerPageForDwellContractorPage,
                                    dwellContractorPage *
                                      rowsPerPageForDwellContractorPage +
                                      rowsPerPageForDwellContractorPage
                                  )
                                  ?.map((contractor, index) => (
                                    <TableRow
                                      selected={contractorId === contractor.id}
                                      key={index}
                                      sx={[
                                        tableDataRow,
                                        {
                                          backgroundColor:
                                            contractor.id === contractorId
                                              ? "crimson"
                                              : "",
                                          color:
                                            contractor.id === contractorId
                                              ? "white"
                                              : "",
                                        },
                                      ]}
                                      onClick={() =>
                                        handleContractorTableRowClick(
                                          contractor
                                        )
                                      }
                                    >
                                      <TableCell sx={tableDataCell}>
                                        {contractor?.name}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {contractor?.companyName}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {contractor?.licenseNumber}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {contractor?.phoneNumber}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <CustomPagination
                            page={dwellContractorPage}
                            setPage={setDwellContractorPage}
                            rowsPerPage={rowsPerPageForDwellContractorPage}
                            handleChangePage={handleDwellContractorChangePage}
                            filteredList={dwellContractors}
                            setRowsPerPage={setRowsPerPageForDwellContractors}
                          />
                          <Button
                            sx={applicationFormCreateButton}
                            onClick={() => {
                              setShowDwellContractor(false);
                              setShowDwellContractorForm(true);
                            }}
                          >
                            <AddBoxOutlined /> Add Dwelling Contractor
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Box>
                {showDwellContractorForm && (
                  <Box component="div" sx={{ marginTop: "20px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      Dwelling Contractor Information
                    </Typography>
                    <Grid container spacing={2} sx={{ my: "5px" }}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !contractorObject.name && saveContractorPressed
                          }
                          helperText={
                            !contractorObject.name && saveContractorPressed
                              ? "Name is required"
                              : ""
                          }
                          id="dwelling-contractor-name"
                          placeholder="Dwelling Contractor name"
                          value={name ? name : ""}
                          name="name"
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !contractorObject.companyName &&
                            saveContractorPressed
                          }
                          helperText={
                            !contractorObject.companyName &&
                            saveContractorPressed
                              ? "Company name is required"
                              : ""
                          }
                          id="dwelling-contractor-company-name"
                          placeholder="Dwelling Contractor Company"
                          name="companyName"
                          value={companyName ? companyName : ""}
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="dwelling-contractor-email"
                          placeholder="Dwelling Contractor Email"
                          name="email"
                          value={email ? email : ""}
                          type="email"
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            !!dwellEmailError ||
                            (buildingType < 3 &&
                              !contractorObject?.email &&
                              saveContractorPressed)
                          }
                          helperText={
                            dwellEmailError ||
                            (buildingType < 3 &&
                              !contractorObject?.email &&
                              saveContractorPressed)
                              ? "Please enter a valid email address"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !contractorObject.phoneNumber &&
                            saveContractorPressed
                          }
                          helperText={
                            !contractorObject.phoneNumber &&
                            saveContractorPressed
                              ? "Phone no is required"
                              : ""
                          }
                          id="dwelling-contractor-phone-number"
                          placeholder="Dwelling Contractor Phone No."
                          name="phoneNumber"
                          value={phoneNumber ? phoneNumber : ""}
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            buildingType < 3 &&
                            !contractorObject.licenseNumber &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.licenseNumber &&
                            saveContractorPressed
                              ? "License no is required"
                              : ""
                          }
                          id="dwelling-contractor-license-number"
                          placeholder="Dwelling Contractor License No."
                          name="licenseNumber"
                          value={licenseNumber ? licenseNumber : ""}
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            buildingType < 3 &&
                            !contractorObject.licenseExpirationDate &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.licenseExpirationDate &&
                            saveContractorPressed
                              ? "License No. expiration date is Required"
                              : ""
                          }
                          id="dwelling-contractor-license-expire-date"
                          placeholder="License Expiration Date"
                          name="licenseExpirationDate"
                          value={
                            licenseExpirationDate ? licenseExpirationDate : ""
                          }
                          onChange={handleContractorChange}
                          disableUnderline
                          fullWidth
                          sx={textfieldDate}
                          label="License Expiration Date"
                          inputProps={{
                            max: "9999-12-31",
                          }}
                          type="date"
                          variant="filled"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          // error
                          id="dwelling-contractor-address"
                          placeholder="Dwelling Contractor Street Address"
                          name="address"
                          value={address ? address : ""}
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.address &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.address &&
                            saveContractorPressed
                              ? "Address is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          // error
                          id="dwelling-contractor-city"
                          placeholder="Dwelling Contractor City"
                          name="city"
                          value={city ? city : ""}
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.city &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.city &&
                            saveContractorPressed
                              ? "City is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          // error
                          id="dwelling-contractor-zipd-code"
                          type="number"
                          placeholder="Dwelling Contractor Zip Code"
                          name="zip"
                          value={zip ? zip : ""}
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.zip &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.zip &&
                            saveContractorPressed
                              ? "Zip is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          // error
                          id="dwelling-contractor-qualifier-name"
                          placeholder="Dwelling Contractor Qualifier Name"
                          value={
                            dwellingContractorQualifierName
                              ? dwellingContractorQualifierName
                              : ""
                          }
                          name="dwellingContractorQualifierName"
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierName &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierName &&
                            saveContractorPressed
                              ? "Dwelling contractor qualifier name is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          // error
                          id="dwelling-contractor-qualifier-address"
                          placeholder="Dwelling Contractor Qualifier Address"
                          value={
                            dwellingContractorQualifierAddress
                              ? dwellingContractorQualifierAddress
                              : ""
                          }
                          name="dwellingContractorQualifierAddress"
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierAddress &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierAddress &&
                            saveContractorPressed
                              ? "Dwelling contractor qualifier address is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          // error
                          id="dwelling-contractor-qualifier-license-number"
                          placeholder="Dwelling Contractor Qualifier License No."
                          name="dwellingContractorQualifierLicenseNumber"
                          value={
                            dwellingContractorQualifierLicenseNumber
                              ? dwellingContractorQualifierLicenseNumber
                              : ""
                          }
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierLicenseNumber &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierLicenseNumber &&
                            saveContractorPressed
                              ? "Dwelling contractor qualifier license number is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="dwelling-contractor-qualifier-license-number-expire-date"
                          name="dwellingContractorQualifierLicenseExpirationDate"
                          value={
                            dwellingContractorQualifierLicenseExpirationDate
                              ? dwellingContractorQualifierLicenseExpirationDate
                              : ""
                          }
                          onChange={handleContractorChange}
                          disableUnderline
                          fullWidth
                          sx={textfieldDate}
                          label="License Expiration Date"
                          inputProps={{
                            max: "9999-12-31",
                          }}
                          type="date"
                          variant="filled"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                          error={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierLicenseExpirationDate &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierLicenseExpirationDate &&
                            saveContractorPressed
                              ? "Dwelling contractor qualifier license expiration date is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="dwelling-contractor-qualifier-city"
                          placeholder="Dwelling Contractor Qualifier City"
                          name="dwellingContractorQualifierCity"
                          value={
                            dwellingContractorQualifierCity
                              ? dwellingContractorQualifierCity
                              : ""
                          }
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierCity &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierCity &&
                            saveContractorPressed
                              ? "Dwelling contractor qualifier city is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="dwelling-contractor-qualifier-zip-code"
                          type="number"
                          placeholder="Dwelling Contractor Qualifier Zip Code"
                          name="dwellingContractorQualifierZip"
                          value={
                            dwellingContractorQualifierZip
                              ? dwellingContractorQualifierZip
                              : ""
                          }
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierZip &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierZip &&
                            saveContractorPressed
                              ? "Dwelling contractor qualifier zip code is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="dwelling-contractor-qualifier-email"
                          placeholder="Dwelling Contractor Qualifier Email"
                          name="dwellingContractorQualifierEmail"
                          value={
                            dwellingContractorQualifierEmail
                              ? dwellingContractorQualifierEmail
                              : ""
                          }
                          type="email"
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            !!dwellQualifierEmailError ||
                            (!contractorObject?.dwellingContractorQualifierEmail &&
                              buildingType < 3 &&
                              saveContractorPressed)
                          }
                          helperText={
                            (dwellQualifierEmailError &&
                              dwellQualifierEmailError) ||
                            (!contractorObject?.dwellingContractorQualifierEmail &&
                              buildingType < 3 &&
                              saveContractorPressed)
                              ? "Please enter a valid email address"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="dwelling-contractor-qualifier-phone-number"
                          placeholder="Dwelling Contractor Qualifier Phone No."
                          name="dwellingContractorQualifierPhoneNumber"
                          value={
                            dwellingContractorQualifierPhoneNumber
                              ? dwellingContractorQualifierPhoneNumber
                              : ""
                          }
                          sx={applicationFormTitleField}
                          onChange={handleContractorChange}
                          error={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierPhoneNumber &&
                            saveContractorPressed
                          }
                          helperText={
                            buildingType < 3 &&
                            !contractorObject.dwellingContractorQualifierPhoneNumber &&
                            saveContractorPressed
                              ? "Dwelling contractor qualifier phone number is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Button
                          sx={{
                            ...saveButton,
                            backgroundColor: contractorLoader
                              ? "grey"
                              : "#d52027",
                          }}
                          onClick={() => {
                            handleDwellContractorSubmit();
                          }}
                        >
                          Save Contractor
                          {contractorLoader ? <CircularProgressLoader /> : ""}
                        </Button>
                        <Button
                          sx={cancelButton}
                          onClick={() => {
                            setContractorId(null);
                            setShowDwellContractor(true);
                            setShowDwellContractorForm(false);
                            setContractorObject({});
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </>
            ))}

          {permitIds?.includes(3) &&
            buildingType &&
            (selectedElectricalDataShow ? (
              <ContractorDetail
                type="Electrical"
                data={selectedElectrcialData}
                handleClose={handleShowElectricalSelectedFormClose}
              />
            ) : (
              <>
                {/* Electrical information box */}
                <Box component="div" sx={{ marginTop: "30px" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                  >
                    Electrical
                  </Typography>

                  {/* checkbox electrical contractor  */}

                  <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="electricalContractorByOwner"
                          checked={
                            electricalContractorByOwner
                              ? electricalContractorByOwner
                              : false
                          }
                          sx={{ fontSize: 60 }}
                          onChange={handleChangeCheckBox}
                        />
                      }
                      label="By Owner"
                    />
                  </FormGroup>

                  {electricalContractorByOwner !== true && (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#414042",
                          fontWeight: 300,
                          fontSize: "15px",
                          marginTop: "5px",
                        }}
                      >
                        Search Electrical Contractor if the contractor is not
                        listed click the add button to enter contractors
                        information
                      </Typography>
                      <Grid container spacing={2} sx={{ mt: "2px" }}>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                          <TextField
                            id="outlined-error-helper-text"
                            placeholder="Enter company name, license number or phone number..."
                            name="searchElectricalContractor"
                            value={
                              electicalContractorSearchString
                                ? electicalContractorSearchString
                                : ""
                            }
                            sx={applicationFormTitleField}
                            onChange={(event) => {
                              handleContractorSearchString(
                                event.target.value,
                                "electricContractor"
                              );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Button
                            sx={applicationFormSearchButton}
                            disabled={!electicalContractorSearchString}
                            onClick={() =>
                              handleFilterContractorType("electricContractor")
                            }
                          >
                            <AddBoxOutlined /> Search
                          </Button>
                        </Grid>
                      </Grid>

                      {showElectricalTable && (
                        <>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#414042",
                              fontWeight: 300,
                              fontSize: "15px",
                              marginTop: "5px",
                            }}
                          >
                            Click desired Electrician from search results
                          </Typography>

                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={tableHeadCell}>
                                    Company Name
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Phone Number
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    License Number
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Master Electrician License Number/Expiration
                                    Date
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {electricalContractors
                                  ?.slice(
                                    electricalContractorPage *
                                      rowsPerPageForElectricalContractor,
                                    electricalContractorPage *
                                      rowsPerPageForElectricalContractor +
                                      rowsPerPageForElectricalContractor
                                  )
                                  ?.map((electricalContractor, index) => (
                                    <TableRow
                                      key={index}
                                      selected={
                                        electricalContractor.id === electricalId
                                      }
                                      sx={[
                                        tableDataRow,
                                        {
                                          backgroundColor:
                                            electricalContractor.id ===
                                            electricalId
                                              ? "crimson"
                                              : "",
                                          color:
                                            electricalContractor.id ===
                                            electricalId
                                              ? "white"
                                              : "",
                                        },
                                      ]}
                                      onClick={() =>
                                        handleElectricalTableRowClick(
                                          electricalContractor
                                        )
                                      }
                                    >
                                      <TableCell sx={tableDataCell}>
                                        {electricalContractor?.companyName}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {electricalContractor?.phoneNumber}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {electricalContractor?.licenseNumber}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {electricalContractor?.masterElectricianLicenseNumber +
                                          " | " +
                                          electricalContractor?.masterElectricianLicenseExpirationDate?.split(
                                            "T"
                                          )[0]}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <CustomPagination
                            page={electricalContractorPage}
                            setPage={setElectricalContractorPage}
                            rowsPerPage={rowsPerPageForElectricalContractor}
                            handleChangePage={
                              handleElectricalContractorChangePage
                            }
                            filteredList={electricalContractors}
                            setRowsPerPage={
                              setRowsPerPageForElectricalContractor
                            }
                          />

                          <Button
                            sx={applicationFormCreateButton}
                            onClick={() => {
                              setShowElecrtricalTable(false),
                                setShowElecrtricalForm(!showElecrticalForm);
                            }}
                          >
                            <AddBoxOutlined /> Add Electrical Contractor
                          </Button>
                        </>
                      )}
                    </>
                  )}

                  {/* Electrical contractor's info box */}
                  {showElecrticalForm && (
                    <>
                      <Box component="div" sx={{ marginTop: "20px" }}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#414042",
                            fontWeight: 600,
                            fontSize: "15px",
                          }}
                        >
                          Electrical Contractor Information
                        </Typography>
                        <Grid container spacing={2} sx={{ my: "5px" }}>
                          {/* Electrical name */}
                          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            error={
                              !electricalObject.name && saveElectricalPressed
                            }
                            helperText={
                              !electricalObject.name && saveElectricalPressed
                                ? "Name is required"
                                : ""
                            }
                            id="electrical-contractor-name"
                            placeholder="Electrical Contractor Name"
                            value={electricalName}
                            name="name"
                            sx={applicationFormTitleField}
                            onChange={handleElectricalChange}
                          />
                        </Grid> */}

                          {/* Electrical company name */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              id="electrical-contract-company"
                              placeholder="Electrical Contractor Company"
                              value={electricalCompanyName}
                              name="companyName"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.companyName &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.companyName &&
                                saveElectricalPressed
                                  ? "Company name is required"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* Electrical company email */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              id="electrical-company-email"
                              placeholder="Electrical Contractor Email"
                              type="email"
                              value={electricalEmail}
                              name="email"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !!electricalEmailError ||
                                (!electricalObject?.email &&
                                  saveElectricalPressed)
                              }
                              helperText={
                                (electricalEmailError &&
                                  electricalEmailError) ||
                                (!electricalObject?.email &&
                                  saveElectricalPressed)
                                  ? "Please enter a valid email address"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* electrical phone number */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              error={
                                !electricalObject.phoneNumber &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.phoneNumber &&
                                saveElectricalPressed
                                  ? "Phone number is required"
                                  : ""
                              }
                              id="electrical-phone-number"
                              placeholder="Electrical Contractor Phone No."
                              value={electricalPhoneNumber}
                              name="phoneNumber"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                            />
                          </Grid>

                          {/* master electrical License number */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              error={
                                !electricalObject.licenseNumber &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.licenseNumber &&
                                saveElectricalPressed
                                  ? "License no is required"
                                  : ""
                              }
                              id="electrician-license-number"
                              placeholder="Electrical Contractor License No."
                              value={electricalLicenseNumber}
                              name="licenseNumber"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                            />
                          </Grid>

                          {/* master electrician license expire date */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              error={
                                !electricalObject.licenseExpirationDate &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.licenseExpirationDate &&
                                saveElectricalPressed
                                  ? "License expiration date is required"
                                  : ""
                              }
                              id="electrician-license-expire-date"
                              placeholder="Electrician Contractor's License Expiration Date"
                              value={electricalLicenseExpirationDate}
                              name="licenseExpirationDate"
                              onChange={handleElectricalChange}
                              disableUnderline
                              fullWidth
                              sx={textfieldDate}
                              label="License Expiration Date"
                              inputProps={{
                                max: "9999-12-31",
                              }}
                              type="date"
                              variant="filled"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{ disableUnderline: true }}
                            />
                          </Grid>

                          {/* electrical street address */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              id="electrical-street-address"
                              placeholder="Electrical Contractor Street Address"
                              value={electricalAddress}
                              name="address"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.address &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.address &&
                                saveElectricalPressed
                                  ? "Address is required"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* electrical city field */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              id="electrical-city"
                              placeholder="Electrical Contractor City"
                              value={electricalCity}
                              name="city"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.city && saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.city && saveElectricalPressed
                                  ? "City is required"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* electrical zip code */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              type="number"
                              id="electrical-zip-code"
                              placeholder="Electrical Contractor Zipcode"
                              value={electricalZip}
                              name="zip"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.zip && saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.zip && saveElectricalPressed
                                  ? "Zip is required"
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {/* Master Electrian contractor's info box */}
                      <Box component="div" sx={{ marginTop: "20px" }}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#414042",
                            fontWeight: 600,
                            fontSize: "15px",
                          }}
                        >
                          Master Electrian Information
                        </Typography>
                        <Grid container spacing={2} sx={{ my: "5px" }}>
                          {/* maaster electrician name */}
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              id="master-electrician-name"
                              placeholder="Master Electrician Name "
                              value={
                                masterElectricianName
                                  ? masterElectricianName
                                  : ""
                              }
                              name="masterElectricianName"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.masterElectricianName &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.masterElectricianName &&
                                saveElectricalPressed
                                  ? "Master electrician name is required"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* master electrician email */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              id="master-electrical-email"
                              placeholder="Master Electrician Email"
                              value={
                                masterElectricianEmail
                                  ? masterElectricianEmail
                                  : ""
                              }
                              name="masterElectricianEmail"
                              type="email"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !!electricalMasterEmailError ||
                                (!electricalObject?.masterElectricianEmail &&
                                  saveElectricalPressed)
                              }
                              helperText={
                                (electricalMasterEmailError &&
                                  electricalMasterEmailError) ||
                                (!electricalObject?.masterElectricianEmail &&
                                  saveElectricalPressed)
                                  ? "Please enter a valid email address"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* master electrician phonenumber */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              id="master-electrician-phoneNumber"
                              placeholder="Master Electrician Phone Number"
                              value={
                                masterElectricianPhoneNumber
                                  ? masterElectricianPhoneNumber
                                  : ""
                              }
                              name="masterElectricianPhoneNumber"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.masterElectricianPhoneNumber &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.masterElectricianPhoneNumber &&
                                saveElectricalPressed
                                  ? "Master electrician phone number is required"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* master electrician certificate no */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              error={
                                !electricalObject.masterElectricianLicenseNumber &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.masterElectricianLicenseNumber &&
                                saveElectricalPressed
                                  ? "Master electrician license no is required"
                                  : ""
                              }
                              id="master-electrician-certificate"
                              placeholder="Master Electrician License No."
                              value={
                                masterElectricianLicenseNumber
                                  ? masterElectricianLicenseNumber
                                  : ""
                              }
                              name="masterElectricianLicenseNumber"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                            />
                          </Grid>

                          {/* master electrician license expiration date */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              error={
                                !electricalObject.masterElectricianLicenseExpirationDate &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.masterElectricianLicenseExpirationDate &&
                                saveElectricalPressed
                                  ? "Master electrician license expiration date is required"
                                  : ""
                              }
                              id="master-electrical-license-expire-date"
                              placeholder="Master Electrician License Expire Date"
                              value={
                                masterElectricianLicenseExpirationDate
                                  ? masterElectricianLicenseExpirationDate
                                  : ""
                              }
                              name="masterElectricianLicenseExpirationDate"
                              onChange={handleElectricalChange}
                              disableUnderline
                              fullWidth
                              sx={textfieldDate}
                              label="License Expiration Date"
                              inputProps={{
                                max: "9999-12-31",
                              }}
                              type="date"
                              variant="filled"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{ disableUnderline: true }}
                            />
                          </Grid>

                          {/* master electrical address */}
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              id="master-electrical-address"
                              placeholder="Master Electrician Street Address"
                              value={
                                masterElectricianAddress
                                  ? masterElectricianAddress
                                  : ""
                              }
                              name="masterElectricianAddress"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.masterElectricianAddress &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.masterElectricianAddress &&
                                saveElectricalPressed
                                  ? "Master electrician address is required"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* master Electrical city */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              id="master-electrical-city"
                              placeholder="Master Electrician City"
                              value={
                                masterElectricianCity
                                  ? masterElectricianCity
                                  : ""
                              }
                              name="masterElectricianCity"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.masterElectricianCity &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.masterElectricianCity &&
                                saveElectricalPressed
                                  ? "Master electrician city is required"
                                  : ""
                              }
                            />
                          </Grid>

                          {/* master electrical zipcode */}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              type="number"
                              id="master-electrical-zipcode"
                              placeholder="Master Electrician Zipcode"
                              value={
                                masterElectricianZip ? masterElectricianZip : ""
                              }
                              name="masterElectricianZip"
                              sx={applicationFormTitleField}
                              onChange={handleElectricalChange}
                              error={
                                !electricalObject.masterElectricianZip &&
                                saveElectricalPressed
                              }
                              helperText={
                                !electricalObject.masterElectricianZip &&
                                saveElectricalPressed
                                  ? "Master electrician zip code is required"
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 3 }}>
                        <Button
                          sx={{
                            ...saveButton,
                            backgroundColor: contractorLoader
                              ? "grey"
                              : "#d52027",
                          }}
                          onClick={() => {
                            handleElectricContractorSubmit();
                          }}
                        >
                          Save Contractor
                          {contractorLoader ? <CircularProgressLoader /> : ""}
                        </Button>
                        <Button
                          sx={cancelButton}
                          onClick={() => {
                            setElectricalObject({});
                            setShowElecrtricalForm(false);
                            setShowElecrtricalTable(true);
                            setElectricalObject({});
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            ))}

          {permitIds?.includes(4) &&
            buildingType &&
            (selectedPlumberDataShow ? (
              <ContractorDetail
                type="Plumber"
                data={selectedPlumberData}
                handleClose={handleShowPlumberSelectedFormClose}
              />
            ) : (
              <>
                {/* Plumbing information box */}
                <Box component="div" sx={{ marginTop: "30px" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                  >
                    Plumbing
                  </Typography>

                  {/* plumbing checkbox by owner */}

                  <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="plumbingContractorByOwner"
                          checked={
                            plumbingContractorByOwner
                              ? plumbingContractorByOwner
                              : false
                          }
                          sx={{ fontSize: 60 }}
                          onChange={handleChangeCheckBox}
                        />
                      }
                      label="By Owner"
                    />
                  </FormGroup>

                  {plumbingContractorByOwner !== true && (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#414042",
                          fontWeight: 300,
                          fontSize: "15px",
                          marginTop: "5px",
                        }}
                      >
                        Search Plumbing Contractor if the contractor is not
                        listed click the add button to enter contractors
                        information
                      </Typography>
                      <Grid container spacing={2} sx={{ mt: "2px" }}>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                          <TextField
                            id="outlined-error-helper-text"
                            placeholder="Enter compamy name,license number or phone number..."
                            value={
                              plumbingContractorSearchString
                                ? plumbingContractorSearchString
                                : ""
                            }
                            name="searchPlumbingContractor"
                            sx={applicationFormTitleField}
                            onChange={(event) => {
                              handleContractorSearchString(
                                event.target.value,
                                "plumberContractor"
                              );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Button
                            sx={applicationFormSearchButton}
                            disabled={!plumbingContractorSearchString}
                            onClick={() =>
                              handleFilterContractorType("plumberContractor")
                            }
                          >
                            <AddBoxOutlined /> Search
                          </Button>
                        </Grid>
                      </Grid>

                      {showPlumbingTable && (
                        <>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#414042",
                              fontWeight: 300,
                              fontSize: "15px",
                              marginTop: "5px",
                            }}
                          >
                            Click desired plumber from search results
                          </Typography>

                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={tableHeadCell}>
                                    Company Name
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Phone Number
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    License Number
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Expiration Date
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {plumberContractors
                                  ?.slice(
                                    plumberContractorPage *
                                      rowsPerPageForPlumberContractor,
                                    plumberContractorPage *
                                      rowsPerPageForPlumberContractor +
                                      rowsPerPageForPlumberContractor
                                  )
                                  ?.map((plumberContractor, index) => (
                                    <TableRow
                                      selected={
                                        plumberContractor.id === plumberId
                                      }
                                      key={index}
                                      sx={[
                                        tableDataRow,
                                        {
                                          backgroundColor:
                                            plumberContractor.id === plumberId
                                              ? "crimson"
                                              : "",
                                          color:
                                            plumberContractor.id === plumberId
                                              ? "white"
                                              : "",
                                        },
                                      ]}
                                      onClick={() =>
                                        handlePlumberTableRowClick(
                                          plumberContractor
                                        )
                                      }
                                    >
                                      <TableCell sx={tableDataCell}>
                                        {plumberContractor.companyName}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {plumberContractor.phoneNumber}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {plumberContractor.licenseNumber}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {
                                          plumberContractor.licenseExpirationDate?.split(
                                            "T"
                                          )[0]
                                        }
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <CustomPagination
                            page={plumberContractorPage}
                            setPage={setPlumberContractorPage}
                            rowsPerPage={rowsPerPageForPlumberContractor}
                            handleChangePage={handlePlumberContractorChangePage}
                            filteredList={plumberContractors}
                            setRowsPerPage={setRowsPerPageForPlumberContractor}
                          />
                          <Button
                            sx={applicationFormCreateButton}
                            onClick={() => {
                              setShowPlumbingTable(false),
                                setShowPlumbingForm(!showPlumbingForm);
                            }}
                          >
                            <AddBoxOutlined /> Add Plumbing Contractor
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Box>

                {/* Plumbing contractor's info box */}
                {showPlumbingForm && (
                  <Box component="div" sx={{ marginTop: "20px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      Plumber Contractor Information
                    </Typography>
                    <Grid container spacing={2} sx={{ my: "5px" }}>
                      {/* Plumber name */}
                      {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={!plumberObject.name && savePlumberPressed}
                        helperText={
                          !plumberObject.name && savePlumberPressed
                            ? "Name is required"
                            : ""
                        }
                        id="plumber-contractor-name"
                        placeholder="Plumber Contractor Name"
                        value={plumberName}
                        name="name"
                        sx={applicationFormTitleField}
                        onChange={handlePlumberChange}
                      />
                    </Grid> */}

                      {/* Plumber company name */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !plumberObject.companyName && savePlumberPressed
                          }
                          helperText={
                            !plumberObject.companyName && savePlumberPressed
                              ? "Company name is required"
                              : ""
                          }
                          id="plumber-contract-company"
                          placeholder="Plumber Contractor Company"
                          value={plumberCompanyName}
                          name="companyName"
                          sx={applicationFormTitleField}
                          onChange={handlePlumberChange}
                        />
                      </Grid>

                      {/* Plumber company email */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="plumber-company-email"
                          placeholder="Plumber Contractor Email"
                          type="email"
                          value={plumberEmail}
                          name="email"
                          sx={applicationFormTitleField}
                          onChange={handlePlumberChange}
                          error={
                            !!plumberEmailError ||
                            (!plumberObject?.email && savePlumberPressed)
                          }
                          helperText={
                            (plumberEmailError && plumberEmailError) ||
                            (!plumberObject?.email && savePlumberPressed)
                              ? "Please enter a valid email address"
                              : ""
                          }
                        />
                      </Grid>

                      {/* Pluimber phone number */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !plumberObject.phoneNumber && savePlumberPressed
                          }
                          helperText={
                            !plumberObject.phoneNumber && savePlumberPressed
                              ? "Phone No Required"
                              : ""
                          }
                          id="plumber-phone-number"
                          placeholder="Plumber Contractor Phone No."
                          value={plumberPhoneNumber}
                          name="phoneNumber"
                          sx={applicationFormTitleField}
                          onChange={handlePlumberChange}
                        />
                      </Grid>

                      {/* plumber License number */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !plumberObject.licenseNumber && savePlumberPressed
                          }
                          helperText={
                            !plumberObject.licenseNumber && savePlumberPressed
                              ? "License no is required"
                              : ""
                          }
                          id="plumber-license-number"
                          placeholder="Plumber Contractor License No."
                          value={plumberLicenseNumber}
                          name="licenseNumber"
                          sx={applicationFormTitleField}
                          onChange={handlePlumberChange}
                        />
                      </Grid>

                      {/* plumber license expire date */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !plumberObject.licenseExpirationDate &&
                            savePlumberPressed
                          }
                          helperText={
                            !plumberObject.licenseExpirationDate &&
                            savePlumberPressed
                              ? "License expiration date is required"
                              : ""
                          }
                          id="plumber-license-expire-date"
                          placeholder="Plumber Contractor License Expiration Date"
                          value={plumberLicenseExpirationDate}
                          name="licenseExpirationDate"
                          onChange={handlePlumberChange}
                          fullWidth
                          sx={textfieldDate}
                          label="License Expiration Date"
                          inputProps={{
                            max: "9999-12-31",
                          }}
                          type="date"
                          variant="filled"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>

                      {/* plumber street address */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="plumber-street-address"
                          placeholder="Plumber Contractor Street Address"
                          value={plumberAddress}
                          name="address"
                          sx={applicationFormTitleField}
                          onChange={handlePlumberChange}
                          error={!plumberObject.address && savePlumberPressed}
                          helperText={
                            !plumberObject.address && savePlumberPressed
                              ? "Address is required"
                              : ""
                          }
                        />
                      </Grid>

                      {/* plumber city field */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="plumber-city"
                          placeholder="Plumber Contractor City"
                          value={plumberCity}
                          name="city"
                          sx={applicationFormTitleField}
                          onChange={handlePlumberChange}
                          error={!plumberObject.city && savePlumberPressed}
                          helperText={
                            !plumberObject.city && savePlumberPressed
                              ? "City is required"
                              : ""
                          }
                        />
                      </Grid>

                      {/* plumber zip code */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          // error
                          type="number"
                          id="plumber-zip-code"
                          placeholder="Plumber Contractor Zipcode"
                          value={plumberZip}
                          name="zip"
                          sx={applicationFormTitleField}
                          onChange={handlePlumberChange}
                          error={!plumberObject.zip && savePlumberPressed}
                          helperText={
                            !plumberObject.zip && savePlumberPressed
                              ? "Zip is required"
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 3 }}>
                      <Button
                        sx={{
                          ...saveButton,
                          backgroundColor: contractorLoader
                            ? "grey"
                            : "#d52027",
                        }}
                        onClick={() => {
                          handlePlumberContractorSubmit();
                        }}
                      >
                        Save Contractor
                        {contractorLoader ? <CircularProgressLoader /> : ""}
                      </Button>
                      <Button
                        sx={cancelButton}
                        onClick={() => {
                          setPlumberObject({});
                          setShowPlumbingForm(false);
                          setShowPlumbingTable(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Box>
                )}
              </>
            ))}

          {permitIds?.includes(2) &&
            buildingType &&
            (selectedHvacDataShow ? (
              <ContractorDetail
                type="HVAC"
                data={selectedHvacData}
                handleClose={handleShowHvacSelectedFormClose}
              />
            ) : (
              <>
                {/* Heating information box */}
                <Box component="div" sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                  >
                    Heating
                  </Typography>

                  {/* plumbing checkbox by owner */}

                  <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="hvacContractorByOwner"
                          checked={
                            hvacContractorByOwner
                              ? hvacContractorByOwner
                              : false
                          }
                          sx={{ fontSize: 60 }}
                          onChange={handleChangeCheckBox}
                        />
                      }
                      label="By Owner"
                    />
                  </FormGroup>

                  {hvacContractorByOwner !== true && (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#414042",
                          fontWeight: 300,
                          fontSize: "15px",
                          marginTop: "5px",
                        }}
                      >
                        Search HVAC Contractor if the contractor is not listed
                        click the add button to enter contractors information
                      </Typography>
                      <Grid container spacing={2} sx={{ mt: "2px" }}>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                          <TextField
                            id="search-hvac-contractor"
                            placeholder="Enter company name, phone number or license number..."
                            value={
                              hvacContractorSearchString
                                ? hvacContractorSearchString
                                : ""
                            }
                            name="searchHVACContractor"
                            sx={applicationFormTitleField}
                            onChange={(event) => {
                              handleContractorSearchString(
                                event.target.value,
                                "HVACContractor"
                              );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Button
                            sx={{
                              ...applicationFormSearchButton,
                              "&:hover": {
                                backgroundColor: "black",
                                boxShadow: 2,
                                transition: ".7s ease-in",
                              },
                            }}
                            disabled={!hvacContractorSearchString}
                            onClick={() =>
                              handleFilterContractorType("HVACContractor")
                            }
                          >
                            <AddBoxOutlined /> Search
                          </Button>
                        </Grid>
                      </Grid>

                      {showHVACTable && (
                        <>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#414042",
                              fontWeight: 300,
                              fontSize: "15px",
                              marginTop: "5px",
                            }}
                          >
                            Click desired HVAC from search results
                          </Typography>

                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={tableHeadCell}>
                                    Company Name
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Phone Number
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    License Number
                                  </TableCell>
                                  <TableCell sx={tableHeadCell}>
                                    Expiration Date
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {hvacContractors
                                  ?.slice(
                                    hvacContractorPage *
                                      rowsPerPageForHvacContractor,
                                    hvacContractorPage *
                                      rowsPerPageForHvacContractor +
                                      rowsPerPageForHvacContractor
                                  )
                                  ?.map((hvacContractor, index) => (
                                    <TableRow
                                      selected={hvacContractor.id === hvacId}
                                      key={index}
                                      sx={[
                                        tableDataRow,
                                        {
                                          backgroundColor:
                                            hvacContractor.id === hvacId
                                              ? "crimson"
                                              : "",
                                          color:
                                            hvacContractor.id === hvacId
                                              ? "white"
                                              : "",
                                        },
                                      ]}
                                      onClick={() =>
                                        handleHvacTableRowClick(hvacContractor)
                                      }
                                    >
                                      <TableCell sx={tableDataCell}>
                                        {hvacContractor.companyName}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {hvacContractor.phoneNumber}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {hvacContractor.licenseNumber}
                                      </TableCell>
                                      <TableCell sx={tableDataCell}>
                                        {
                                          hvacContractor.licenseExpirationDate?.split(
                                            "T"
                                          )[0]
                                        }
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <CustomPagination
                            page={hvacContractorPage}
                            setPage={setHvacContractorPage}
                            rowsPerPage={rowsPerPageForHvacContractor}
                            handleChangePage={handleHvacContractorChangePage}
                            filteredList={hvacContractors}
                            setRowsPerPage={setRowsPerPageForHvacContractor}
                          />
                          <Button
                            sx={applicationFormCreateButton}
                            onClick={() => {
                              setShowHVACTable(false),
                                setShowHVACForm(!showHVACForm);
                            }}
                          >
                            <AddBoxOutlined /> Add Heating Contractor
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Box>

                {/* Plumbing contractor's info box */}
                {showHVACForm && (
                  <Box component="div" sx={{ marginTop: "20px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      Heating Contractor Information
                    </Typography>
                    <Grid container spacing={2} sx={{ my: "5px" }}>
                      {/* heating name */}
                      {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={!heatObject.name && saveHeatingPressed}
                        helperText={
                          !heatObject.name && saveHeatingPressed
                            ? "Name is required"
                            : ""
                        }
                        id="heating-contractor-name"
                        placeholder="Heating Contractor Name"
                        value={heatName}
                        name="name"
                        sx={applicationFormTitleField}
                        onChange={handleHeatObjectChange}
                      />
                    </Grid> */}

                      {/* heating company name */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={!heatObject.companyName && saveHeatingPressed}
                          helperText={
                            !heatObject.companyName && saveHeatingPressed
                              ? "Heating company is required"
                              : ""
                          }
                          id="heating-contract-company"
                          placeholder="Heating Contractor Company"
                          value={heatCompanyName}
                          name="companyName"
                          sx={applicationFormTitleField}
                          onChange={handleHeatObjectChange}
                        />
                      </Grid>

                      {/* heating company email */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="heating-company-email"
                          placeholder="Heating Contractor Email"
                          type="email"
                          value={heatEmail}
                          name="email"
                          sx={applicationFormTitleField}
                          onChange={handleHeatObjectChange}
                          error={
                            !!hvacEmailError ||
                            (!heatObject?.email && saveHeatingPressed)
                          }
                          helperText={
                            (hvacEmailError && hvacEmailError) ||
                            (!heatObject?.email && saveHeatingPressed)
                              ? "Please enter a valid email address"
                              : ""
                          }
                        />
                      </Grid>

                      {/* heat phone number */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={!heatObject.phoneNumber && saveHeatingPressed}
                          helperText={
                            !heatObject.phoneNumber && saveHeatingPressed
                              ? "Phone no is required"
                              : ""
                          }
                          id="heat-phone-number"
                          placeholder="Heating Contractor Phone No."
                          value={heatPhoneNumber}
                          name="phoneNumber"
                          sx={applicationFormTitleField}
                          onChange={handleHeatObjectChange}
                        />
                      </Grid>

                      {/* heat License number */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !heatObject.licenseNumber && saveHeatingPressed
                          }
                          helperText={
                            !heatObject.licenseNumber && saveHeatingPressed
                              ? "License no is required"
                              : ""
                          }
                          id="heat-license-number"
                          placeholder="Heating Contractor License No."
                          value={heatLicenseNumber}
                          name="licenseNumber"
                          sx={applicationFormTitleField}
                          onChange={handleHeatObjectChange}
                        />
                      </Grid>

                      {/* heat license expire date */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !heatObject.licenseExpirationDate &&
                            saveHeatingPressed
                          }
                          helperText={
                            !heatObject.licenseExpirationDate &&
                            saveHeatingPressed
                              ? "License expiration date is required"
                              : ""
                          }
                          id="heat-license-expire-date"
                          placeholder="Heat Contractor's License Expiration Date"
                          value={heatLicenseExpirationDate}
                          name="licenseExpirationDate"
                          onChange={handleHeatObjectChange}
                          disableUnderline
                          fullWidth
                          sx={textfieldDate}
                          label="License Expiration Date"
                          inputProps={{
                            max: "9999-12-31",
                          }}
                          type="date"
                          variant="filled"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>

                      {/* heat street address */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="heat-street-address"
                          placeholder="Heating Contractor Street Address"
                          value={heatAddress}
                          name="address"
                          sx={applicationFormTitleField}
                          onChange={handleHeatObjectChange}
                        />
                      </Grid>

                      {/* heat city field */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="heat-city"
                          placeholder="Heating Contractor City"
                          value={heatCity}
                          name="city"
                          sx={applicationFormTitleField}
                          onChange={handleHeatObjectChange}
                        />
                      </Grid>

                      {/* heat zip code */}
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          type="number"
                          id="heat-zip-code"
                          placeholder="Heating Contractor Zipcode"
                          value={heatZip}
                          name="zip"
                          sx={applicationFormTitleField}
                          onChange={handleHeatObjectChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 3 }}>
                      <Button
                        sx={{
                          ...saveButton,
                          backgroundColor: contractorLoader
                            ? "grey"
                            : "#d52027",
                        }}
                        onClick={() => {
                          handleHVACContractorSubmit();
                        }}
                      >
                        Save Contractor
                        {contractorLoader ? <CircularProgressLoader /> : ""}
                      </Button>
                      <Button
                        sx={cancelButton}
                        onClick={() => {
                          setHeatObject({});
                          setShowHVACForm(false);
                          setShowHVACTable(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Box>
                )}
              </>
            ))}

          {/* Applicant inormation info box */}
          <Box component="div" sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
            >
              Applicant Information
            </Typography>
            <Grid container spacing={2} sx={{ my: "5px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                  sx={textfieldDropdownStyle}
                  id="grouped-demo"
                  options={personSubmittingApplication}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Person Submitting Application"
                      error={!personSubmittingApplicationName && submitPressed}
                      helperText={
                        !personSubmittingApplicationName && submitPressed
                          ? "Person submitting application is required"
                          : ""
                      }
                    />
                  )}
                  onChange={(event, value) => {
                    setPersonSubmittingApplicationName(value.id);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={!applicationFormObject.applicantName && submitPressed}
                  helperText={
                    !applicationFormObject.applicantName && submitPressed
                      ? "Applicant name is required"
                      : ""
                  }
                  id="applicant-name"
                  placeholder="Applicant Name"
                  name="applicantName"
                  value={applicantName}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="applicant-email"
                  placeholder="Email of Applicant/Company"
                  name="applicantEmail"
                  value={applicantEmail}
                  sx={applicationFormTitleField}
                  onChange={handleChange}
                  type="email"
                  error={
                    !!applicantEmailError ||
                    (!applicationFormObject?.applicantEmail && submitPressed)
                  }
                  helperText={
                    (applicantEmailError && applicantEmailError) ||
                    (!applicationFormObject?.applicantEmail && submitPressed)
                      ? "Please enter a valid email address"
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Box>

          {/* Files or documents info box */}
          <Box component="div" sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
            >
              Files/Documents
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
            >
              Please upload any documents or files related to this project.
            </Typography>

            <Grid container spacing={2} sx={{ my: "5px" }}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <div style={{ width: "100%" }}>
                  <label htmlFor="fileInput">
                    <div
                      onDrop={onDropHandler}
                      onDragOver={(e) => e.preventDefault()}
                      style={{
                        border: "2px dashed #666",
                        borderRadius: "5px",
                        padding: "20px",
                        textAlign: "center",
                        color: "#666",
                        cursor: "pointer",
                        margin: "10px",
                        width: "100%",
                      }}
                    >
                      {previewImages.length < 1 ? (
                        <p>
                          Drag &ldquo;n&ldquo; drop some files here, or click to
                          select files
                        </p>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "center",
                            flexDirection: "row",
                            gap: "60px",
                            margin: "0 auto",
                            width: "100%",
                            flexWrap: "wrap",
                          }}
                        >
                          {previewImages.map((preview, index) => (
                            <>
                              <div style={{ position: "relative" }} key={index}>
                                <span
                                  style={{
                                    position: "absolute",
                                    top: -10,
                                    right: -10,
                                  }}
                                >
                                  <CloseIcon
                                    onClick={() => {
                                      setDisableFileInput(true);
                                      let previewsTemp = [...previewImages];
                                      let selectedMediaTemp = [
                                        ...selectedMedia,
                                      ];
                                      previewsTemp.splice(index, 1);
                                      selectedMediaTemp.splice(index, 1);
                                      setSelectedMedia(selectedMediaTemp);
                                      setPreviewImages(previewsTemp);
                                      setTimeout(() => {
                                        setDisableFileInput(false);
                                      }, 1000);
                                    }}
                                  />
                                </span>
                                <img
                                  title={preview.name}
                                  src={preview.url}
                                  alt="preview"
                                  style={{
                                    width: "160px",
                                    height: "140px",
                                    borderRadius: "10px",
                                  }}
                                />
                                <p title={preview.name}>
                                  {/* {getFilePreviewName(preview.url)} */}
                                  {getShortFileName(preview.name)}
                                </p>
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </Grid>
            </Grid>

            <input
              disabled={disableFileInput}
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={(event) => {
                handleSelectFile(event.target.files[0]);
                event.target.value = "";
              }}
            />
          </Box>

          {/* Files or documents info box */}
          <Box component="div" sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{ color: "#414042", fontWeight: 300, fontSize: "15px" }}
            >
              I understand that I am subject to all applicable codes, statutes
              and ordinances and with the conditions of this permit; understand
              that the issuance of the permit creates no legal liability,
              express or implied, on the state or municipality; and certify that
              all the above information is accurate. If one acre or more of soil
              will be disturbed, I understand that this project is subject to
              CHP NR 151 regarding additional erosion control and stormwater
              management. I expressly grant the building inspector, or the
              inspector&ldquo;s authorized agent, permission to enter the
              premises for which this permit is sought at all reasonable hours
              and for any proper purpose to inspect the work which is being
              done.
            </Typography>
          </Box>

          {/* Submit Button box */}
          <Box component="div" sx={{ marginTop: "20px" }}>
            <Button
              disabled={loader || submitButtonDisabled}
              sx={applicationFormSubmitButton}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Index;
