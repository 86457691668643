import React, { useEffect } from "react";
// import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { RightSidebarData } from "./routesList";
import { sidebarDrawer } from "../utils/muiComponentStyles";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import RightSideSubMenu from "./RightSideSubMenu";
import { useDispatch, useSelector } from "react-redux";
import { getApplicantStatus } from "../redux/slices/dashboard/applicantsSlice";

const drawerWidth = 240;

const useStyles = makeStyles({
  drawer: {
    width: "255px",
    justifyContent: "center",
  },
  drawerSmall: {
    width: "0px",
    justifyContent: "center",
  },
});

function RightSidebar() {
  const dispatch = useDispatch();
  const testClasses = useStyles();
  const [open, setOpen] = React.useState(true);
  const [sideNavLinks, setSideNavLinks] = React.useState([...RightSidebarData]);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("md"));
  const drawerClass =
    isMatch && open ? testClasses.drawer : testClasses.drawerSmall;

  const selectedApplicationInfo = JSON.parse(
    localStorage.getItem("selectedApplication")
  );

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (selectedApplicationInfo) {
      dispatch(getApplicantStatus(selectedApplicationInfo.id));
    }
  }, []);
  const { applicationStatus } = useSelector((state) => state.applicantsReducer);

  useEffect(() => {
    if (applicationStatus.data) {
      let tempSideNavLinks = [...sideNavLinks];
      for (let i = 0; i < tempSideNavLinks?.length; i++) {
        if (
          (tempSideNavLinks[i].path == "/applications/record-inspections" &&
            applicationStatus?.data?.recordedInspection) ||
          (tempSideNavLinks[i].path == "/applications/media" &&
            applicationStatus?.data?.media) ||
          (tempSideNavLinks[i].path == "/applications/building-placard" &&
            applicationStatus?.data?.buildingPlacard) ||
          (tempSideNavLinks[i].path == "/applications/occupency-letter" &&
            applicationStatus?.data?.occupancyLetter) ||
          (tempSideNavLinks[i].path == "/applications/permit-fee" &&
            applicationStatus?.data?.permitFee) ||
          (tempSideNavLinks[i].path == "/applications/payment-mode" &&
            applicationStatus?.data?.payment) ||
          tempSideNavLinks[i].path == "/applications/application-detail"
        ) {
          tempSideNavLinks[i].color = "green";
        } else {
          tempSideNavLinks[i].color = "#040F25";
        }
      }
      setSideNavLinks(tempSideNavLinks);
    }
  }, [applicationStatus]);

  return (
    <Box sx={sidebarDrawer}>
      <nav className={drawerClass}>
        <Drawer
          sx={{
            width: open ? drawerWidth : 0,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : 0,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="right"
          open={open}
        >
          <IconButton
            size="small"
            onClick={handleDrawerClose}
            sx={{
              position: "fixed",
              top: "75px",
              right: open ? "235px" : "10px",
              backgroundColor: "white",
              borderRadius: "10px",
              border: "1px solid black",
              "&:hover": {
                backgroundColor: "white",
                border: "1px solid #deded",
              },
            }}
          >
            {open ? (
              <ChevronRight fontSize="10px" />
            ) : (
              <ChevronLeft fontSize="10px" />
            )}
          </IconButton>
          <List sx={sidebarDrawer}>
            {sideNavLinks.map((obj, index) => (
              <RightSideSubMenu sidebarData={obj} key={index} />
            ))}
          </List>
        </Drawer>
      </nav>
    </Box>
  );
}

export default RightSidebar;
