import React from "react";
import { TableRow, createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Dialog, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";

export const leftbarStyles = makeStyles({
  drawer: {
    width: "265px",
  },
  drawerPaper: {
    width: "265px",
    color: "white",
    backgroundColor: "red",
  },
  root: {
    display: "flex",
    marginTop: "70px",
    // background: "#F4F8FB",
  },
});

const topbarHeight = "70px";
export const theme = createTheme({
  typography: {
    sidebarLogoHeading: {
      fontFamily: "Poppins",
      color: "white",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "50px",
    },
    sidebarHeading: {
      fontFamily: "Poppins",
      color: "white",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "50px",
    },
    topbarHeading: {
      fontFamily: "Poppins",
      height: "138px",
      width: "160px",
      left: "98px",
      top: "21.01px",
      borderRadius: "0px",
    },
    button: {
      // fontStyle: "italic",
    },
  },
});

//Second Styles - Component Style method
export const layoutChildrenBox = {
  display: "flex",
  overflow: "hidden",
  justifyContent: "center",
  width: "100%",
  paddingTop: "30px",
  paddingBottom: "5px",
  paddingRight: "100px",
  paddingLeft: "100px",
  [`@media screen and (max-width: 1920px)`]: {
    paddingRight: "30px",
    paddingLeft: "30px",
  },
};

export const appbarTabs = {
  "&:hover": {
    color: "pink",
  },
};

export const appbar = {
  "&.MuiAppBar-root": {
    backgroundColor: "#FFFFFF",
    height: topbarHeight,
    justifyContent: "center",
    boxShadow: 0.5,
  },
};

export const sidebarListItemButton = {
  "&.MuiListItemButton-root": {
    textDecoration: "none",
    borderRadius: "10px",
    width: 200,
    maxHeight: 50,
    padding: 1,
    margin: "2px",
    marginLeft: "10px",
    marginRight: "10px",
    color: "#040F25",
    fontFamily: "Poppins",
  },

  "&:hover": {
    backgroundColor: "background.bgSidebarHover",
    borderRadius: "10px",
    transition: "background 600ms linear",
    // margin: "2px"
  },

  "&.active": {
    fontWeight: 1000,
    backgroundColor: "background.bgLayoutB",
    transition: "background 600ms linear",
    fontFamily: "Poppins-SemiBold",
    color: "white",
    "& svg": {
      color: "white !important",
    },
  },
};

export const sidebarListItemButton_Roles = {
  "&.MuiListItemButton-root": {
    textDecoration: "none",
    borderRadius: "10px",
    width: 200,
    maxHeight: 50,
    padding: 1,
    margin: "2px",
    marginLeft: "10px",
    marginRight: "10px",
    color: "#040F25",
    fontFamily: "Poppins",
  },
  "&:hover": {
    backgroundColor: "background.bgSidebarHover",
    borderRadius: "10px",
    transition: "background 600ms linear",
    // margin: "2px"
  },
};

export const sidebarDrawerResponsive = {
  "& .MuiDrawer-paper": {
    postion: "fixed",
    background: "#FFFFFF",
    border: "none",
    color: "#FFFFFF",
    boxSizing: "border-box",
    textTransform: "capitalize",
    textDecoration: "none",
    overflow: "auto",
    [`::-webkit-scrollbar`]: {
      width: 0 /* Remove scrollbar space */,
      background: "transparent" /* Optional: just make scrollbar invisible */,
    },
  },
  "&.MuiListItemText-root": {
    fontFamily: "Poppins",
    fontSize: "100px !important",
    fontWeight: 400,
    lineHeight: "50px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  "&.MuiBox-root": { margin: "10px" },
};

export const topbarLogo = {
  "&.MuiBox-root": {
    textAlign: "center",
    fontSize: "18px",
    // background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "30px",
    // [`@media screen and (max-width: 900px)`]: {
    //   height: "49px",
    //   width: "160px",
    //   // marginRight: "30px",
    // },
    // [`@media screen and (max-width: 500px)`]: {
    //   height: "35px",
    //   width: "100px",
    //   marginRight: "10px",
    // },
  },
};

export const drawerResponsiveButton = {
  "&.MuiIconButton-root": {
    color: "#000000",
    marginRight: "10px",
    [`@media screen and (max-width: 500px)`]: {
      marginRight: "0px",
    },
  },
};

export const topbarHeading = {
  "&.MuiBox-root": {
    display: "flex",
    height: "38px",
    width: "420px",
    borderRadius: "0px",
    [`@media screen and (max-width: 900px)`]: {
      display: { xs: "none", sm: "none" },
    },
  },
  "&.MuiTypography-topHeading": {
    color: "#000000",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "25px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-topHeadingSmall": {
    color: "#000000",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "22px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-topHeadingVerySmall": {
    color: "#000000",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "16px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-busyModeHeading": {
    color: "#000000",
    fontFamily: "Poppins",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalHeadingBold": {
    color: "#000000",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalHeadingBold_Button": {
    color: "#000000",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalList": {
    color: "#7D8EA3",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const topbarDropdownSelect = {
  "&.MuiSelect-select": {
    color: "white",
    ".MuiSvgIcon-root ": {
      fill: "#040F25 ",
    },
    "&:before": {
      borderBottom: "2px solid white",
    },
    "&:after": {
      borderBottom: "2px solid white",
    },
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      borderBottom: `2px solid purple`,
    },
  },
};

export const mainLayoutBox = {
  "&.MuiBox-root": {
    display: "block",
    background: "#F4F8FB",
    height: "auto",
    [`@media screen and (max-width: 900px)`]: {
      height: "auto",
    },
  },
};

export const dashboardHeading = {
  "&.MuiTypography-dashboardHeading": {
    color: "primary.dashboardWelcomeHeading",
    fontFamily: "Poppins",
    fontSize: "25px",
    lineHeight: "37.5px",
    textTransform: "capitalize",
  },

  "&.MuiTypography-dashboardHeadingBold": {
    color: "primary.dashboardWelcomeHeading",
    fontFamily: "Poppins-Bold",
    marginLeft: "10px",
    fontSize: "25px",
    lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardInnerHeading": {
    color: "#040F25",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
    ["@media screen and (max-width: 768px)"]: {
      fontSize: "18px",
      textWrap: "noWrap",
    },
  },
  "&.MuiTypography-dashboardCounter": {
    color: "#040F25",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

//Topbar
export const topbarMainBox = {
  "&.MuiBox-root": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
};
export const topbarBackButton = { color: "#040F25", marginLeft: "30px" };
export const topbarSecondaryBox = {
  "&.MuiBox-root": {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
};
export const topbarSecondaryBoxFormControl = {
  m: 1,
  minWidth: { xs: 50, sm: 120, md: 120, lg: 320 },
  border: "none",
  marginBottom: "20px",
};
export const topbarSecondaryBoxSelect = {
  color: "white",
  textAlign: "left",
  ".MuiSvgIcon-root ": {
    fill: "white ",
  },
  "&:before": {
    borderBottom: "2px solid white",
  },
  "&:after": {
    borderBottom: "2px solid white",
  },
  "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
    borderBottom: `2px solid grey`,
  },
};
export const miniScrollBarStyles = {
  [`::-webkit-scrollbar`]: {
    width: "10px",
    height: "10px",
    backgroundColor: "white",
  },
  [`::-webkit-scrollbar-track `]: {
    // [`-webkit-box-shadow`]: "inset 0 0 6px rgba(0,0,0,0.3)",
    borderRadius: "10px",
    backgroundColor: "#F5F5F5",
  },
  [`::-webkit-scrollbar-thumb`]: {
    borderRadius: "10px",
    // [`-webkit-box-shadow`]: "inset 0 0 6px rgba(0,0,0,.3)",
    backgroundColor: "#cbcbcb",
  },
};
export const topbarSecondaryBoxTooltipIconButton = {
  justifyContent: "flex-end",
};
export const topbarSecondaryBoxTooltipAvatar = {
  width: 32,
  height: 32,
  color: "white",
};

//ResponsiveSidebar
export const responsiveSidebarListBg = { background: "inherit" };
export const responsiveSidebarListItemIcon = {
  color: "#040F25",
  minWidth: "35px",
};

//Sidebar SubMenu
export const sidebarSubMenuListItemIcon = {
  color: "#040F25",
  minWidth: "35px",
  "&.active": {
    "& svg": {
      color: "green",
    },
  },
};
export const sidebarSubMenuListItemIconWithPadding = {
  color: "#040F25",
  minWidth: "35px",
  pb: "2px",
};
export const sidebarSubMenuTypography = {
  display: "flex",
  justifyContent: "flex-end",
  // width: "100%",/
};

export const textfieldStyle = {
  width: { xs: "200px", sm: "260px", md: "300px" },
  input: {
    color: "#7D8EA3",
    borderRadius: "10px",
    background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "#7D8EA3",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    color: "#7D8EA3",
    background: "white",
    borderRadius: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};

export const themeIconButton = {
  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
  marginRight: { lg: "10px", md: "0px" },
};

// table body styles

export const tableBodyTypos = {
  "&.MuiTypography-tableBodyBoldButton": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-tableBodyBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-tableBodyCellName": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.675px",
  },
  "&.MuiTypography-tableBodyCellNameAccessKey": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    textTransform: "uppercase",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.675px",
  },
  "&.MuiTypography-tableBodyCellNameLicenseNumber": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    textTransform: "uppercase",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.675px",
  },

  "&.MuiTypography-autoCompleteTypo": {
    fontFamily: "Poppins",
    fontSize: "15px",
    color: "#7D8EA3",
    textTransform: "capitalize",
  },
};

// *****************************************styling by imran*******************************************************

// for the all text fields

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // getContentAnchorEl: null,
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const textfield = {
  "&.MuiFormControl-root": {
    width: "100%",
    "& .MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    "& .MuiInputBase-root": {
      fontFamily: "Poppins",
      fontSize: "15px",
      color: "#404041",
      borderRadius: "8px",
    },
    "& label": { color: "#404041" },
  },

  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
};

export const textfieldAccessKey = {
  "&.MuiFormControl-root": {
    width: "100%",
    "& .MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    "& .MuiInputBase-root": {
      fontFamily: "Poppins",
      fontSize: "15px",
      color: "#404041",
      borderRadius: "8px",
    },
    "& .MuiInputBase-input": { textTransform: "uppercase" },
    "& label": { color: "#404041" },
  },

  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
};
export const textfieldDate = {
  "& .MuiFilledInput-root": {
    backgroundColor: "white",
    width: "100%",
    ".MuiFilledInput-input": {
      border: "1px solid lightgrey",
      borderRadius: "8px",
      width: "100%",
      height: "20px",
    },
    "& fieldset": {
      borderColor: "white",
      width: "100%",
    },
    "&:hover fieldset": {
      borderColor: "white",
      borderRadius: "8px",
      border: "1px solid lightgrey",
    },
    "&.Mui-focused": {
      "& .MuiFilledInput-input": {
        border: "1px solid #D52027",
      },
      backgroundColor: "white",
      outline: "none",
    },
    "&:hover": {
      borderColor: "black",
      borderRadius: "8px",
      border: ".2px solid black",
      outline: "none",
      backgroundColor: "transparent",
    },
  },
};

export const checkBox = {
  "&.MuiFormControlLabel-root": {
    ".MuiFormControlLabel-label": {
      fontFamily: "Poppins-semibold",
      fontStyle: "normal",
      fontSize: "16px",
      color: "#6D7B88",
      display: "flex",
    },
  },
};

export const autoCompleteStyle = {
  width: "100%",
  "& .MuiInputLabel-root": {
    color: "inherit",
  },
  "& .MuiAutocomplete-tag": {
    backgroundColor: "#f1f1f1",
    borderRadius: "12px",
    margin: "6px 4px 6px 0",
    fontFamily: "Poppins",
  },
  "& .MuiAutocomplete-tagSizeSmall": {
    fontSize: "0.75rem",
  },
  "& .MuiOutlinedInput-root": {
    height: "38px",
    borderRadius: 2,
    backgroundColor: "background.bgSearchBar",
    fontFamily: "Poppins",
  },
};

export const tableLoaderBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const rolesTableChip = {
  margin: "2px",
  color: "white",
  fontSize: "12px",
  cursor: "default",
  padding: "4px 12px",
  borderRadius: "50px",
  fontFamily: "Poppins",
  display: "inline-block",
  textTransform: "capitalize",
  backgroundColor: "background.bgTableChip",
  textAlign: "center",
};

export const styledChip = {
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#404041",
  textTransform: "capitalize",
};

export const userHeading = {
  "&.MuiTypography-userHeadingBoldButton": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-userTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-userTableCellName": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.675px",
  },
  "&.MuiTypography-userTableCellPermitFee": {
    // color: "primary.tableFontColor",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.675px",
  },
  "&.MuiTypography-userTableCellDescription": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.675px",
  },
  "&.MuiTypography-userRoleTableCellName": {
    textTransform: "capitalize",
    height: "20px",
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  "&.MuiTypography-autoCompleteTypo": {
    fontFamily: "Poppins",
    fontSize: "15px",
    color: "#404041",
    textTransform: "capitalize",
  },
};

export const StyledTableRow = styled(TableRow)((props) => ({
  "&:nth-of-type(odd)": {
    background: props.theme.palette.background.bgTableOddRow,
  },
  "&:nth-of-type(even)": {
    background: props.theme.palette.background.bgTableEvenRow,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    background: props.theme.palette.background.bgTableRowHover,
  },
  height: "20px",
}));

export const styledTableCell = {
  padding: "15px",
  borderBottom: "none",
  height: "10px ",
};
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "background.bgLayoutA",
        color: "white",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const dialogCancelButton = {
  background: "black",
  color: "white",
  fontFamily: "Poppins-semibold",
  fontSize: "15px",
  borderRadius: "10px",
  "&:hover": { backgroundColor: "black", boxShadow: 2 },
};
export const dialogDeleteButton = {
  backgroundColor: "background.bgLayoutB",
  borderRadius: "10px",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};

export const rightSidebarListItemButton_Roles = {
  "&.MuiListItemButton-root": {
    textDecoration: "none",
    // borderRadius: "10px",
    width: 200,
    maxHeight: 50,
    padding: 1,
    margin: "2px",
    marginLeft: "10px",
    marginRight: "10px",
    color: "red",
    fontFamily: "Poppins",
  },
  "&:hover": {
    // backgroundColor: "background.bgSidebarHover",
    borderRadius: "10px",
    transition: "background 600ms linear",
    // margin: "2px"
  },
  // "&.MuiListItemButton-root:hover": {
  //   background: "#0F69BE",
  //   borderRadius: "10px",
  //   marginLeft: "10px",
  //   marginRight: "10px",
  //   // margin: "2px"
  // },
  // "&.active": {
  //   fontWeight: 1000,
  //   background: "#D52027",
  //   transition: "background 600ms linear",
  //   fontFamily: "Poppins-SemiBold",
  // },
};
export const rightSidebarListItemButton = {
  "&.MuiListItemButton-root": {
    textDecoration: "none",
    borderRadius: "10px",
    width: 200,
    maxHeight: 50,
    padding: 1,
    margin: "2px",
    marginLeft: "10px",
    marginRight: "10px",
    color: "#040F25",
    fontFamily: "Poppins",
  },
  "&:hover": {
    // backgroundColor: "background.bgSidebarHover",
    borderRadius: "10px",
    // transition: "background 600ms linear",
    // margin: "2px"
  },
  // "&.MuiListItemButton-root:hover": {
  //   background: "#0F69BE",
  //   borderRadius: "10px",
  //   marginLeft: "10px",
  //   marginRight: "10px",
  //   // margin: "2px"
  // },
  "&.active": {
    fontWeight: 1000,
    // backgroundColor: "background.bgLayoutB",
    // transition: "background 600ms linear",
    fontFamily: "Poppins-SemiBold",
    color: "background.bgLayoutB",
  },
};
export const sidebarDrawer = {
  "& .MuiDrawer-paper": {
    postion: "fixed",
    display: { xs: "none", sm: "none", md: "block" },
    marginTop: topbarHeight,
    backgroundColor: "#FFFFFF",
    border: "none",
    // color: "white",
    // width: 255,
    boxSizing: "border-box",
    textTransform: "capitalize",
    textDecoration: "none",
    overflow: "auto",
    [`::-webkit-scrollbar`]: {
      width: 0 /* Remove scrollbar space */,
      background: "transparent" /* Optional: just make scrollbar invisible */,
    },
  },
  "&.MuiListItemText-root": {
    fontFamily: "Poppins",
    fontSize: "100px !important",
    fontWeight: 400,
    lineHeight: "50px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#040F25",
  },
  "&.MuiTypography-sidebarTypography": {
    // color: "#040F25",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "50px",
    width: "100%",
  },
  "&.MuiTypography-rightSidebarTypography": {
    // color: "#040F25",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "50px",
  },
  "&.MuiList-root": {
    mt: "15px",
    mb: "70px",
  },
  "&.MuiBox-root": { display: "flex" },
};

// ############################################   Record Inspection styling   ############################################################
export const recordInspectionTypo = {
  "&.MuiTypography-root": {
    fontSize: "20px",
    fontFamily: "Poppins-Bold",
    letterSpacing: "1.26px",
    lineHeight: "37.5px",
  },
  "&.MuiTypography-subText": {
    fontSize: "16px",
    fontFamily: "Poppins-Light",
    letterSpacing: "0.68px",
    lineHeight: "24px",
  },
};
export const applicationCardStyle = {
  backgroundColor: "#040F25",
  color: "white",
  fontSize: "20px !important",
  fontFamily: "Poppins-semibold",
  borderRadius: "10px 10px 0px 0px",
  height: "68px",
};

export const downloadMediaButton = {
  backgroundColor: "#040F25",
  positon: "relative",
  color: "white",
  fontSize: "16px",
  fontFamily: "Poppins-Light",
  letterSpacing: "0.68px",
  lineHeight: "24px",
  border: "none",
  textTransform: "lowercase",
  "&:hover": {
    backgroundColor: "#040F25",
    color: "white",
    border: "none",
    boxShadow: 2,
  },
};
export const downloadButtonWrapper = {
  width: "100%",
  padding: "4px",
  borderRadius: 2,
  position: "relative",
  bottom: "18px",
};
export const downloadButtonWrapperVideo = {
  width: "327",
  padding: "4px",
  borderRadius: 2,
  position: "absolute",
  bottom: "18px",
};
export const listMediaInnerWrapper = {
  overflowX: "auto",
  display: "flex",
  gap: 3,
  borderRadius: 2,
  [`::-webkit-scrollbar`]: {
    width: "10px",
    height: "10px",
    backgroundColor: "white",
  },
  [`::-webkit-scrollbar-track `]: {
    // [`-webkit-box-shadow`]: "inset 0 0 6px rgba(0,0,0,0.3)",
    borderRadius: "10px",
    backgroundColor: "#F5F5F5",
  },
  [`::-webkit-scrollbar-thumb`]: {
    borderRadius: "10px",
    // [`-webkit-box-shadow`]: "inset 0 0 6px rgba(0,0,0,.3)",
    backgroundColor: "#cbcbcb",
  },
};

export const removeButton = {
  position: "absolute",
  top: 8,
  right: 6,
  background: "transparent",
  border: "none",
  cursor: "pointer",
};

export const closeIconButton = {
  backgroundColor: "#747474",
  color: "white",
  "&:hover": {
    backgroundColor: "#747474",
    color: "white",
  },
};

// ** Cautionary page styling

export const mainHeading = {
  fontFamily: "Poppins-Medium",
  fontSize: 40,
  textAlign: "center",
  my: 4,
};

export const subCautionaryHeading = {
  fontFamily: "Poppins-Bold",
  fontSize: 20,
  my: 2,
  borderBottom: "1px solid black",
  display: "inline-block",
};
