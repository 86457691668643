import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  status: "All",
  paymentStatus: "",
  startDate: "",
  endDate: "",
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    resetFilters: () => initialState,
  },
});

export const {
  setSearchTerm,
  setStatus,
  setPaymentStatus,
  setStartDate,
  setEndDate,
  resetFilters,
} = filterSlice.actions;

export default filterSlice.reducer;
