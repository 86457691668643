import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { pdfBox, recieptBox, titleTypography } from "./EmailBodyStyle";
import PdfPreview from "../../../../assets/application-media-previews/wipermit-pdf-thumbnail.png";

const EmailBody = ({ bodyData }) => {
  let selectedApplication = JSON.parse(
    localStorage.getItem("selectedApplication")
  );
  const [emailData, setEmailData] = useState();
  const [showEmailData, setShowEmailData] = useState(false);
  const [previewImages, setPreviewImages] = useState();

  useEffect(() => {
    if (bodyData === null || bodyData === undefined) {
      setShowEmailData(false);
    } else {
      setEmailData(bodyData);
      setShowEmailData(true);
    }
    setPreviewImages(bodyData?.link);
  }, [bodyData]);

  return (
    showEmailData && (
      <Box component="div">
        <Box component="div">
          <Typography sx={titleTypography}>{emailData?.purpose}</Typography>
        </Box>
        <Box component="div" sx={recieptBox}>
          <Avatar sx={{ textTransform: "uppercase" }}>
            {selectedApplication?.applicantName.slice(0, 1)}
          </Avatar>
          {selectedApplication?.applicantName}
        </Box>

        {emailData?.message && (
          <Box
            component="div"
            sx={{
              // ...pdfBox,
              marginTop: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            {/* {emailData?.message} */}
            <div dangerouslySetInnerHTML={{ __html: emailData?.message }}></div>
          </Box>
        )}
        {emailData.link && (
          <Box component="div" sx={{ ...pdfBox, position: "relative" }}>
            <a href={previewImages} download>
              <img
                src={PdfPreview}
                alt="preview"
                width="150px"
                height="150px"
              />
              <Typography
                sx={{
                  position: "absolute",
                  bottom: 15,
                  backgroundColor: "black",
                  color: "white",
                  fontFamily: "Poppins-semibold",
                  fontSize: "12px",
                  textAlign: "center",
                  // left: 10,
                }}
              >
                {previewImages?.split("/").pop()}
              </Typography>
            </a>
            {/* {previewImages} */}
          </Box>
        )}
      </Box>
    )
  );
};

export default EmailBody;
