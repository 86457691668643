import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Box,
  Button,
  IconButton,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import {
  DeleteObjectCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import {
  closeIconButton,
  downloadButtonWrapper,
  downloadMediaButton,
  listMediaInnerWrapper,
  recordInspectionTypo,
  removeButton,
  textfield,
  textfieldDate,
} from "../../../utils/muiComponentStyles";
import {
  getAllInspections,
  updateRecordInspection,
  // getAllInspections,
} from "../../../redux/slices/recordedInspectionsSlice";
import { dispatch } from "../../../redux/store";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import ToasterContext from "../../../utils/context/toasterContext";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  getApplicantStatus,
  getSingleApplicationData,
  uploadRecordInspectionMedia,
} from "../../../redux/slices/dashboard/applicantsSlice";
import PdfPreview from "./../../../assets/application-media-previews/wipermit-pdf-thumbnail.png";
import DocsPreview from "./../../../assets/application-media-previews/wipermit-docs-thumbnail.jpg";
import PowerPointPreview from "./../../../assets/application-media-previews/wipermit-powerpoint-thumbnail.jpg";
import ExcelPreview from "./../../../assets/application-media-previews/wipermit-xls-thumbnail.jpg";
import imageCompression from "browser-image-compression";
import { compressionOption } from "../../../utils/imageCompression";
import getShortFileName from "../../../utils/fileName";

const inspectionStatus = [
  { id: 1, name: "Completed" },
  { id: 2, name: "Failed" },
  { id: 3, name: "Approved with Conditions noted" },
];

const inspectionPerformed = [
  { id: 1, name: "Footing" },
  { id: 2, name: "Foundation" },
  { id: 3, name: "Drain tile" },
  { id: 4, name: "Foundation water proofing" },
  { id: 5, name: "Foundation Insulation" },
  { id: 6, name: "Erosion Control" },
  { id: 7, name: "Rough Construction" },
  { id: 8, name: "Final Construction" },
  { id: 9, name: "Rough Electrical" },
  { id: 10, name: "Final Electrical" },
  { id: 11, name: "Underground Electrical" },
  { id: 12, name: "Electrical Service" },
  { id: 13, name: "Rough HVAC" },
  { id: 14, name: "Final HVAC" },
  { id: 15, name: "Final Plumbing" },
  { id: 16, name: "Rough Plumbing" },
  { id: 17, name: "Underground Plumbing" },
  { id: 18, name: "Plumbing Sewer Lateral" },
  { id: 19, name: "Building Insulation" },
  { id: 20, name: "Vapor Barrier" },
  { id: 21, name: "Final Occupancy Inspection" },
  { id: 22, name: "Temporary Occupancy Inspection" },
  { id: 23, name: "Gas Inspection" },
  { id: 24, name: "Other See Notes" },
];

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});

const Index = ({
  selectedRecord,
  manipulationPermission,
  handleRecordInspection,
}) => {
  const matches = useMediaQuery("(max-width:600px)");
  let applicationData = JSON.parse(localStorage.getItem("selectedApplication"));
  const [recordInspectionDetails, setRecordInspectionDetails] =
    useState(selectedRecord);
  const [loading, setLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [imagesToBeDeleted, setImagesToBeDeleted] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [buttonSubmit, setButtonSubmit] = useState(false);
  const fireToasterContext = useContext(ToasterContext);
  const [projectAddress, setProjectAddress] = useState(
    selectedRecord.application.projectAddress
  );

  useEffect(() => {
    if (selectedRecord?.media?.length > 0) {
      setPreviewImages(
        selectedRecord.media.map((file) => {
          if (file?.type === "Docs") {
            return {
              url: DocsPreview,
              type: file.type,
              urlDownload: file.url,
            };
          } else if (file?.type === "Excel") {
            return {
              url: ExcelPreview,
              type: file.type,
              urlDownload: file.url,
            };
          } else if (file?.type === "Powerpoint") {
            return {
              url: PowerPointPreview,
              type: file.type,
              urlDownload: file.url,
            };
          } else if (file?.type === "Pdf") {
            return {
              url: PdfPreview,
              type: file.type,
              urlDownload: file.url,
            };
          } else if (file?.type === "Image") {
            return {
              url: file.url,
              type: file.type,
              urlDownload: file.url,
            };
          } else if (file?.type === "Video") {
            return {
              url: file.url,
              type: file.type,
              urlDownload: file.url,
            };
          }
        })
      );
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRecordInspectionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const onDropHandler = (ev) => {
    ev.preventDefault();

    let files = [];

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          const file = ev.dataTransfer.items[i].getAsFile();
          if (file) files.push(file); // Add each file to the array
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      files = Array.from(ev.dataTransfer.files); // Get all files
    }

    if (files.length > 0) {
      handleSelectFiles(files); // Pass all files to handleSelectFiles
    }
  };

  const handleAddPhotoClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSelectFiles = async (files) => {
    if (files && files.length) {
      let tempPreviewImages = [...previewImages];
      let tempSelectedMedia = [...selectedMedia];

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let fileExtension = checkFileExt(file.name).toLowerCase();

        if (["png", "jpg", "jpeg", "webp"].includes(fileExtension)) {
          const compressedFile = await imageCompression(
            file,
            compressionOption
          );
          const imageUrl = URL.createObjectURL(compressedFile);

          tempPreviewImages.push({
            type: "Image",
            url: imageUrl,
            name: file.name,
          });
          tempSelectedMedia.push({
            file: compressedFile,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Image",
            name: file.name,
          });
        } else if (["docx", "doc", "txt"].includes(fileExtension)) {
          tempPreviewImages.push({
            type: "Docs",
            url: DocsPreview,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Docs",
          });
        } else if (["csv", "xls", "xlsx"].includes(fileExtension)) {
          tempPreviewImages.push({
            type: "Excel",
            url: ExcelPreview,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Excel",
          });
        } else if (fileExtension === "pdf") {
          tempPreviewImages.push({
            type: "Pdf",
            url: PdfPreview,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Pdf",
          });
        } else if (["pptx", "ppt"].includes(fileExtension)) {
          tempPreviewImages.push({
            type: "Powerpoint",
            url: PowerPointPreview,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Powerpoint",
          });
        } else if (
          ["avi", "mov", "wmv", "mkv", "flv", "mp4"].includes(fileExtension)
        ) {
          const videoUrl = URL.createObjectURL(file);

          tempPreviewImages.push({
            type: "Video",
            url: videoUrl,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Video",
          });
        } else {
          fireToasterContext.fireToasterHandler(false, "Invalid File");
        }
      }

      setPreviewImages(tempPreviewImages);
      setSelectedMedia(tempSelectedMedia);
    }
  };

  const removeObjectsFromSpaces = async () => {
    for (let i = 0; i < imagesToBeDeleted.length; i++) {
      const deleteParams = {
        Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
        Key: imagesToBeDeleted[i],
      };
      const command = new DeleteObjectCommand(deleteParams);
      s3Client.send(command);
    }
  };

  // S3 bucket media uploaded response
  const handleUploadMediaResponse = async (data) => {
    if (data.status) {
      removeObjectsFromSpaces();
      fireToasterContext.fireToasterHandler(
        true,
        "Recorded inspection updated successfully"
      );
      setLoading(false);
      await dispatch(getAllInspections());
      handleRecordInspection();
      dispatch(getApplicantStatus(applicationData.id));
    } else {
      fireToasterContext.fireToasterHandler(false, "Something went wrong");
      setLoading(false);
    }
  };

  function checkFileExt(filename) {
    const ext = filename.split(".");
    return ext[ext.length - 1];
  }

  // Handle the upload of images to the S3 bucket.

  const handleUploadFilesToSpaces = async (inspectionId, selectedMedia) => {
    try {
      let allMediaArr = [];

      for (let i = 0; i < previewImages?.length; i++) {
        let el = previewImages[i];
        if (
          el?.type == "Image" &&
          el?.url?.toString()?.startsWith(process.env.REACT_APP_SPACES_ENDPOINT)
        ) {
          allMediaArr.push({
            url: el.url,
            type: el.type,
          });
        }
        if (
          el?.type != "Image" &&
          el?.urlDownload
            ?.toString()
            ?.startsWith(process.env.REACT_APP_SPACES_ENDPOINT)
        ) {
          allMediaArr.push({
            url: el.urlDownload,
            type: el.type,
          });
        }
      }

      // Loop through each selected media file
      for (let i = 0; i < selectedMedia.length; i++) {
        const arrayBuffer = await selectedMedia[i].file.arrayBuffer();
        const params = {
          Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
          Key: `${process.env.REACT_APP_ENVIRONMENT}/${
            applicationData.id
          }/RecordInspection/${inspectionId}/${
            selectedMedia[i].file.name.split(" ").join("-").split(".")[0]
          }-${Date.now()}.${checkFileExt(selectedMedia[i].file.name)}`,
          Body: new Uint8Array(arrayBuffer),
          ACL: "public-read",
        };
        // Upload the media file to the S3 bucket
        await s3Client.send(new PutObjectCommand(params));
        // Add the uploaded media URL to the array
        allMediaArr.push({
          url: `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`,
          type: selectedMedia[i].type,
        });
      }

      // Dispatch the uploadMedia action with the necessary parameters
      dispatch(
        uploadRecordInspectionMedia(
          inspectionId,
          allMediaArr,
          handleUploadMediaResponse
        )
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemovePreview = async (index, imageUrl) => {
    let tempImagesToBeDeleted = [...imagesToBeDeleted];
    if (imageUrl.toString().startsWith(process.env.REACT_APP_SPACES_ENDPOINT)) {
      let splittedImageUrl = imageUrl.split("/");
      splittedImageUrl.splice(0, 4);
      let keyToBeDeleted = splittedImageUrl.join("/");
      tempImagesToBeDeleted.push(keyToBeDeleted);
      setImagesToBeDeleted(tempImagesToBeDeleted);
    }
    let tempPreviewImages = [...previewImages];
    tempPreviewImages.splice(index, 1);
    setPreviewImages(tempPreviewImages);
    let tempSelectedMedia = [...selectedMedia];
    let selectedMediaIndexToBeRemoved = tempSelectedMedia?.findIndex((el) => {
      return el.fileId == index;
    });
    if (selectedMediaIndexToBeRemoved > -1) {
      tempSelectedMedia.splice(selectedMediaIndexToBeRemoved, 1);
      setSelectedMedia(tempSelectedMedia);
    }
  };

  const handleApiRes = (data) => {
    if (data.status) {
      handleUploadFilesToSpaces(data.data.id, selectedMedia);
      dispatch(getSingleApplicationData(applicationData?.id));
    } else {
      fireToasterContext.fireToasterHandler(false, "Something went wrong");
    }
  };

  const handleSave = () => {
    setButtonSubmit(true);
    if (
      recordInspectionDetails.applicationId &&
      projectAddress &&
      recordInspectionDetails.date &&
      recordInspectionDetails.time &&
      recordInspectionDetails.inspectionPerformed &&
      // recordInspectionDetails.inspectionNotes &&
      recordInspectionDetails.inspectionStatus &&
      recordInspectionDetails.inspectorName &&
      recordInspectionDetails.inspectorCredentials
    ) {
      setLoading(true);
      const finalObj = {
        id: selectedRecord.id,
        applicationId: recordInspectionDetails.applicationId,
        projectAddress: projectAddress,
        date: recordInspectionDetails.date,
        time: recordInspectionDetails.time,
        inspectionPerformed: recordInspectionDetails.inspectionPerformed,
        inspectionNotes: recordInspectionDetails.inspectionNotes,
        inspectionStatus: recordInspectionDetails.inspectionStatus,
        inspectorInformation: recordInspectionDetails.inspectorInformation,
      };

      dispatch(updateRecordInspection(finalObj, handleApiRes));
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please select all required fields"
      );
    }
  };

  return (
    <div>
      <Container sx={{ my: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Typography sx={recordInspectionTypo}>Inspection Report</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="date"
              name="date"
              type="date"
              disableUnderline
              fullWidth
              sx={textfieldDate}
              label="Inspection date"
              inputProps={{
                max: new Date().toISOString().split("T")[0],
              }}
              variant="filled"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                disableUnderline: true,
                readOnly: manipulationPermission,
              }}
              value={recordInspectionDetails.date}
              onChange={handleChange}
              error={!recordInspectionDetails.date && buttonSubmit}
              helperText={
                !recordInspectionDetails.date && buttonSubmit
                  ? "Date is Required"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="time"
              label="Time"
              name="time"
              sx={textfield}
              value={recordInspectionDetails.time}
              onChange={handleChange}
              type="time"
              error={!recordInspectionDetails.time && buttonSubmit}
              helperText={
                !recordInspectionDetails.time && buttonSubmit
                  ? "Time is Required"
                  : ""
              }
              InputProps={{ readOnly: manipulationPermission }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              readOnly={manipulationPermission}
              fullWidth
              multiple
              limitTags={5}
              name="inspectionPerformed"
              id="inspection-performed"
              options={inspectionPerformed}
              getOptionLabel={(option) => option.name}
              value={inspectionPerformed.filter((item) =>
                recordInspectionDetails.inspectionPerformed.includes(item.id)
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Inspection Performed"
                  sx={textfield}
                  error={
                    !recordInspectionDetails.inspectionPerformed && buttonSubmit
                  }
                  helperText={
                    !recordInspectionDetails.inspectionPerformed && buttonSubmit
                      ? "Please Select Record inspection Details"
                      : ""
                  }
                />
              )}
              onChange={(event, newValues) => {
                setRecordInspectionDetails((prevDetails) => ({
                  ...prevDetails,
                  inspectionPerformed: newValues.map((val) => val.id),
                }));
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputProps={{ readOnly: manipulationPermission }}
              multiline
              rows={3}
              id="inspection-notes"
              label="Inspection Notes"
              name="inspectionNotes"
              sx={textfield}
              type="text"
              value={recordInspectionDetails.inspectionNotes}
              onChange={handleChange}
              // error={!recordInspectionDetails.inspectionNotes && buttonSubmit}
              // helperText={
              //    // !recordInspectionDetails.inspectionNotes && buttonSubmit
              //     ? "Inspection Notes are Required"
              //     : ""
              // }
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              readOnly={manipulationPermission}
              fullWidth
              name="inspectionStatus"
              id="inspection-status"
              options={inspectionStatus}
              getOptionLabel={(option) => option.name}
              value={
                inspectionStatus.find(
                  (status) =>
                    status.name === recordInspectionDetails.inspectionStatus
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Inspection Status"
                  sx={textfield}
                  error={
                    !recordInspectionDetails.inspectionStatus && buttonSubmit
                  }
                  helperText={
                    !recordInspectionDetails.inspectionStatus && buttonSubmit
                      ? "Inspection Status is Required"
                      : ""
                  }
                />
              )}
              onChange={(event, newValue) => {
                setRecordInspectionDetails((prevDetails) => ({
                  ...prevDetails,
                  inspectionStatus: newValue ? newValue.name : "",
                }));
              }}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="address"
              label="Project Address"
              name="projectAddress"
              type="text"
              sx={textfield}
              value={projectAddress || ""}
              onChange={(event) => setProjectAddress(event.target.value)}
              error={!projectAddress && buttonSubmit}
              helperText={
                !projectAddress && buttonSubmit ? "Address is Required" : ""
              }
              InputProps={{ readOnly: manipulationPermission }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography sx={recordInspectionTypo}>
                Inspector Information
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="inspectorName"
              label="Inspector Name"
              name="inspectorName"
              sx={textfield}
              type="text"
              onChange={handleChange}
              value={recordInspectionDetails.inspectorName}
              error={!recordInspectionDetails.inspectorName && buttonSubmit}
              helperText={
                !recordInspectionDetails.inspectorName && buttonSubmit
                  ? "Inspector name is Required"
                  : ""
              }
              InputProps={{ readOnly: manipulationPermission }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="inspectorCredentials"
              label="State Credentials"
              name="inspectorCredentials"
              sx={textfield}
              type="text"
              onChange={handleChange}
              value={recordInspectionDetails.inspectorCredentials}
              error={
                !recordInspectionDetails.inspectorCredentials && buttonSubmit
              }
              helperText={
                !recordInspectionDetails.inspectorCredentials && buttonSubmit
                  ? "State Credentials is Required"
                  : ""
              }
              InputProps={{ readOnly: manipulationPermission }}
            />
          </Grid>
        </Grid>

        {/*  add media section */}
        <Box>
          <Box sx={{ my: 3 }}>
            <Typography sx={recordInspectionTypo}>
              Add Photo & Documents
            </Typography>
          </Box>
          <Box
            onDrop={onDropHandler}
            onDragOver={(e) => e.preventDefault()}
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: matches ? "wrap" : "nowrap",
            }}
          >
            <Box
              sx={{
                minWidth: 327,
                height: 240,
                borderRadius: 2,
                backgroundColor: "#ECECEC",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={handleAddPhotoClick}
            >
              <Typography sx={{ ...recordInspectionTypo, color: "#CBCBCB" }}>
                +Add Photo & Documents
              </Typography>
              <input
                multiple
                id="fileInput"
                disabled={manipulationPermission}
                style={{ display: "none" }}
                type="file"
                onChange={(event) =>
                  handleSelectFiles(
                    Array.from(event.target.files), // Pass all selected files
                    (document.getElementById("fileInput").value = "")
                  )
                }
              />
            </Box>
            <Box
              sx={{
                overflowX: "auto",
                display: "flex",
                gap: 3,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  flexWrap: matches ? "wrap" : "nowrap",
                  alignItems: "center",
                }}
              >
                <Box sx={listMediaInnerWrapper}>
                  {previewImages.map((media, index) => {
                    const fileName = media?.urlDownload
                      ? media?.urlDownload?.split("/").pop()
                      : media?.name?.split(" ")?.join("-");
                    return (
                      <Box
                        key={index}
                        sx={{
                          position: "relative",
                          [`::-webkit-scrollbar`]: {
                            width: "10px",
                            height: "10px",
                            backgroundColor: "white",
                          },
                          [`::-webkit-scrollbar-track `]: {
                            borderRadius: "10px",
                            backgroundColor: "#F5F5F5",
                          },
                          [`::-webkit-scrollbar-thumb`]: {
                            borderRadius: "10px",
                            backgroundColor: "#cbcbcb",
                          },
                        }}
                      >
                        {media.type === "Video" ? (
                          <video
                            style={{
                              width: 327,
                              height: 180,
                              borderRadius: "10px",
                            }}
                            controls
                          >
                            <source src={media.url} type="video/mp4" />
                          </video>
                        ) : (
                          <img
                            src={media.url}
                            alt="image preview"
                            style={{
                              width: 327,
                              height: 240,
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        {!manipulationPermission && (
                          <button
                            onClick={() =>
                              handleRemovePreview(index, media.url)
                            }
                            style={removeButton}
                          >
                            <span>
                              <IconButton size="small" sx={closeIconButton}>
                                <CloseRoundedIcon />
                              </IconButton>
                            </span>
                          </button>
                        )}

                        {(media?.urlDownload
                          ?.toString()
                          ?.startsWith(process.env.REACT_APP_SPACES_ENDPOINT) ||
                          media.name) && (
                          <a
                            href={media.urlDownload}
                            download={media.urlDownload}
                            rel="noreferrer"
                            // style={{
                            //   display: !media?.urlDownload
                            //     ?.toString()
                            //     ?.startsWith(
                            //       process.env.REACT_APP_SPACES_ENDPOINT
                            //     )
                            //     ? "none"
                            //     : "block",
                            // }}
                          >
                            <div style={downloadButtonWrapper}>
                              <Button
                                variant="outlined"
                                fullWidth
                                sx={downloadMediaButton}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="subText"
                                    sx={{
                                      color: "white",
                                      marginRight: "8px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {/* {fileName} */}
                                    {getShortFileName(fileName)}
                                  </Typography>
                                  {media?.urlDownload
                                    ?.toString()
                                    ?.startsWith(
                                      process.env.REACT_APP_SPACES_ENDPOINT
                                    ) && (
                                    <DownloadForOfflineRoundedIcon
                                      sx={{
                                        fontSize: "27px",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Button>
                            </div>
                          </a>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <Button
            disabled={manipulationPermission}
            onClick={handleSave}
            sx={
              loading
                ? {
                    width: "178px",
                    height: "46px",
                    background: "#979797",
                    borderRadius: "10px",
                    color: "white",
                    "&:hover": { backgroundColor: "#979797" },
                    // "&:disabled": {
                    //   backgroundColor: "#979797",
                    // },
                  }
                : {
                    width: "178px",
                    height: "46px",
                    backgroundColor: "background.bgLayoutB",
                    borderRadius: "10px",
                    color: "white",
                    fontFamily: "Poppins-Bold",
                    "&:hover": {
                      backgroundColor: "red",
                      boxShadow: 2,
                      transition: ".7s ease-in",
                    },
                    "&:disabled": {
                      backgroundColor:
                        manipulationPermission && "background.bgLayoutB",
                      color: "white",
                    },
                  }
            }
          >
            Save
            {loading ? <CircularProgressLoader /> : ""}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Index;
