import React, { useEffect } from "react";
import ApplicationTable from "./applicationTable";
import CardList from "./CardList";
const index = () => {
  const permissions = localStorage.getItem("permissions");
  let permissionArray = permissions?.length > 0 ? permissions.split(",") : [];

  useEffect(() => {
    // Push current state to prevent back navigation
    window.history.pushState(null, null, window.location.href);

    const handlePopState = () => {
      // Prevent back navigation by pushing state again
      window.history.pushState(null, null, window.location.href);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div>
      <CardList />
      {permissionArray.includes("show applications") && <ApplicationTable />}
    </div>
  );
};

export default index;
