import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../redux/store";
import { getApplicantsData } from "../../redux/slices/dashboard/applicantsSlice";
import CustomTable from "../customTable/Index";
import { Box } from "@mui/material";
const tableHeader = [
  {
    id: 1,
    name: "Date",
    width: "100px",
  },
  { id: 2, name: "Municipality", width: "150px" },
  { id: 3, name: "Job Address", width: "180px" },
  { id: 4, name: "Job Description", width: "200px" },
  { id: 5, name: "Owner Name", width: "180px" },
  { id: 6, name: "Permit Fee", width: "150px" },
  // { id: 7, name: "Payment Status", width: "200px" },
  { id: 7, name: "Application Status", width: "250px" },
];
const Index = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.setItem("previousRoute", location.pathname);
    dispatch(getApplicantsData(handleApiResponse));
  }, []);

  const handleApiResponse = (data) => {
    if (data.status) {
      setLoading(false);
    }
  };

  let { applicants } = useSelector((state) => state.applicantsReducer);
  return (
    <Box>
      <CustomTable
        tableHeader={tableHeader}
        bodyData={applicants}
        loading={loading}
      />
    </Box>
  );
};

export default Index;
