const getShortFileName = (filename, maxLength = 30) => {
  // Find the last dot position
  const dotIndex = filename.lastIndexOf(".");
  // If there's no dot, just return as is
  if (dotIndex === -1) return filename;

  const namePart = filename.slice(0, dotIndex); // e.g. "long_file_name"
  const extensionPart = filename.slice(dotIndex); // e.g. ".jpg"

  // If the name part is already short enough, return original
  if (namePart.length <= maxLength) {
    return filename;
  }

  // Otherwise, truncate the name part and keep the extension
  const prefix = namePart.slice(0, 20); // adjust as needed
  return `${prefix}...${extensionPart}`;
};

export default getShortFileName;
