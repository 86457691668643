import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Box,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  dialogCancelButton,
  userHeading,
} from "../../utils/muiComponentStyles";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { useDispatch } from "../../redux/store";
import { deleteContractor } from "../../redux/slices/contractorSlice";
import DeleContractorWithApplication from "./DeleteContractorsWithApplicationModal";

const Index = ({ openDeleteDialog, selectedContractor, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const fireToasterContext = useContext(toasterContext);
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [storeResposne, setStoreResposne] = useState({});

  const handleDeleteContractor = () => {
    setLoader(true);
    dispatch(deleteContractor(selectedContractor.id, handleApiRes));
  };

  const handleCloseDeleteModal = () => {
    setOpenModal(false);
    setStoreResposne({});
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(true, data.message);
      handleCloseDialog();
    } else {
      // Close the previous modal and then open the nested error modal
      // handleCloseDialog();
      if (data.data.length > 0) {
        setStoreResposne(data);
        setOpenModal(true);
      } else {
        handleCloseDialog();
      }
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDeleteDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={userHeading} variant="userTableHeadingBold">
            Delete Contractor
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box paddingTop={4}>
            <Typography
              variant="caption"
              sx={{ fontFamily: "Poppins", fontSize: "16px" }}
            >
              Are You Sure, You Want To Delete{" "}
              <span style={{ fontFamily: "Poppins-Bold" }}>
                {selectedContractor.name}
              </span>
              ?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={handleCloseDialog}
          >
            cancel
          </Button>
          <Button
            type="submit"
            onClick={handleDeleteContractor}
            sx={
              loader
                ? {
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": { backgroundColor: "#979797" },
                  }
                : {
                    backgroundColor: "background.bgLayoutB",
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "background.bgLayoutB",
                      boxShadow: 2,
                    },
                    "&:disabled": { backgroundColor: "#979797" },
                  }
            }
          >
            Delete Contractor
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <DeleContractorWithApplication
        openModal={openModal}
        storeResposne={storeResposne}
        handleCloseDialog={handleCloseDialog}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </Container>
  );
};

export default Index;
