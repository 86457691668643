import React, { useContext, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  CardHeader,
  TextField,
  Stack,
  Box,
  SvgIcon,
} from "@mui/material";
import {
  applicationCardStyle,
  miniScrollBarStyles,
  textfield,
  textfieldDate,
  userHeading,
} from "../../../utils/muiComponentStyles";
import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ToasterContext from "../../../utils/context/toasterContext";
import { updateContractor } from "../../../redux/slices/contractorSlice";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

const ConstructionContractor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedContractor = JSON.parse(
    localStorage.getItem("selectedContractor")
  );
  const fireToasterContext = useContext(ToasterContext);
  const [contractorLoader, setContractorLoader] = useState(false);
  const [contractorObject, setContractorObject] = useState(selectedContractor);
  const [dwellEmailError, setDwellEmailError] = useState(false);
  const [dwellQualifierEmailError, setDwellQualifierEmailError] =
    useState(false);
  const [saveContractorPressed, setSaveContractorPressed] = useState(false);

  const handleContractorChange = (event) => {
    setContractorObject({
      ...contractorObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleDwellContractorApiResponse = (data) => {
    setContractorLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Update successfully"
      );
      navigate("/contractors");
      // localStorage.removeItem("selectedContractor");
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleDwellContractorSubmit = () => {
    setSaveContractorPressed(true);
    // contractorObject.email
    if (contractorObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(contractorObject.email)) {
        setDwellEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setDwellEmailError(false);
      }
    }
    if (contractorObject.dwellingContractorQualifierEmail) {
      var patternQualifierEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !patternQualifierEmail.test(
          contractorObject.dwellingContractorQualifierEmail
        )
      ) {
        setDwellQualifierEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setDwellQualifierEmailError(false);
      }
    }
    if (
      contractorObject?.name &&
      contractorObject?.companyName &&
      contractorObject?.phoneNumber &&
      contractorObject?.email &&
      contractorObject?.licenseNumber &&
      contractorObject?.licenseExpirationDate &&
      contractorObject?.dwellingContractorQualifierEmail &&
      contractorObject?.dwellingContractorQualifierName &&
      contractorObject?.dwellingContractorQualifierLicenseNumber &&
      contractorObject?.dwellingContractorQualifierAddress &&
      contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
      contractorObject?.dwellingContractorQualifierCity &&
      contractorObject?.dwellingContractorQualifierZip &&
      contractorObject?.dwellingContractorQualifierEmail &&
      contractorObject?.dwellingContractorQualifierPhoneNumber
    ) {
      const finalDwellContractorObject = {
        id: contractorObject?.id,
        type: selectedContractor.type,
        name: contractorObject?.name,
        email: contractorObject?.email,
        companyName: contractorObject?.companyName,
        phoneNumber: contractorObject?.phoneNumber,
        licenseNumber: contractorObject?.licenseNumber,
        licenseExpirationDate: contractorObject?.licenseExpirationDate,
        address: contractorObject?.address,
        city: contractorObject?.city,
        zip: contractorObject?.zip,
        dwellingContractorQualifierName:
          contractorObject?.dwellingContractorQualifierName,
        dwellingContractorQualifierLicenseNumber:
          contractorObject?.dwellingContractorQualifierLicenseNumber,
        dwellingContractorQualifierAddress:
          contractorObject?.dwellingContractorQualifierAddress,
        dwellingContractorQualifierLicenseExpirationDate:
          contractorObject?.dwellingContractorQualifierLicenseExpirationDate,
        dwellingContractorQualifierCity:
          contractorObject?.dwellingContractorQualifierCity,
        dwellingContractorQualifierZip:
          contractorObject?.dwellingContractorQualifierZip,
        dwellingContractorQualifierEmail:
          contractorObject?.dwellingContractorQualifierEmail,
        dwellingContractorQualifierPhoneNumber:
          contractorObject?.dwellingContractorQualifierPhoneNumber,
        temporary: selectedContractor.temporary,
      };
      setContractorLoader(true);
      dispatch(
        updateContractor(
          finalDwellContractorObject,
          handleDwellContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Dwelling Contractor"
      );
    }
  };

  return (
    <div>
      <Container>
        <Card>
          <CardHeader
            disableTypography
            title={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>{`Update ${
                  selectedContractor?.type === "Construction" ? "Dwelling" : ""
                } Contractor details`}</Box>
                <Box
                  sx={{
                    display:
                      selectedContractor.temporary === false ? "none" : "flex",
                    alignItems: "center",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <SvgIcon>
                    <ErrorOutlineRoundedIcon sx={{ color: "#d52127" }} />
                  </SvgIcon>
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Medium",
                      fontSize: "15px",
                      ml: 1,
                    }}
                  >
                    {selectedContractor.temporary === true
                      ? "Add contractor to Database"
                      : null}
                  </Typography>
                </Box>
              </Stack>
            }
            sx={applicationCardStyle}
          />
          <ValidatorForm
            style={{ width: "100%" }}
            onSubmit={handleDwellContractorSubmit}
          >
            <CardContent
              sx={{
                mt: 2,
                mx: 2,
                height: "580px",
                overflowY: "auto",
                [`::-webkit-scrollbar`]: {
                  width: "0px",
                  background: "transparent",
                },
                ...miniScrollBarStyles,
              }}
            >
              <Grid container spacing={2} sx={{ my: "5px" }}>
                {/* dwelling contractor name field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!contractorObject?.name && saveContractorPressed}
                    helperText={
                      !contractorObject?.name && saveContractorPressed
                        ? "Name is required"
                        : ""
                    }
                    id="dwelling-contractor-name"
                    label="Dwelling Contractor name"
                    value={contractorObject?.name || ""}
                    name="name"
                    sx={textfield}
                    onChange={handleContractorChange}
                  />
                </Grid>
                {/* Dwelling contractor company name field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-company-name"
                    label="Dwelling Contractor Comapany"
                    name="companyName"
                    value={contractorObject?.companyName || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                  />
                </Grid>
                {/* dweeling contractor email field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-email"
                    label="Dwelling Contractor Email"
                    name="email"
                    value={contractorObject?.email || ""}
                    type="email"
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !!dwellEmailError ||
                      (!contractorObject?.email && saveContractorPressed)
                    }
                    helperText={
                      dwellEmailError ||
                      (!contractorObject?.email && saveContractorPressed)
                        ? "Please enter a valid email address"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor phone number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !contractorObject?.phoneNumber && saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.phoneNumber && saveContractorPressed
                        ? "Phone number is required"
                        : ""
                    }
                    id="dwelling-contractor-phone-number"
                    label="Dwelling Contractor Phone No."
                    name="phoneNumber"
                    value={contractorObject?.phoneNumber || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                  />
                </Grid>
                {/* dwelling contractor license number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !contractorObject?.licenseNumber && saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.licenseNumber && saveContractorPressed
                        ? "License number is required"
                        : ""
                    }
                    id="dwelling-contractor-license-number"
                    label="Dwelling Contractor License No."
                    name="licenseNumber"
                    value={contractorObject?.licenseNumber || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                  />
                </Grid>
                {/* dwelling contractor license expire date */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !contractorObject?.licenseExpirationDate &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.licenseExpirationDate &&
                      saveContractorPressed
                        ? "License expiration date is required"
                        : ""
                    }
                    id="dwelling-contractor-license-expire-date"
                    // label="Dwelling Contractor License/Certification No. Exp Date"
                    name="licenseExpirationDate"
                    value={
                      contractorObject?.licenseExpirationDate?.split("T")[0]
                    }
                    disableUnderline
                    fullWidth
                    sx={textfieldDate}
                    label="License Expiration Date"
                    inputProps={{
                      max: "9999-12-31",
                    }}
                    type="date"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                    onChange={handleContractorChange}
                  />
                </Grid>
                {/* dwelling contractor address */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="dwelling-contractor-address"
                    label="Dwelling Contractor Street Address"
                    name="address"
                    value={contractorObject?.address || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={!contractorObject?.address && saveContractorPressed}
                    helperText={
                      !contractorObject?.address && saveContractorPressed
                        ? "Address is required"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor city */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-city"
                    label="Dwelling Contractor City"
                    name="city"
                    value={contractorObject?.city || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={!contractorObject?.city && saveContractorPressed}
                    helperText={
                      !contractorObject?.city && saveContractorPressed
                        ? "City is required"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor zip code */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-zipd-code"
                    label="Dwelling Contractor Zipcode"
                    name="zip"
                    type="number"
                    value={contractorObject?.zip || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={!contractorObject?.zip && saveContractorPressed}
                    helperText={
                      !contractorObject?.zip && saveContractorPressed
                        ? "Zip code is required"
                        : ""
                    }
                  />
                </Grid>

                {/* dweeling contractor qualifier name */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-name"
                    label="Dwelling Contractor Qualifier Name"
                    value={
                      contractorObject?.dwellingContractorQualifierName || ""
                    }
                    name="dwellingContractorQualifierName"
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierName &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierName &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier name is required"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor qualifier's license number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-license-number"
                    label="Dwelling Contractor Qualifier License No."
                    name="dwellingContractorQualifierLicenseNumber"
                    value={
                      contractorObject?.dwellingContractorQualifierLicenseNumber ||
                      ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierLicenseNumber &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierLicenseNumber &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier license number is required"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor qualifier's license number expire date */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-license-number-expire-date"
                    name="dwellingContractorQualifierLicenseExpirationDate"
                    value={
                      contractorObject?.dwellingContractorQualifierLicenseExpirationDate?.split(
                        "T"
                      )[0]
                    }
                    onChange={handleContractorChange}
                    disableUnderline
                    fullWidth
                    sx={textfieldDate}
                    label="License Expiration Date"
                    inputProps={{
                      max: "9999-12-31",
                    }}
                    type="date"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                    error={
                      !contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier license expiration date is required"
                        : ""
                    }
                  />
                </Grid>
                {/* Dwelling Contractor Qualifier Address */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-Qualifier-address"
                    label="Dwelling Contractor Qualifier Address"
                    name="dwellingContractorQualifierAddress"
                    value={
                      contractorObject?.dwellingContractorQualifierAddress || ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierAddress &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierAddress &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier address is required"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor qualifier city */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-city"
                    label="Dwelling Contractor Qualifier City"
                    name="dwellingContractorQualifierCity"
                    value={
                      contractorObject?.dwellingContractorQualifierCity || ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierCity &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierCity &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier city is required"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor qualifier zipCode */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-zip-code"
                    label="Dwelling Contractor Qualifier Zip Code"
                    name="dwellingContractorQualifierZip"
                    type="number"
                    value={
                      contractorObject?.dwellingContractorQualifierZip || ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierZip &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierZip &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier zip code is required"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor qualifier email */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-email"
                    label="Dwelling Contractor Qualifier Email"
                    name="dwellingContractorQualifierEmail"
                    value={
                      contractorObject?.dwellingContractorQualifierEmail || ""
                    }
                    type="email"
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !!dwellQualifierEmailError ||
                      (!contractorObject?.dwellingContractorQualifierEmail &&
                        saveContractorPressed)
                    }
                    helperText={
                      dwellQualifierEmailError ||
                      (!contractorObject?.dwellingContractorQualifierEmail &&
                        saveContractorPressed)
                        ? "Please enter a valid email address"
                        : ""
                    }
                  />
                </Grid>
                {/* dwelling contractor qualifier phone number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-phone-number"
                    label="Dwelling Contractor Qualifier Phone No."
                    name="dwellingContractorQualifierPhoneNumber"
                    value={
                      contractorObject?.dwellingContractorQualifierPhoneNumber ||
                      ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierPhoneNumber &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierPhoneNumber &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier phone number is required"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <Button
                disableRipple
                type="submit"
                disabled={contractorLoader}
                sx={
                  contractorLoader
                    ? {
                        background: "#979797",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "187px",
                        "&:hover": { backgroundColor: "#979797" },
                        "&:disabled": {
                          backgroundColor: "#979797",
                        },
                      }
                    : {
                        backgroundColor: "background.bgLayoutB",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "187px",
                        "&:hover": { backgroundColor: "background.bgLayoutB" },
                        "&:disabled": {
                          backgroundColor: "#979797",
                        },
                      }
                }
              >
                <Typography sx={userHeading} variant="userHeadingBoldButton">
                  Update
                </Typography>
                {contractorLoader ? <CircularProgressLoader /> : ""}
              </Button>
            </CardActions>
          </ValidatorForm>
        </Card>
      </Container>
    </div>
  );
};

export default ConstructionContractor;
