import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  IconButton,
  Button,
  InputBase,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import Loader from "../Loader/Loader";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  Search,
} from "@mui/icons-material";

import CustomPagination from "../../hooks/useTablePagination";
import { useDispatch, useSelector } from "../../redux/store";

import {
  StyledTableRow,
  autoCompleteStyle,
  miniScrollBarStyles,
  styledTableCell,
  tableBodyTypos,
  tableLoaderBox,
  textfield,
  userHeading,
} from "../../utils/muiComponentStyles";
import { getAllContractorsForListing } from "../../redux/slices/contractorSlice";
import DeleteContractorsDialog from "./DeleteContractors";

import { useLocation, useNavigate } from "react-router-dom";

const tableHeader = [
  { id: 2, name: "Company", width: "180px" },
  { id: 7, name: "Contractor Type", width: "200px" },
  { id: 3, name: "License Number", width: "200px" },
  { id: 4, name: "License Expiration Date", width: "280px" },
  { id: 4, name: "Status", width: "100px" },
  { id: 5, name: "Phone Number", width: "200px" },
  { id: 6, name: "" },
];

const contactorTypes = [
  "Construction",
  "Electrical",
  "Plumbing",
  "HVAC",
  "General",
];

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [page, setPage] = useState(() => {
    // Retrieve the page from localStorage or default to 0
    const savedPage = localStorage.getItem("pageContractors");
    return savedPage ? Number(savedPage) : 0;
  });
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    // Retrieve the rowsPerPage from localStorage or default to 10
    const savedRowsPerPage = localStorage.getItem("rowsPerPageContractors");
    return savedRowsPerPage ? Number(savedRowsPerPage) : 10;
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [toBeSearched, setToBeSearched] = useState(
    localStorage.getItem("searchContractor") || ""
  ); // Load search filter from localStorage
  const [autoCompleteSearch, setAutoCompleteSearch] = useState(
    localStorage.getItem("contractorTypeFilter") || ""
  ); // Load contractor type filter from localStorage
  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = localStorage.getItem("selectedDate");
    return savedDate ? savedDate : ""; // Initialize as an empty string
  });
  const [uniqueData, setUniqueData] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState();
  let { contractorForListingData } = useSelector(
    (state) => state.contractorReducer
  );
  useEffect(() => {
    localStorage.setItem("previousRoute", location.pathname);
    dispatch(getAllContractorsForListing(handleApiResponse));
  }, []);

  const handleApiResponse = (response) => {
    if (response.status) {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Persist selected date in localStorage whenever it changes
    if (selectedDate) {
      localStorage.setItem("selectedDate", selectedDate);
    } else {
      localStorage.removeItem("selectedDate"); // Remove from localStorage if no date is selected
    }
  }, [selectedDate]);
  useEffect(() => {
    handleDateSearch();
    setPage(0);
  }, [selectedDate]);
  useEffect(() => {
    // setPage(0);
    if (contractorForListingData) {
      const uniqueData = [];
      const groupedData = contractorForListingData.reduce((acc, item) => {
        const key = `${item.companyName}|${item.phoneNumber}|${item.licenseNumber}|${item.licenseExpirationDate}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});

      Object.values(groupedData).forEach((group) => {
        if (group.length > 1) {
          const allTemporarySame = group.every(
            (item) => item.temporary === group[0].temporary
          );
          if (allTemporarySame) {
            uniqueData.push(group[0]);
          } else {
            uniqueData.push(...group);
          }
        } else {
          uniqueData.push(group[0]);
        }
      });

      setUniqueData(uniqueData);
      setFilteredList(uniqueData); // Set filteredList to uniqueData initially
    }
  }, [contractorForListingData]);
  useEffect(() => {
    // Check if any filters are applied
    const areFiltersApplied =
      toBeSearched || autoCompleteSearch || selectedDate;

    if (!areFiltersApplied) {
      // If no filters are applied, retrieve saved page from localStorage
      const savedPage = localStorage.getItem("pageContractors");
      if (savedPage) {
        setPage(Number(savedPage)); // Set the page to the saved value
      }
    } else {
      // If filters are applied, reset page to 0
      setPage(0);
    }
  }, [toBeSearched, autoCompleteSearch, selectedDate]);

  const handleFilter = (bodyData, searchTerm, contractorType, dateFilter) => {
    return bodyData.filter((u) => {
      const nameMatch =
        u?.companyName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        u?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        u?.phoneNumber?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        u?.licenseNumber?.toLowerCase()?.includes(searchTerm?.toLowerCase());

      const contractorMatched = contractorType
        ? u?.type?.toLowerCase()?.includes(contractorType.toLowerCase())
        : true;

      const dateMatch = dateFilter
        ? u?.licenseExpirationDate?.split("T")[0] === dateFilter
        : true;

      return nameMatch && contractorMatched && dateMatch;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    localStorage.setItem("pageContractors", newPage - 1); // Persist the page in localStorage
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    localStorage.setItem("rowsPerPageContractors", newRowsPerPage); // Persist the rowsPerPage in localStorage
    setPage(0); // Reset to the first page when changing rows per page
    localStorage.setItem("pageContractors", 0); // Persist the reset page in localStorage
  };
  const filteredData = handleFilter(
    filteredList,
    toBeSearched,
    autoCompleteSearch,
    selectedDate
  );

  const handleDeleteContractor = (data) => {
    setOpenDeleteDialog(true);
    setSelectedContractor(data);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleEditContractor = (data) => {
    setSelectedContractor(data);
    localStorage.setItem("selectedContractor", JSON.stringify(data));
    navigate("/contractors/update-contractors-details");
  };

  const handleDateSearch = () => {
    const filteredContractors = uniqueData.filter((u) => {
      const dateMatch =
        !selectedDate ||
        u?.licenseExpirationDate?.split("T")[0] === selectedDate;
      return dateMatch;
    });
    setFilteredList(filteredContractors);
  };
  const handleSearch = (value) => {
    setToBeSearched(value);
    localStorage.setItem("searchContractor", value); // Save search filter in localStorage
    setPage(0); // Reset page to 0 when filter is applied
  };

  const handleContractorTypeChange = (event, newValue) => {
    setPage(0);
    if (newValue !== null) {
      setAutoCompleteSearch(newValue);
      localStorage.setItem("contractorTypeFilter", newValue); // Save contractor type in localStorage
    } else {
      setAutoCompleteSearch("");
      localStorage.removeItem("contractorTypeFilter"); // Clear contractor type filter from localStorage if no value is selected
    }
    // Reset page to 0 when filter is applied
  };

  const handleDateChange = (event) => {
    setPage(0);
    const newDate = event.target.value;
    setSelectedDate(newDate);
    localStorage.setItem("selectedDate", newDate); // Save to localStorage
    // setPage(0); // Reset page to 0 when filter is applied
    handleDateSearch(); // Call the filter function after date change
  };

  return (
    <Box>
      <Grid spacing={2} container sx={{ my: 2 }}>
        <Grid item xs={12} md={4}>
          <InputBase
            value={toBeSearched}
            disabled={loading}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
              width: "100%",
              height: "38px",
              fontFamily: "Poppins",
              border: "1px solid rgba(169, 169, 169, 0.6)",
              borderRadius: 2,
              backgroundColor: "background.bgSearchBar",
            }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            startAdornment={
              <Search
                sx={{
                  color: "rgba(151, 151, 151, 0.68);",
                  paddingLeft: "3px",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            value={autoCompleteSearch}
            disabled={loading}
            disablePortal
            id="combo-box-demo"
            options={contactorTypes.sort()}
            sx={{ fontFamily: "Poppins" }}
            getOptionLabel={(option) => option}
            onChange={handleContractorTypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Contractor Type"
                size="small"
                sx={autoCompleteStyle}
                InputLabelProps={{
                  style: { fontFamily: "Poppins" },
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    color: "#404041",
                  }}
                >
                  {option}
                </Typography>
              </li>
            )}
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <TextField
            disabled={loading}
            size="small"
            id="startDate"
            name="startDate"
            type="date"
            label="License Expiration Date"
            fulWidth
            variant="outlined"
            value={selectedDate} // Bind the value to selectedDate
            onChange={handleDateChange}
            sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
            InputLabelProps={{
              shrink: "true",
            }}
          />
        </Grid>

        <Grid item xs={12} md={2.5}>
          <Button
            disabled={loading}
            onClick={() => navigate("/contractors/add-new-contractor")}
            disableRipple
            sx={{
              backgroundColor: "background.bgLayoutB",
              width: "100%",
              borderRadius: "10px",
              "&:hover": { backgroundColor: "background.bgLayoutB" },
              "&:disabled": {
                backgroundColor: "rgba(224, 224, 224, 1)",
              },
            }}
          >
            <Typography sx={userHeading} variant="userHeadingBoldButton">
              Add New Contractor
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          maxHeight: 640,
          borderRadius: "10px",
          [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
          ...miniScrollBarStyles,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
            <TableRow>
              {tableHeader.map((data) => (
                <TableCell
                  sx={{
                    ...styledTableCell,
                    backgroundColor: "background.bgLayoutA",
                    minWidth: data.width,
                  }}
                  key={data.id}
                >
                  <Typography sx={userHeading} variant="userTableHeadingBold">
                    {data.name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">
                  <Box sx={{ ...tableLoaderBox, justifyContent: "flex-end" }}>
                    <Loader />
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : filteredData?.length < 1 ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right" sx={{ width: 550 }}>
                  <Box
                    sx={{
                      ...tableLoaderBox,
                      justifyContent: "flex-end",
                      width: " 100%",
                    }}
                  >
                    <Typography sx={userHeading} variant="userTableCellName">
                      No contractors found
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell
                        sx={{
                          ...styledTableCell,
                          minWidth: "200px",
                        }}
                        component="th"
                        scope="row"
                      >
                        <Typography
                          sx={tableBodyTypos}
                          variant="tableBodyCellName"
                        >
                          {row.name}
                        </Typography>
                      </TableCell> */}
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "180px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.companyName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "200px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.type}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "200px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellNameLicenseNumber"
                      >
                        {row.licenseNumber}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "230px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={userHeading}
                        variant="userTableCellName"
                        // dangerouslySetInnerHTML={{
                        //   __html: dateTimeApiformat
                        //     (row.licenseExpirationDate),
                        // }}
                      >
                        {row?.licenseExpirationDate?.split("T")[0]}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "100px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography sx={userHeading} variant="userTableCellName">
                        {row?.temporary === true ? "Temporary" : "Permanent"}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "200px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.phoneNumber}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ ...styledTableCell, minWidth: "10px" }}
                      component="th"
                      scope="row"
                      align="right"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          sx={{
                            "&.MuiButtonBase-root": {
                              backgroundColor: "background.bgLayoutA",
                              color: "white",
                              height: "36px",
                              width: "36px",
                            },
                          }}
                          onClick={() => handleEditContractor(row)}
                        >
                          <EditOutlined />
                        </IconButton>
                        <IconButton
                          sx={{
                            "&.MuiButtonBase-root": {
                              backgroundColor: "background.bgLayoutB",
                              color: "white",
                              height: "36px",
                              width: "36px",
                            },
                          }}
                          onClick={() => handleDeleteContractor(row)}
                        >
                          <DeleteOutlineOutlined />
                        </IconButton>
                      </span>
                    </TableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage} // This should only handle page change triggered by pagination
        filteredList={filteredData}
        setRowsPerPage={handleRowsPerPageChange}
      />

      {openDeleteDialog && (
        <DeleteContractorsDialog
          openDeleteDialog={openDeleteDialog}
          selectedContractor={selectedContractor}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </Box>
  );
};

export default Index;
