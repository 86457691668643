import React, { useContext, useState } from "react";
import "./Login.css";
import logo from "../../assets/icons/logo.png";
import {
  Container,
  Card,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Loader from "../../components/Loader/Loader";
import toasterContext from "../../utils/context/toasterContext";
import { dispatch } from "../../redux/store";
import { loginUser } from "../../redux/slices/LoginSlice";
import {
  loginButton,
  loginCard,
  loginContainer,
  loginFieldDivBox,
  loginHeadingTextStyle,
  loginInputField,
} from "./LoginStyle";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(toasterContext);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // const permissions = localStorage.getItem("permissions");
  // const permissionArray = permissions > 0 ? permissions.split(",") : [];

  const handleChange = (event) => {
    event.persist();
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const { phoneNumber, password } = state;
  const handleLoginFormData = (data) => {
    setLoader(false);
    if (data.status) {
      window.location.href = "/";
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      localStorage.setItem("isSuperAdmin", data.data.user.isSuperAdmin);
      if (data.data.user.isSuperAdmin) {
        window.location.href = "/";
      } else {
        if (localStorage.getItem("permissions").includes("show dashboard")) {
          window.location.href = "/";
        } else if (
          localStorage.getItem("permissions").includes("show monthly reports")
        ) {
          window.location.href = "/reports";
        } else if (
          localStorage.getItem("permissions").includes("show municipalities")
        ) {
          window.location.href = "/municipalities";
        } else if (
          localStorage.getItem("permissions").includes("show contractors")
        ) {
          window.location.href = "/contractors";
        } else if (
          localStorage.getItem("permissions").includes("show applications")
        ) {
          window.location.href = "/applications";
        }
      }
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data?.message ? data?.message : "Something went wrong"
      );
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    dispatch(loginUser(state, handleLoginFormData));
  };

  return (
    <div className="login_mainDiv">
      <Container sx={loginContainer}>
        <Card sx={loginCard}>
          <img className="loginLogoImg" src={logo} alt="logo" />
          <Box sx={loginFieldDivBox} autoComplete="off">
            <Typography variant="body1" sx={loginHeadingTextStyle}>
              Please login to continue
            </Typography>
            <br />
            <ValidatorForm
              style={{ width: "100%" }}
              onSubmit={handleSubmit}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            >
              <TextValidator
                id="outlined-required"
                label="Phone Number/Email"
                name="phoneNumber"
                value={phoneNumber || ""}
                sx={loginInputField}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["Phone Number/Email is required"]}
              />
              <TextValidator
                id="outlined-password-required"
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password || ""}
                sx={loginInputField}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["this field is required"]}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                }}
              />
              <Button variant="contained" sx={loginButton} type="submit">
                {loader ? <Loader /> : "Login"}
              </Button>
            </ValidatorForm>
          </Box>
        </Card>
      </Container>
    </div>
  );
}

export default Login;
